b,
strong,
.bold,
.semibold { @include rakutenSansUIsemiBold; }
.semibold-link {
  color: $brand-01;
  @include rakutenSansUIsemiBold;
}

.f-r, .prox-r, .proxima_nova_rgregular, .texgs-r { // Legacy Styles overwrite
  @include rakutenSansUI(Rakuten Sans UI, 400);
}

.f-b, .prox-b, .texgs-i, .prox-r .f-b { // Legacy Styles overwrite
  @include rakutenSansUIsemiBold;
}

.f-l, .prox-l, .prox-t { // Legacy Styles overwrite
  @include rakutenSansUI(Rakuten Sans UI, 300);
}
.stag-med-6 { @include stag(Stag Medium Web, 600); }

h2.section-heading,
.section-heading { // Section Heading used to separate modules in Home Page
  @include stag(Stag Medium Web, 500);
  font-size: 28px;
  line-height: 36px;
  padding-top: 30px;
  padding-bottom: 10px;
  a {
    &.see-all {
      @include rakutenSansUIsemiBold;
      display: inline-block;
      font-size: 16px;
      line-height: 24px;
      padding-left: 32px;
      margin-top: 4px;
      &.pad-0-l { padding-left: 0 }
    }
  }
}
.module-heading {
  @include stag(Stag Medium Web, 500);
  font-size: 28px;
  line-height: 36px;
  padding-bottom: 10px;
  color: $prim-text-color;
}
.aside {
  .section-heading {
    display: block;
    @include stag(Stag Medium Web, 500);
    font-size: 22px;
    line-height: 32px;
    padding: 20px 30px 10px 30px;
    @include border-bottom;
    a {
      @include stag(Stag Medium Web, 500);
      color: $prim-text-color;
      .see-all {
        display: block;
        float: right;
        text-align: right;
        @include rakutenSansUIsemiBold;
        color: $brand-01;
        font-size: 16px;
        line-height: 24px;
        margin-top: 4px;
      }
    }
  }
  .module-heading {
    display: block;
    @include stag(Stag Medium Web, 500);
    font-size: 28px;
    line-height: 36px;
    margin-bottom: 5px;
    a {
      &.module-heading-link {
        color: $prim-text-color;
        &:hover { text-decoration: none; }
      }
    }
  }
}
.main {
  .section-heading {
    padding: 20px 30px 10px 30px;
  }
  .heading-main, // Store Breadcrumb Heading
  .module-heading {
    @include stag(Stag Medium Web, 500);
    font-size: 28px;
    line-height: 36px;
    padding: 18px 30px 6px 30px;
    @include border-bottom;
    .see-all {
      @include rakutenSansUIsemiBold;
      font-size: 16px;
      line-height: 24px;
      float: right;
      padding-top: 10px;
    }
  }
  .heading-main {
    padding: 18px 0 0 0;
    border-bottom: none;
  }
  .hd-heading {
    @include stag(Stag Medium Web, 500);
    font-size: 28px;
    line-height: 36px;
    padding: 18px 30px 6px 30px;
    .see-all {
      @include rakutenSansUIsemiBold;
      font-size: 16px;
      line-height: 24px;
      float: right;
      padding-top: 10px;
    }
  }
}
.section-heading,
.module-heading {
  &.pad-0-t {
    padding-top: 0;
  }
}
.modal-heading { // Used for SU/SI Modals
  @include stag(Stag Medium Web, 500);
  text-align: center;
  margin: 35px 30px 30px 30px;
}
.sign-in-cta {
  color: $brand-01;
  @include rakutenSansUIsemiBold;
}
.mwview {
  h2.section-heading,
  .section-heading { // Section Heading used to separate modules in Home Page
    @include stag(Stag Medium Web, 500);
    font-size: 18px;
    line-height: 22px;
    padding-top: 20px;
    padding-bottom: 10px;
    margin-top: 0;
    margin-bottom: 0;
    color: $prim-text-color;
    a {
      @include rakutenSansUIsemiBold;
      font-size: 16px;
      line-height: 20px;
      &.anchorText { color: $brand-01; }
    }
  }
  .module-heading {
    @include stag(Stag Medium Web, 500);
    font-size: 18px;
    line-height: 22px;
    padding: 0 0 10px 0;
    letter-spacing: 1px;
    .see-all {
      @include rakutenSansUIsemiBold;
      font-size: 16px;
      line-height: 24px;
      float: right;
      padding-top: 10px;
    }
  }
}

.space-0, .ls-0 { letter-spacing: 0; } // Do not use .ls-0
.space-half { letter-spacing: 0.05rem; }
.space-1, .ls-1 { letter-spacing: 1px; }  // Do not use .ls-1
.space-2, .ls-2 { letter-spacing: 2px; }  // Do not use .ls-2

.underline { text-decoration: underline; }
.strike { text-decoration: line-through; }
.no-outline { outline: none; }
.no-wrap { white-space: nowrap; }
.wrap { white-space: normal; }
.norm { font-weight: normal; }
.wordbreak {
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-word;
}
.no-word-break { word-break: keep-all; }
.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.bld { font-weight: bold; }
.f-left {text-align: left; }
.f-right { text-align: right; }
.cent,
.center,
.f-cent { text-align: center; }
.f-just { text-align: justify; }

.tl { text-align: left; } // Do not use since we already have the class f-left in _typography-new.scss
.tr { text-align: right; } // Do not use since we already have the class f-right in _typography-new.scss
.tc { text-align: center; } // Do not use since we already have the class center AND f-cent in _typography-new.scss
.tj { text-align: justify; } // Do not use since we already have the class f-just in _typography-new.scss

// Font Sizes
.f-7 { font-size: 7px; }
.f-9 { font-size: 9px; }
.f-10 { font-size: 10px; }
.f-11 { font-size: 11px; }
.f-12 { font-size: 12px; }
.f-13 { font-size: 13px }
.f-reg,.f-14{ font-size: 14px; }
.f-15 { font-size: 15px; }
.f-16 { font-size: 16px; }
.f-17 { font-size: 17px; }
.f-18 { font-size: 18px; }
.f-19 { font-size: 19px; }
.f-20 { font-size: 20px; }
.f-21 { font-size: 21px; }
.f-22 { font-size: 22px; }
.f-23 { font-size: 23px; }
.f-24 { font-size: 24px; }
.f-25 { font-size: 25px; }
.f-26 { font-size: 26px; }
.f-27 { font-size: 27px; }
.f-28 { font-size: 28px; }
.f-29 { font-size: 29px; }
.f-30 { font-size: 30px; }
.f-31 { font-size: 31px; }
.f-32 { font-size: 32px; }
.f-33 { font-size: 33px; }
.f-34 { font-size: 34px; }
.f-35 { font-size: 35px; }
.f-36 { font-size: 36px; }
.f-38 { font-size: 38px; }
.f-40 { font-size: 40px; }
.f-42 { font-size: 42px; }
.f-44 { font-size: 44px; }
.f-45 { font-size: 45px; }
.f-48 { font-size: 48px; }
.f-50 { font-size: 50px; }
.f-52 { font-size: 52px; }
.f-53 { font-size: 53px; }
.f-55 { font-size: 55px; }
.f-56 { font-size: 56px; }
.f-60 { font-size: 60px; }
.f-66 { font-size: 66px; }
.f-70 { font-size: 70px; }
.f-72 { font-size: 72px; }
.f-76 { font-size: 76px; }
.f-80 { font-size: 80px; }

// Line Heights
.lh-1 { line-height: $lh-default;}
.lh-12 { line-height: 12px; }
.lh-13 { line-height: 13px; }
.lh-14 { line-height: 14px; }
.lh-15 { line-height: 15px; }
.lh-16 { line-height: 16px; }
.lh-17 { line-height: 17px; }
.lh-18 { line-height: 18px; }
.lh-20 { line-height: 20px; }
.lh-21 { line-height: 21px; }
.lh-22 { line-height: 22px; }
.lh-23 { line-height: 23px; }
.lh-24 { line-height: 24px; }
.lh-25 { line-height: 25px; }
.lh-26 { line-height: 26px; }
.lh-27 { line-height: 27px; }
.lh-28 { line-height: 28px; }
.lh-29 { line-height: 29px; }
.lh-30 { line-height: 30px; }
.lh-32 { line-height: 32px; }
.lh-33 { line-height: 33px; }
.lh-34 { line-height: 34px; }
.lh-36 { line-height: 36px; }
.lh-38 { line-height: 38px; }
.lh-40 { line-height: 40px; }
.lh-41 { line-height: 41px; }
.lh-42 { line-height: 42px; }
.lh-44 { line-height: 44px; }
.lh-45 { line-height: 45px; }
.lh-46 { line-height: 46px; }
.lh-48 { line-height: 48px; }
.lh-50 { line-height: 50px; }
.lh-52 { line-height: 52px; }
.lh-54 { line-height: 54px; }
.lh-55 { line-height: 55px; }
.lh-56 { line-height: 56px; }
.lh-60 { line-height: 60px; }
.lh-62 { line-height: 62px; }
.lh-65 { line-height: 65px; }
.lh-64 { line-height: 64px; }
.lh-70 { line-height: 70px; }
.lh-128 { line-height: 128px; }

.f-dark,
.f-norm,
.f-norm a,
.f-gry,
.f-gry-dk { color: $prim-text-color; }

.f-sec,
.f-gry-dk-6 {color: $sec-text-color; }

.f-tert,
.f-gry-dk-8 { color: $tert-text-color; }

.f-hint,
.f-gry-a { color: $hint-color; }

.f-brand-01,
.f-grn,
.f-int,
.score-3,
.score-4 { color: $brand-01; }

.f-brand-03 { color: $brand-03; }
.f-brand-04 { color: $brand-04; }

.f-err,
.score-0,
.score-1,
.error-red { color: $error-color; }

.f-ui-03,
.f-gry-c { color: $border-color; }

.f-ui-02{color:$darker-border-color;}

.f-gry-dk2,
.f-gry-dk-9 { color: $tert-text-color; } // Do not use either of these 2 classes as they're being phased out

.f-gry-3{color:$gray-dark-3-color;}
.f-gry-6{color:$gray-dark-6-color;}

.f-gry-ec,
.f-gry-ec a { color: $gray-ec-color; }

.f-gry-lte{color:$default-bg-color;/* to be deleted - not used */}
.f-gry-lte2{color:#d7d7d7;/* to be deleted - not used */}
.f-ext, .span.cb, .f-orange, .f-ext { color: $brand-01; }
.cb {
  @include rakutenSansUIsemiBold;
  color: $brand-01;
}
.cb-was { color: $gray-dark-8-color; }
.f-orange-2{color:#f60;/* to be deleted - not used */}
.f-orange-cc{color:#e18922;/* to be deleted - not used */}
.score-2,.f-orange-dk,.darkorange{color:$darkorange-color;}

.f-white,
.f-white a,.white-font { color: $white-color; }

.f-white-all *{color:$white-color !important;}
.f-blu,.f-cc-blue,a.f-cc-blue,.cb-blue,.cb.bluecb,span.cb.bluecb{color:#017edb;}
.f-bonus,.f-blu-bonus, a.f-blu-bonus, .bonus-blu{color:$bonus-color;}
.f-cred,.f-cc-blu{color:$cc-color;}
.f-grn-hover .f-gry-dk:hover,
.f-grn-hover .f-gry-dk:hover .cb{color:$brand-01;}
.f-red,.error-msg,.red{color:$error-color-old;}
.f-pink{color:$bday-color;/*birthday promo*/}
.f-4u,.f-gold{color:$just-for-you-color;/*golden links*/}
.f-peach { color: $color-peach; }
.f-indigo { color: $color-indigo; }
.f-teal { color: $color-teal; }

// .uppercase{text-transform:uppercase;}
// .lowercase{text-transform:lowercase;}
// .capitalize{text-transform:capitalize;}
$txt-transforms: (uppercase, lowercase, capitalize);
@each $txt-transform in $txt-transforms {
  .#{$txt-transform} {
    text-transform:#{$txt-transform};
  }
}

.indigo-blk { // Used for My Account Indigo Blocks
  display: inline-block;
  @include border( $border-width: 1px, $border-color: $color-indigo );
  @include round(3px);
  color: $color-indigo;
  background-color: rgba(49,18,147,0.05);
  padding: 30px;
  .fa-exclamation-circle,
  .fa-question-circle { display: block; }
}

.mwview {
  [class^="fa-"],[class*=" fa-"] { // Overwrite default FA line-height: inherit from legacy-mw.scss
    &.lh-18 { line-height: 18px; }
    &.lh-20 { line-height: 20px; }
    &.lh-30 { line-height: 30px; }
  }
  .indigo-blk { padding: 20px; }
}
.eb-link { // used in some overlays
  @include rakutenSansUIsemiBold;
  color: $brand-01;
  text-decoration: none;
  &:hover {cursor: pointer;}
}

/***************************************
* DLS-friendly named classes
 ****************************************/

.descriptor-l {
  @include rakutenSansUIsemiBold;
  font-size: 20px;
  line-height: 28px;
}


.descriptor-med {
  @include rakutenSansUIsemiBold;
  font-size: 18px;
  line-height: 24px;
}

.descriptor-sm {
  @include rakutenSansUIsemiBold;
  font-size: 16px;
  line-height: 24px;
}

.descriptor-xs {
  @include rakutenSansUIsemiBold;
  font-size: 14px;
  line-height: 20px;
}

@mixin body {
  @include rakutenSansUI;
  font-size: 14px;
  line-height: 20px;
}

.body {
  @include body;
}
