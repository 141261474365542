@import "../partials/variables";
@import "../partials/mixins";
//Desktop overlay style for R4
.new-ui {
  .sl-su-modal {
    .eb-form-layout li input.text { width: 318px; }
  }
  .f-blu-bonus {
    color: $brand-01;
    display: inline-block;
    margin-top: 10px;
  }
  .eb-form-layout {
    a.eb-terms,a.eb-privacy {
      text-decoration: underline;
      &:hover { text-decoration: underline; }
    }
    .store-copy{
      font-size:24px;
      line-height: 28px;
      @include rakutenSansUIsemiBold;
      .cb,.f-blu-bonus {
        font-size: 24px;
        line-height: 28px;
      }
     }
    .button-xlg.social { font-size: 16px; }
    .join-button {
      font-size: 16px;
      padding-right: 20px;
      padding-left: 20px;
    }
    .social {
      &.button {
        padding-right: 20px;
        padding-left: 20px;
      }
    }

    .login-option {
      font-size: 14px;
      color: $gray-dark-8-color;
      &:before,&:after {
        top: 6px;
        border-color: $border-color;
      }
    }
    .google-join+br+span{ //The 'never post' sentence.
      font-size: 14px !important;//TO-DO: once move to r4 100%, we should update vm instead of important here.
    }

    .susi-captcha-cont-wrapper {
       &+li{ margin-bottom: 0; }
    }
    li {
      input {
        &.text{
          line-height: 20px;
          &.bg-gry-f6 {
            background-color: $white-color;
          }
          &::placeholder{
            color:$placeholder-color;
          }
        }
      }
    }
  }
}

.eb-form-skinny {
  .social.button {
    width: 300px;
  }
}
.phase-2 #eb-modal-bg{opacity:0.3 !important;}
.phase-2, #interstitial { // Rebrand (R4) Phase 2
  .transition-overlay {
    .eb-modal-close {
      padding: 20px;
      color: rgba(255,255,255,0.85);
    }
  }

  .r4-transitioned-overlay {
    width: 500px;
    box-shadow: 0 24px 38px 3px rgba(0,0,0,0.14), 0 9px 46px 8px rgba(0,0,0,0.12), 0 11px 15px 0 rgba(0,0,0,0.2);
    .mod-copy {
      padding: 60px 60px 70px 60px;
      // background: linear-gradient(225deg, $header-color-start 0%, $header-color-mid 37.53%, $header-color-end 100%);
      @include gradient(225deg, $gradient-header);
    }
    .mod-copy2{
      padding: 40px 60px 40px 60px;
    }
    .button.transition-done {padding: 0 40px;}
  }

  .r4-rakuten-transition-overlay {
    width: 800px;
    box-shadow: 0 24px 38px 3px rgba(0,0,0,0.14), 0 9px 46px 8px rgba(0,0,0,0.12), 0 11px 15px 0 rgba(0,0,0,0.2);
    .mod-copy{
      padding: 50px 100px;
    }
    .logo-rak-w, .logo-rak-grad, .logo-rak-b{ width:200px; height:60px; margin:0 auto; }
    .button.transition-done {padding: 0 40px;}
  }

  .is-tablet {
    .r4-transitioned-overlay, .r4-rakuten-transition-overlay { width: 100%; }
  }
}

#interstitial{
  .r4-transitioned-overlay {
    .mod-copy {
      padding: 50px 50px 55px 50px;
      width:400px;
      .logo-rak-w, .logo-rak-grad, .logo-rak-b{ width:200px; height:60px; margin:0 auto; }
    }
  }
  #eb-modal-bg{opacity:0.3 !important;}
}
.r4-transition-overlay {
  width: 800px;
  height: 451px;
  box-shadow: 0 24px 38px 3px rgba(0,0,0,0.14), 0 9px 46px 8px rgba(0,0,0,0.12), 0 11px 15px 0 rgba(0,0,0,0.2);
  .mod-copy {
    padding: 50px 100px 50px 100px;
  }
  .mod-copy0 { width: 560px; }
  .button {
    .transition-done { width: 254px; }
  }
  .logo-rak-grad, .logo-rak-w, .logo-rak-b{ width:200px; height:60px; margin:0 auto; }
}

.is-tablet {
  .r4-transition-overlay{ width: 100%; }
}

.pre-header{
  .r-icon{
    background-image:url('//static.ebates.com/static/images/rakuten/help/icon_rakuten_white.svg');
    background-repeat: no-repeat;
    background-size:100%;
    width:18.5px;
    height:20px;
    vertical-align: -5px;
  }
}
.stag-300 {
  font-family: "Stag Web", Arial, Helvetica, Sans-Serif;
  font-weight: 300;
}