/* --- Blocker Warning --- */
.ABP-container{
  @include background($prim-text-color);
  margin:0;padding:2px 0;overflow:hidden;transition:all .5s .3s;height:78px;
  &.zero-height{ height:0; padding:0; }
}
.ABP-warning{
  margin:8px auto;width:$max-width;height:62px;overflow:hidden;
  @include round;
  .button {transition:all 0.1s;right:35px;top:17px;width:160px;}
  > div{height:inherit;margin-top:-1px;}
}
.ABP-text{
  top:0;
  left:0;
  width:730px;
  overflow:visible;
  z-index:1;
  &:before{
    background:$cb-color;
    border-radius:50%;
    color:white;
    content:"!";
    font-size:16px;
    font-weight:bold;
    height:17px;
    left:18px;
    line-height:17px;
    position:absolute;
    text-align:center;
    top:15px;
    width:17px;
  }
  div{ white-space:nowrap; overflow:visible; }
}
.IE {
  .ABP-text{
    padding-left:58px;
    &:before{
      font-size:27px;
      height:29px;
      line-height:1.15;
      top:17px;
      width:29px;
    }
  }
}

.filter-blk {
  top:0;
  left:400px;
  width:330px;
  z-index:2;
  &:before{background:#cdcdcd;content:"";height:43px;right:2px;position:absolute;top:10px;width:1px;}
  > div{line-height:1.3;}
}

.ABP-info{
  top:0;
  left:810px;
  width:400px;
  z-index:3;
  a {
    color: $brand-01;
    &:before{background:$brand-01; border-radius:50px;color:white;content:"\203A";font-size:19px;height:12px;right:-15px;line-height:9px;position:absolute;text-align:center;top:3px;width:10px;padding:0 0 0 2px;font-family:Georgia, Geneva, sans-serif;}
  }
}
.IE {
  .ABP-info{left:418px;width:800px;}
  .IE-hide{display:none;}
}

.blockdecoy{position:absolute;left:0;bottom:0;}

.ABP-help .install-filter.hide,.install-filter.hide{display:none !important;}

.new-ui {
  .ABP-warning {
    .button { margin-top: -6px; top: 21px; }
  }
}