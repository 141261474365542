// DO NOT US THIS SCSS IN NEW DEVELOPMENT
.button {
  @include button;
  min-width: 106px;
  &.sm {
    height: 15px;
    line-height: 17px;
    padding: 0 4px;
  }
  &.lg {
    min-width: 175px;
    font-size: 18px;
    height: 46px;
    line-height: 46px;
    padding: 0 20px;
  }
  &.xlg {
    font-size: 24px;
    height: 50px;
    line-height: 50px;
  }
  &.xxlg {
    font-size: 28px;
    height: 54px;
    line-height: 54px;
  }
}

.button.ext{
  @include background($cb-color);
  &:hover{ @include background($cb-color-hover); color: $white-color; }
}
.button.ext.hollow{
  background: transparent;
  @include border( $border-color: $cb-color);
  color: $cb-color;
  &:hover{
    background: transparent;
    @include border($border-color: $cb-color-hover);
    color: $cb-color-hover;
  }
}
.button.ext.white{
  @extend .button.ext.hollow;
  border: none;
  @include background($white-color);
  &:hover{
    @include background($white-color);
    border: none;
    color: $cb-color-hover;
  }
}

.button.int{
  @include background($brand-01);
  &:hover{ @include background($brand-01-hover); color: $white-color; }
}
.button.int.hollow{
  background: transparent;
  @include border($border-color: $brand-01);
  color: $brand-01;
  &:hover{
    background: transparent;
    @include border($border-color: $brand-01-hover);
    color: $brand-01-hover;
  }
}
.button.int.white{
  @extend .button.int.hollow;
  border: none;
  @include background($white-color);
  &:hover{
    @include background($white-color);
    border: none;
    color: $brand-01-hover;
  }
}
.button.int.bg-w{background-color:$white-color;color:$brand-01;}

.button.int.hov:hover{ @include background(#3abf56); }
.button.dis{ @include background($border-color); color:$prim-text-color;}
.button.dis.hov:hover{ @include background($border-color); }
.button.white{ @include background($white-color); }
.button.white.f-blu{color:$bonus-color;}
.button.blu,
.button.cc{ @include background(#017edb); }
.button.bonus{ @include background($bonus-color); }
.button.no-bg{color:$cb-color;}

.button.gry3,
.button.luxe{
  @include background($prim-text-color);
  &:hover{ @include background($prim-text-color-hover); color: $white-color; }
}
.button.luxe.hollow{
  background: transparent;
  @include border($border-color: $prim-text-color);
  color: $prim-text-color;
  &:hover{
    background: transparent;
    @include border($border-color: $prim-text-color-hover);
    color: $prim-text-color-hover;
  }
}
.button.luxe.white{
  @extend .button.luxe.hollow;
  border: none;
  @include background($white-color);
  &:hover{
    @include background($white-color);
    border: none;
    color: $prim-text-color-hover;
  }
}

.button.cred{
  @include background($cc-color);
  &:hover { color: $white-color; }
}
.button.cred.hollow{
  background: transparent;
  @include border( $border-color: $cc-color);
  color: $cc-color;
}
.button.cred.white{
  @extend .button.cred.hollow;
  border: none;
  @include background($white-color);
}

.button.bonus{
  @include background($bonus-color);
  &:hover { color: $white-color; }
}
.button.bonus.hollow{
  background: transparent;
  @include border( $border-color: $bonus-color);
  color: $bonus-color;
}
.button.bonus.white{
  @extend .button.bonus.hollow;
  border: none;
  @include background($white-color);
}

.button.gold{ @include background($just-for-you-color); } // golden links

.social.fb{
  .fa-facebook {
    color: $social-facebook-color;
  }
}

.social.goog{
  @include background($social-google-color);
  &:hover{ @include background($social-google-color-hover); color: $white-color; }
}

.social.goog2{
  @include background($white-color);
  @include border( $border-color: $prim-text-color);
  color: $prim-text-color;
}

.social.twitter{ @include background($social-twitter-color); color: $white-color; }

.social.int{
  @include background($brand-01);
  &:hover{ @include background($brand-01-hover); color: $white-color; text-decoration: none; }
}

.social.link{
  @include background($sec-text-color);
  &:hover{ @include background($gray-dark-8-color); }
}

.button.social{position:relative;min-width:95px;}
.button.social {
  .fa-facebook,
  .fa-google,
  .fa-apple {
    margin-right: 15px;
  }
}

.button.sm.social span{height:16px;line-height:16px;}
.button.reg.social span{height:30px;line-height:30px;}
.button.lg.social span{height:46px;line-height:46px;}
.button.xlg.social span{height:50px;line-height:50px;}
.button.xxlg.social span{height:40px;line-height:40px;}
.button.xxlg.social{font-size:16px;}
.button.sm.social.fb{padding:0 6px 0 20px;}
.button.sm.social.goog{padding:0 6px 0 25px;}
.button.reg.social.fb,.button.lg.social.fb{}
.button.reg.social.goog,.button.lg.social.goog{}
.button.xlg.social.fb{padding:0 32px;}
.button.xlg.social.goog{padding:0 48px;}
.button.xxlg.social.fb{padding:0 32px;}
.button.xxlg.social.goog{padding:0 48px;}

.button.reg.social.hdr{width:95px;font-size:14px;padding:0 30px;}
.button.reg.social.hdr span{left:10px;}

.button.reg.social.goog2 .icn-goog.f-14{
  @extend .square-14;
  @extend .lh-1;
  margin-top:7px;
}
.button.reg.social.goog2 .icn-goog.f-18{
  @extend .square-18;
  @extend .lh-1;
  margin-top:5px;
}
.button.lg.social.goog2 .icn-goog.f-18{
  @extend .square-18;
  @extend .lh-1;
  margin-top:14px;
}

.button.nopad{padding:0;}
.button.norad{ @include round(0); }

.button.reg.ht-36,
.button.ht-36{ @extend .ht-36; @extend .lh-36; }
.button.reg.ht-38,
.button.ht-38{ @extend .ht-38; @extend .lh-38; }
.button.ht-40{ @extend .ht-40; @extend .lh-40;}
.button.ht-50{ @extend .ht-50; @extend .lh-50;}

.button.f-norm{ @extend .f-norm; }
.button.lg.f-16,
.button.xlg.f-16{ @extend .f-16; }
.button.reg.f-18,
.button.xlg.f-18{ @extend .f-18; }

.button.reg.w-106{ @extend .w-106; padding:0;}

.button.pill{height:46px;line-height:46px;padding:0 30px;}
.button.pill.int:hover{ @include background($white-color); color:$brand-01;}
.button.pill.white.int{
  color:$brand-01;
  @include border( $border-color: $brand-01 );
}
.button.pill.white.int.current,
.button.pill.white:hover{ @include background($brand-01); color:$white-color;}

.mwview{
  .button.reg{ min-width:100px; }
  .button.pill{ height:40px;line-height:40px;padding:0 20px; @extend .f-14;}
}

.button.border-gry-3{color:$prim-text-color;}
.button.f-grn{ color:$brand-01; }

.button.round-30{ @include round(30px); }

.share-icon{
  display:block;
  float:left;
  @extend .mar-5-lr;
  &:last-of-type{ margin-right:0; }
}
.share-icon {
  a {
    @extend .blk;
    @extend .center;
    @extend .circle;
    @extend .cursor;
  }
}
.share-icon.square-30 {
  a {
    @extend .square-30;
    @extend .lh-24;
  }
}
.share-icon.square-46 {
  a {
    @extend .square-46;
    @extend .lh-52;
  }
}
.cursor,.pointer{cursor:pointer;}
.cursor-def{cursor:default;}

.new-ui{
  // This will all be refactored and moved to _buttons-new.scss.
  // Do not overwrite any old buttons for rebrand, please refactor the code and use the new buttons
  .button {
    @extend .f-16;
    @include round(23px);
    @include height(46px);
    @include line-height(46px);
    padding: 0 25px;
    .arrow{ @extend .hide; }
  }
  .button.ext.r4,
  .button.ext,
  .iscb-item .button.int{
    color: $prim-text-color;
    border: 1px solid $prim-text-color;
    @include background($white-color);
    &:hover{
      color: $white-color;
      @include background($prim-text-color);
    }
  }
  .button.lg { @include round(30px); }
  .button.reg.social.hdr{
    @include round(20px);
    @include height(30px);
    @include line-height(30px);
    min-width:95px;
    font-size:14px;
  }

  .help-flyout.button.int{
    color: $white-color;
    border: 1px solid $prim-text-color;
    @include background($prim-text-color);
    &:hover{
      color: $white-color;
      @include background($prim-text-color);
    }
  }
}
.join-button.submit-button.button.display {
  // temp hack for signup overlay like /marzia.htm until all pages that use it are rebranded
  color: $white-color;
  font-size: 18px;
  @include height(46px);
  @include line-height(46px);
}

.button {
  &.display{
    &.primary-alt{
      // temp hack for pages like /b/lg/ until all pages that use it are rebranded
      @include height(46px);
      @include line-height(46px);
      color: $white-color;
      @include background($cb-color);
      @include border( $border-color: $white-color );
      &:hover {
        color: $cb-color;
        @include background($white-color);
        @include border( $border-color: $white-color );
      }
    }
  }
}
