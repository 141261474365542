@charset "utf-8";
@import "partials/fonts-rebrand";

@import "partials/functions";
@import "partials/variables";
@import "partials/mixins";
@import "partials/images";

* { margin:0; padding:0; }
@import "layout/grid"; // Load Ebates Custon Grid
.container-fluid{min-width:$max-width;}
.container-fixed{width:$max-width;}
.top-0{position:fixed !important;top:0;}

@import "layout/grid-legacy"; // Load Ebates Legacy Custon Grid

@import "layout/grid-flex"; // Flexbox Grid under development.

@import "layout/padmar"; // Load Padding and Margin Styles

@import "layout/colors"; // Load Background Color Styles

@import "layout/typography"; // Load Typography/Font Styles
@import "layout/typography-new"; // Load NEW Typography/Font Style

@import "layout/dimensions"; // Load Dimension Styles
// Dimension Styles include:
// Styles for block heights ex: .ht-50
// Styles for block dimensions ex: .square-50, .logo-150, .w-150, etc.

@import "layout/r4_overlay";

li:hover > a.grn-hover,
.grn-hover:hover span{color:$brand-01;}

.pad-0-b-last {
  li {
    &:last-child,
    &:only-child { padding-bottom: 0; }
  }
  p {
    &:last-child,
    &:only-child { padding-bottom: 0; }
  }
  p {
    &:last-child,
    &:only-child { margin-bottom: 0; }
  }
}
img{border:none; }
/* reusable >>> */

.mirror{-moz-transform:matrix(-1, 0, 0, 1, 0, 0);-webkit-transform:matrix(-1, 0, 0, 1, 0, 0);-o-transform:matrix(-1, 0, 0, 1, 0, 0);-ms-transform:matrix(-1, 0, 0, 1, 0, 0);filter:progid:DXImageTransform.Microsoft.BasicImage(mirror=1);}
.clr,.clear,.clear-both{clear:both;}
.clear-no{clear:none;}
.clearfix{ @include clearfix(); }
// .clearfix:after{visibility:hidden;display:block;font-size:0;content:" ";clear:both;height:0;}
* + html .clearfix{display:inline-block;}
.no-f-boosting{max-height:999999px;}
.no-select{ @include user-select; }
.merch-logos{margin:25px 0;text-align:center;}

@import "layout/buttons"; // Load Button Styles
@import "layout/buttons-new"; // Load Button Rebranded Styles

@import "layout/shapes"; // Load Shape Styles
// Shape Styles include round corners, arrows, etc.

@import "layout/borders"; // Load Border Styles

@import "layout/tooltips"; // Load Tooltip Styles

@import "layout/tabs"; // Load Tab Styles

.safari-z-index-fix,.overlay,#overlay,#join-signup,#signupOverlay{-webkit-transform:translate3d(0,0,0);}

.loader,
.loader-fixed {
  border: 10px solid $default-bg-color;
  border-top: 10px solid $brand-01;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  animation: spinloader 0.9s linear infinite;
}
@keyframes spinloader {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
/* reusable <<< */

@import "layout/header"; // Load Header Styles

#pge{ position: relative; }

@import "layout/footer"; // Load Footer Styles

@import "layout/interstitial"; // Load Interstitial Styles

div.exceptions {
  margin: 0 20px;
  p, ul {
    font-size: 0.9em;
    margin: 0;
    padding-bottom: 8px;
  }
}
/*login form*/
#logincontent {
  width: 780px;
  padding: 26px 0 0 0;
  .fb {
    border: none;
    h2 { color: #3c5a9a; }
  }
  form {
    label { margin: 10px 0 0 0; }
    input{
      &.text {
        width: 210px;
        color: $prim-text-color;
        padding: 2px;
      }
    }
    ul {
      li {
        &.err { margin: 25px 0 0 0; }
        &.fp { margin: 15px 0 0 0; }
        span {
          &.error { color: #c30; }
          &#forgotpwd { padding:6px 10px 0 10px; }
        }
      }
    }
  }
  ul {
    list-style: none;
    li { margin: 10px 0 0 0; }
    &#benefits {
      list-style-type: disc;
      margin: 0 0 0 25px;
    }
  }
  input {
    &.errorborder { border: solid 1px #c30; }
  }
}
.logincol{padding:0 5px 0 20px;width:233px;border-right:2px solid #f0f0f0;height:412px;}
@import "layout/forms"; // Load Form Styles

@import "layout/promo"; // Load Promotion Styles

/* --- global expander plugin ---*/
.expander {
  .easein-gradient {padding-left:59px;background: transparent url(#{$static-path}static/images/ui/expander/trans-white-grad-60x1.png) repeat-y 0 0;}
  span {
    color: $brand-01;
    @include rakutenSansUIsemiBold;
    &.white-bg { padding-left: 12px; }
    &.more { display: none; }
  }
  &:hover{
    color: $brand-01-hover;
    text-decoration: none;
  }
  &.min {
    .less { display: none; }
    .more { display: inline; }
  }
}
.expander-parent {
  overflow: hidden;
  padding-bottom: 20px;
  &.transition {
    transition: all .4s;
    -webkit-transition: all .4s;
  }
}
.expand-me,.long-desc p,.two-lines{line-height:18px;}
/* --- END global expander plugin ---*/

/* --- global expander v2 plugin with horizontal scroll for google to index hidden text---*/
.expand-content {
  position: relative;
  overflow: hidden;
  &.transition {
    transition: all .4s;
    -webkit-transition: all .4s;
  }
}
.expander2 {
  @include rakutenSansUIsemiBold;
  .easein-gradient {padding-left:59px;background: transparent url(#{$static-path}static/images/ui/expander/trans-white-grad-60x1.png) repeat-y 0 0;}
  .white-bg { padding-left: 12px; }
  .white-bg,
  .more,
  .less {
    color:$brand-01;
    @include rakutenSansUIsemiBold;
  }
  .more{ display: none; }
  span{ color:$brand-01; }
  &:hover{
    color:$prim-text-color;
    text-decoration: none;
  }
  &.min {
    .less{display:none;}
    .more{display:inline;}
  }
}
.expander2-parent{overflow-x:scroll;}
/* --- END global expander v2 plugin ---*/

@import "layout/stores";
@import "layout/stores-hybrid"; // Hybrid store modules shared with Desktop and MW.

/*Terms and privacy pop up window*/
div#dwindow{
  @include background($white-color);
  border: 1px solid $prim-text-color;
  left: 100px;
  top: 100px;
  width: 425px;
  height: 250px;
  text-align: left;
  font-size: 11px;
  line-height: 1.4em;
  position: absolute;
  display: none;
  z-index:999;
  .close{
    @include background($brand-01);
    height: 14px;
    position: relative;
    a { background: url(#{$static-path}static/images/overlays/legacy-close-x.png) no-repeat 0 0;
      position: absolute;
      top: 0;
      right: 1px;
      width: 13px;
      height: 13px;
      cursor: pointer;
      display: block;
    }
  }
  .term-close {
    a {
      top: -2px;
      right: 1px;
      width: 14px;
    }
  }
}

div#dwindowcontent {
  height: 90%;
  overflow: auto;
  padding-left: 5px;
  padding-top: 5px;
  h1 { margin-bottom: 5px; }
}

div.sharing {
  margin-bottom: 10px;
  clear: both;
  height: 20px;
  .share-btn {
    float: left;
    display: block;
    margin-right: 5px;
  }
}

/* --- Generic Notifications --- */
#backgroundPopup{background:$black-color;display:none;height:300%;left:0;position:fixed;top:0;width:300%;}
.h-v-centered {position:absolute;top:15px;bottom:0;left:15px;right:0;margin:auto;}
.sortWaitBox {display:block;position:fixed !important;width:200px;height:28px !important;background:white url(#{$static-path}static/images/account/global/load5.gif) no-repeat center 25px;text-align:center;font-weight:bold;padding-top:144px;}

/* --- Email auto-corrector --- */
.corrected-email{border:1px solid $brand-01 !important;}
.corrected-note {
  @include background($white-color);
  position: absolute;
  padding: 20px;
  color: $prim-text-color;
  height: auto;
  border: 1px solid $border-color;
  left: -230px !important;
  text-align: center;
  top: -15px !important;
  z-index: 90 !important;
  width: 180px !important;
  &:after,
  &:before {
    left: 100%;
    top: 32px;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
  }
  &:after {
    border-left-color: $white-color;
    border-width: 6px;
  }
  &:before {
    border-left-color: $border-color;
    border-width: 8px;
    margin-top: -2px;
  }
}

/* --- Facebook connect Email not found error --- */
div.connection-error-verlay{
  @include background($white-color);
  height: 130px;
  left: 25%;
  top: 120px;
  position: absolute;
  width: 600px;
  z-index: 99999;
  display: block;
  border: 1px solid $prim-text-color;
}

/*Close icon in overlays*/
div#overlay div.msg-bar a#disable,
div#signupOverlay div.msg-bar a#disable,
div#join-signup a#disable,
#signupOverlay a.close,
#join-signup a.close {
  background-position: -151px -91px !important;
  height: 10px;
  width: 10px;
  position: absolute;
  right: 15px;
  top: 4px;
  cursor: pointer;
  text-decoration: none;
}

/* --- Tablet Only --- */

a.readmore-expande { color: $prim-text-color; }

@import "layout/abp"; // Load Adblock Plus Styles

// Begin Global SVG Icons >>>
.svg-eb-logo{background-image:url("#{$fonts-path}svg/logo-ebates-rakuten.svg");background-repeat:no-repeat;background-size:100%;}
.svg-eb-logo2{background-image:url("#{$fonts-path}svg/ebates-rakuten.svg");background-repeat:no-repeat;background-size:100%;}
.svg-eb-logo3 {
  background-image: url("#{$static-path-rakuten}rakuten_nav_solid.svg");
  // background-image: url("#{$static-path-rakuten}rakuten_nav_pride.svg"); // Pride Logo.
  background-repeat: no-repeat;
  background-size: 100%;
}
.svg-eb-logo3.alt{background-image:url("#{$fonts-path}svg/Ebates_yellow_stacked.svg");background-repeat:no-repeat;background-size:100%;}
.targ-cb-lte.svg{background-image:url("#{$fonts-path}svg/diamond-B68C47-light-bg.svg");background-repeat:no-repeat;background-size:100%;}
.targ-cb-drk.svg{background-image:url("#{$fonts-path}svg/diamond-c49859-dark-bg.svg");background-repeat: no-repeat;background-size:100%;}
.in-store-logo.svg{background-image:url("#{$fonts-path}svg/ISCB-icon.svg");background-repeat: no-repeat;background-size:100%;}
.rakuten-footer-logo{background-image:url("#{$static-image-path-rakuten}footer/NBA.svg");background-repeat:no-repeat;background-size:100%;}
.ebates-e.svg{background:url("#{$fonts-path}svg/e-ebates.svg") no-repeat 50% 50%;}
.rakuten-r.svg{background:url("#{$static-path-rakuten}rakuten-lettermark-black.svg") no-repeat 50% 50%;}
.icn-goog.svg{background-image:url("#{$fonts-path}svg/google/google_G_logo.svg");background-repeat:no-repeat;background-size:100%;}
// End Global SVG Icons <<<

// SUSI Form Hacks >>>
.eb-legal-popup {
  h1 {
    font-size: 12px;
    color: $brand-01;
  }
  p {
    font-size: 12px;
    color: $prim-text-color;
  }
}
// .skinny-si-modal .join-button,
// .skinny-su-modal .join-button{height:40px;padding:0 !important;}
// SUSI Form Hacks <<<

.new-ui {
  .welcome {
    .welcome-h {
      .welcome-copy {
        div {
          &:first-child {
            @include stag(Stag Light Web, 300);
            letter-spacing: 0.03167rem;
          }
          &.stag-med { @include stag(Stag Medium Web, 500); }
          &:nth-child(2) {
            @include stag(Stag Medium Web, 500);
            letter-spacing: 0.03167rem;
          }
        }
        &.cyber-w { // Used in the Cyber Days HP
          div {
            &:nth-child(2) {
              @include rakutenSansUI(Rakuten Sans UI, 400);
              letter-spacing: normal;
            }
          }
        }
      }
      .home-su-form-wrap {
        .gc-noovr-head {
          .f-blu-bonus { color:$brand-01; }
          z-index: 100;
        }
        .button.int { @include background ( $cb-color ); }
        .join-button { font-size: 16px; }
        .no-modal-terms{
          color: $gray-dark-8-color;
          text-decoration: underline;
        }
      }
    }
    .media-quotes-carousel {
      .nav-item {
        &.active {
          @include background ( $cb-color );
          &:hover { @include background ( $cb-color-hover ); }
        }
      }
    }
  }

  //SU/SI form
  .login-option {
    &:before,
    &:after{
      content: "";
      position: absolute;
      border-bottom: 1px solid $darker-border-color;
      top: 7px;
      width: 20px;
      width: 20px;
      line-height: 29px;
    }
    &:after {
      left: 100%;
      margin-left: 15px;
    }
    &:before {
      right: 100%;
      margin-right: 15px;
    }
  }
  .start-shopping {
    .start-shopping-copy {
      p {
        &:first-child {
          @include stag(Stag Medium Web, 300);
          letter-spacing: 0.03167rem;
        }
      }
    }
  }
  .earn-cash-back {
    .earn-cash-back-copy {
      div {
        &:first-child {
          @include stag(Stag Medium Web, 300);
          letter-spacing: 0.03167rem;
        }
      }
    }
  }
  .get-bfc {
    .get-bfc-copy {
      div {
        &:first-child {
          @include stag(Stag Medium Web, 300);
          letter-spacing: 0.03167rem;
        }
      }
    }
  }
  .button-overlay{ // Button Overlay
    @include store-tile;
    .button-overlay-carousel {
      .nav-item {
        &.active { @include background ( $prim-text-color ); }
      }
    }
    .button-overlay-bottom {
      .browser-button {
        @extend .button.primary;
        span { color: $white-color; }
      }
      .button-overlay-terms {
        a {
          color: $tert-text-color;
          text-decoration: underline;
        }
      }
      .fa-star{color: $brand-01;}
    }
  }
  .f-white-all *{
    &.goog{
      color:$prim-text-color !important;//Fix the G connect button in dropdown menu
    }
  }
  .fa-google {
    background: url("https://static.ebates.com/static/fonts/svg/google/google_G_logo.svg") no-repeat 100%;
    position: relative;
    margin: auto;
    float: left;
    height: 20px;
    width: 18px;
    left: 63px;
    &:before { display: none; }
  }

  .chatOpen{ //Chat icon
    background-color: $cb-color;
    font-size: 16px;
    z-index: 102;
    padding: 0 20px;
    height: 46px;
    line-height: 46px;
    @include round(23px);
  }
  .eb-form-skinny,.eb-form-Store-Overlays{
    .button.int { @include background ( $cb-color ); }
    .eb-su-footer {
      a{ color: $gray-dark-8-color; }
      .eb-forgot-pass,
      .join-now-link { color: $brand-01; }
    }
  }
  .page-level-join {
    &.pad-80-tb {
      padding-top:80px;
      padding-bottom: 80px;
    }
    .join-bonus { @include stag(Stag Medium Web, 500); letter-spacing: 0.03167rem; }
    .button.int.white{
      @extend .button.int.hollow;
      @include border( $border-color: $white-color);
      @include background ( $cb-color );
      color: $white-color;
      width:300px;
      font-size: 16px;
      &:hover{
        @include border( $border-color: $white-color);
        color: $cb-color;
        @include background ( $white-color );
      }

    }
    div > div {
      @include stag(Stag Light Web, 300);
      letter-spacing: 0.03167rem;
    }
  }

  h2.kr, a.kr { color: $prim-text-color; }

  .landing-susi-cont {
    .landing-landing-header {
      .f-blu-bonus { color: $prim-text-color; }
    }
    .target-merchant {
      .bonus.f-blu-bonus { color: $prim-text-color; }
    }
    .eb-auth-form {
      .susi-password-field::after,
      .susi-email-field::after { top: 12px; }
      input[type="text"],
      input[type="password"] {
        font-size: 16px;
        padding-top: 10px;
        padding-bottom: 10px;
        &.eb-captcha {
          padding-top: 0;
          padding-bottom: 0;
        }
      }
      .susi-refer-cta { margin-bottom: 0;  }
      .susi-email-field {
        .email-address {
          &.border-b-0 {
            @include border;
            margin-bottom: 10px;
          }
        }
      }
      .susi-password-field { margin-bottom: 10px; }
    }
    .eb-terms, .eb-privacy {
      color: $gray-dark-8-color;
      text-decoration: underline;
    }
    .landing-su-form {
      .eb-su-footer{ padding-top: 15px; }
      .fa-google-plus { @extend .fa-google; }
    }
    .google-join { .fa-google-plus { @extend .fa-google; } }
    .eb-forgot-pass.f-12,
    .f-12.iblk.mar-20-t {
      font-size: 16px;
      line-height: 20px;
    }
  }
  .landing-button-cont {
    .promo-list {
      .button.int {
        @include background ( $cb-color );
        color: $white-color;
      }
    }
  }

  #join-signup {
    &.verify-login {
      width: 400px;
      border: none;
      .top-bar {
        > span{
          color:$prim-text-color;
          font-size: 24px;
        }
        p { color: $sec-text-color; }
      }
      .button {
        &.create-pwd-btn,
        &.resend-btn,
        &.login-verify-btn,
        &.skinny-join-btn,
        &.create-pwd-emailbtn {
          @extend .button.primary;
          padding: 0 25px !important;
          font-size: 16px !important;
        }
      }
      a {
        &.forgotOverlay {
          font-size: 16px;
          line-height: 20px;
          color: $brand-01;
          text-align: center;
          margin-top: 5px;
          margin-bottom: 5px;
          @extend .blk;
        }
      }
      &.create-password {
        width: 450px;
        .border-grn { @include border( $border-width: 1px, $border-color: $brand-01 ); }
        .button {
          &.create-pwd-emailbtn {
            @extend .button.primary;
          }
        }
      }
      #join-signup-form {
        &.send-reset {
          width: auto;
          .square-46 {
            &.border-grn { @include border( $border-width: 1px, $border-color: $brand-01 ); }
          }
        }
      }
    }
    &.forgot {
      border: none;
      .button {
        &.skinny-forgot-btn {
          @extend .button.primary;
          padding: 0 25px;
          font-size: 16px;
          margin-top: 5px;
        }
      }
      p {
        &.instruction { color:$prim-text-color; }
      }
      a {
        &.remove-ovr {
          color: $darker-border-color;
        }
      }
    }
  }

  .browser-extension {
    .sf-left {
      .sf-loaded{
        &.d1{
          width: 580px;
          .d2{ font-size: 22px; }
          .d3{ font-size: 13px; }
        }
      }
    }
    .footer-fixed-center{
      .sf-right {
        &.pos-abs-r { right: 390px; }
        .sf-members {
          font-size: 12px;
          line-height: 17px;
        }
      }
      .button {
        &.browser-button {
          @extend .button.primary;
          font-size: 16px;
          padding: 0 20px;
          width: 162px;
          min-width: 162px;
        }
      }
      .footterms-conditions {
        span, a { color: $gray-dark-8-color; }
        .terms-link { text-decoration: underline; }
      }
    }
  }

  .text-me-footer {
    .text-me-footer-left {
      @include stag(Stag Medium Web, 500);
      font-size: 28px;
      line-height: 46px;
    }
    .button{
      &.text-link {
        font-size: 16px;
        @include background ( $cb-color );
      }
    }
  }

  .up-top-container {
    .up-top{
      .fa-arrow-up.f-grn { color: $prim-text-color; }
    }
  }
  .min .up-top{ top: -130px; }

  .copy-coupon-code-tooltip.bg-grn{
    &.tooltip { @include background($gray-dark-8-color); }
    &:after,
    &:before { border-bottom-color: $gray-dark-8-color; }
  }

  .iscb-hp-mod { // ISCB Modal
    .button {
      &.iscb-get-started { @extend .button.primary; }
    }
  }
  .grey-bar { top: -89px; }
}

.aside {
  .border-grn-t-2 { @include border-top; }
  .double-cb {
    h2 {
      padding: 20px;
      font-size: 28px;
      &:hover {
        text-decoration: none;
        a { text-decoration: none; }
      }
    }
    .see-all {
      font-size: 18px;
      line-height: 20px;
      color: $brand-01;
    }
  }
}

.featured-ad-slot.gpt-ad-slot { //for GAM Ads
  @include store-tile;
}
.store-tile-gen { // Generic 8px round shadow container
  @include store-tile;
}

@import "partials/experimental";

// EOF END OF FILE
