.premium-deals { //Home Page Premium Stores.
  .coupon-code{
    position: initial;
    .promo-code {
      &.f-grn { @extend .f-white; }
      background-color: $brand-01;
      color: $white-color;
      font-size: 14px;
      padding: 5px;
      letter-spacing: 1px;
      border-radius: 5px;
    }
    .tooltip-container {
      display: none;
      position: absolute;
      background-color: $brand-01;
      z-index: 100001;
      min-width: 80px;
      padding-left: 15px;
      padding-right: 15px;
      margin-left: 0;
      border-style: hidden;
      margin-bottom: 5px;
      border-radius: 8px;
      &.expand{ width: 155px; }
      &.arrow-b::after{ border-top-color: $brand-01; left:50%; top:100%; }
      &.arrow-b:before{ border-color: transparent; }
    }
    &:hover {
      .tooltip-container{ display: block; }
    }
  }
}

/* coupon block */
.coupon-blk {
  .coupon-fav{
    height:0;padding-bottom:0;
    .fav-sm{position:relative;left:100%;top:-15px;}
  }
}

.store-cb-cats {
  .blk1 {
    width: 826px; //from legacy ice_w.css
    .col2 {
      width: 48%;
    }
  }
}

.travel-widget form{width: 790px;}

/* stores >>>*/
.fav-sm.fa-hear,.fav-s.fa-hear{width:18px;height:16px;padding-top:2px;}
.fav-sm.fa-hear.off,.fav-s.fa-hear.off{color:$border-color;}
.fav-sm.fa-hear.off:hover,.fav-s.fa-hear.off:hover{color:$cb-color}
.fav-sm.fa-hear.on,.fav-s.fa-hear.on{color:$cb-color;}
.fav-sm.fa-hear.on:hover,.fav-s.fa-hear.on:hover{color:$border-color;}
.fav-sm.fa-hear span,.fav-s.fa-hear span{display:none;line-height:12px;width:12px;height:12px;position:absolute;top:2px;left:3px;}
.fav-sm.fa-hear:hover span,.fav-s.fa-hear:hover span{display:block;}
.fav-sm.fa-hear.on:hover span,.fav-s.fa-hear.on:hover span{color:$cb-color}
.fav-sm.fa-hear.on:hover span:before,.fav-s.fa-hear.on:hover span:before{content:"\f00d";}
.fav-sm.fa-hear.off:hover span,.fav-s.fa-hear.off:hover span{color:$border-color;}
.fav-sm.fa-hear.off:hover span:before,.fav-s.fa-hear.off:hover span:before{content:"\f067";}
.fav-sm.fa-hear.off:hover span,.fav-s.fa-hear.off:hover span{top:3px;}

.fav-sm {
  &.fa-heart {
    width: 18px;
    height: 16px;
    padding-top: 2px;
    &.off {
      color: $border-color;
      &:hover {
        color: $cb-color;
        span {
          top:3px;
          color: $border-color;
          &:before { content: "\f067"; }
        }
      }
    }
    &.on {
      color: $cb-color;
      &:hover {
        color: $border-color;
        span {
          color: $cb-color;
          &:before {content:"\f00d";}
        }
      }
    }
    span {
      display: none;
      line-height: 12px;
      width: 12px;
      height: 12px;
      position: absolute;
      top: 2px;
      left: 3px;
    }
    &:hover {
      span {display:block;}
    }
  }
}

*.ui2012,
*.ui2014,
*.ui2015 {
  background: url(//static.ebates.com/global_files/layout_v1/images/sprites/ui2015.1.0.6.png) no-repeat 0 0;
  /*sprite*/
  //Legacy close icon, need to refactor to use font awesome
}

.store-main {
  margin-top:-56px;

  .blk0.deals-cont { // fix layout in groupon
    @extend .blk;
  }

  .return-bonus{
    width:826px;position:relative;
    a.bonus-link{ position:absolute;left:0;z-index:1;width:826px;height:130px;display:block; }
  }
}

.local-deals {
  @include store-tile;
  margin-bottom: 10px;
}
#groupon {
  .deals-cont {
    @extend .blk;
    .deal-blk {
      @include store-tile;
      position: relative;
      text-align: center;
      .gp-img-cont {
        @include round(8px 8px 0 0);
        .gp-img {
          display: block;
          image-rendering: auto;
        }
      }
      &.col4 {
        &.col-last { margin-right: 0; }
      }
    }
  }
}

/* stores v2 >>> */
.coupon-blk-header{margin-top:20px;}
.coupon-blk-header:first-child{margin-top:0;}
/* no-discount stores */
.no-discount .message-w{width:870px;}
.no-discount .welcome-bonus{width:766px;}
.no-discount .bonus .button.reg{padding:0;width:127px;}
/* stores <<<*/

.dd-ribbon{width:14px;height:27px;background-position:-148px -24px;}

.coupon-desc .expander-parent{padding-bottom:18px;}
.coupon-desc .expander-parent.min{padding-bottom:0;}

.coupon-desc .expander{line-height:26px}
#moreStoresTable .tbl-hide{display:none;}
.coupons-list .cpn-right{width:70%;}
.coupons-list .cpn-code-row{line-height:30px;}
.coupons-list .cpn-code{display:inline-block;}
.coupons-list .expander-parent{padding-bottom:20px;}
.coupons-list .expander-parent.min{padding-bottom:0;}
.products-list .pdt-middle{width:42%;}
.products-list .pdt-right{line-height:18px;width:25%;}
.products-list .pdt-price{}
.results-pages{overflow:hidden;}
.search-arrow{float:left;}
.search-arrow .prev-dis,.search-arrow .next-dis{background:#555;display:block;height:15px;margin:0 0 0 12px;width:15px;}
.search-arrow .prev-page,.search-arrow .next-page{background:#faa;display:block;height:15px;margin:0 0 0 12px;width:15px;}
.select{overflow: hidden;padding: 0 10px;position: absolute;border:1px solid $prim-text-color;height:23px;width:273px;line-height:23px;}
.select.sidebar{
  cursor:pointer;position:relative;z-index:5;height:100%;width:inherit;
  @extend %opacity-0;
}
.filter-item{height:25px;width:295px;}
.filter-label{height:20px;}
.arrow-wrap .bblk{display:block;}
.arrow-wrap .arr-up{margin:6px 0 2px;}
.arrow-wrap .arr-down{}
.ajax_box_container{text-align:center;}
/* --- CC Upsel on store pages --- */
.justify{text-align:justify;}
.justify:after{content:"";display:inline-block;width:100%;}
/* --- Store Categories/Tiered START --- */
.catcolumn{padding:0 0 0 4px;width:284px;}
.catcolumn li span{float:left;line-height:20px;}
.catcolumn .head span{line-height:24px;}
.catcolumn a > .catname{color:$brand-01;}
.catcolumn a > .catname,.catcolumn .cattitle{width:198px;}
.catcolumn .cb,.catcolumn .cbtitle{text-align:right;width:84px;}
.catcolumnl{margin:0 36px 0 0;}
.homebtn{clear:both;display:block;float:right;padding:10px 0 0;text-align:right;}
/* --- Store Categories/Tiered END --- */
// .mkt-ops h3, .mkt-ops p, .mkt-ops ol, .mkt-ops ul{margin-bottom:10px;}
// .mkt-ops p:last-child, .mkt-ops ul:last-child, .mkt-ops ol:last-child{margin-bottom:0px;}
// .mkt-ops ol{list-style-position:inside;}
// .mkt-ops li{margin-bottom:5px;}
// .mkt-ops li:last-child{margin-bottom:0px;}

.tbl-lg tr td{padding:5px 0 5px 10px;}
.tbl-sm tr td{padding:5px 10px;}
.zebra tr:nth-child(odd),.zebra-bg{ @include background(#f4f4f4); }
.zebra.zebra-grn tr:nth-child(odd),.zebra-bg.zebra-grn{ @include background(#e0eed2);}
.store-table .fav{width:19px;padding-left:5px;}
.store-table .store-name{width:220px;}
.store-table .store-rebate{width:220px;}
.store-table .store-shop{width:110px;padding-right:10px;}
table.border-b tr td{border-bottom:1px solid $border-color;}
table.border-b, table.border-b tr:last-child td{border-bottom:none;}
.border-collapse{border-collapse:collapse;}

/* Store Sorter >>> */




.all-stores .loader{position:absolute;left:350px;top:100px;}

#sort-merchants li:hover .sort-cat{display:block;}


/* Store Sorter <<< */

.allstores-page {
  .store-hero {
    display: block;
    @include store-tile;
    .store-hero-image {
      img { @include round(8px 8px 0 0); }
    }
    .store-info {
      padding: 0 30px 30px 30px;
    }
  }
}
.allstores-page,
.cat-page {
  .feature-stores {
    .recommended-blocks {
      height: 230px;
      margin-bottom: 20px;
      .m-feature-store-grid{
        @include store-tile;
        height: 220px;
        padding: 30px 20px;
        .feature-store-grid-link {
          .was_rebate {
            display: block;
            color: $tert-text-color;
            font-size: 12px;
            line-height: 16px;
            height: 16px;
            text-align: center;
            margin-top: 10px;
            margin-bottom: 5px;
          }
          .now_rebate {
            display: block;
            text-align: center;
            font-size: 16px;
            line-height: 20px;
          }
          .see_store {
            display: block;
            text-align: center;
            font-size: 10px;
            line-height: 14px;
            height: 28px;
            color: $tert-text-color;
            margin-top: 10px;
            margin-bottom: 10px;
          }
        }
        &.featured-block {
          width: 381px;
          .logos {
            text-align: center;
            width: 167px;
            height: 160px;
            @include store-tile;
          }
          .featured-content {
            float: right;
            width: 144px;
            text-align: center;
            .featured-title {
              @include rakutenSansUIsemiBold;
              font-size: 12px;
              line-height: 16px;
              height: 27px;
              letter-spacing: 0.5px;
              color: $prim-text-color;
            }
          }
        }
      }
    }
  }
  .main {
    .all-stores-module {
      @include store-tile;
      .all-stores-module-heading {
        @include border-bottom;
        .module-heading {
          display: inline-block;
          width: 55%;
          border-bottom: none;
        }
        .sorter {
          float: right;
          margin-top: 15px;
          margin-right: 30px;
        }
      }
      .sorter {
        .select-wrapper {
          width: 170px;
          height: 40px;
          line-height: 40px;
          float: left;
          display: inline-block;
          cursor: pointer;
          .custom-select {
            display: inline-block;
            position: relative;
            .styled-select {
              @include store-tile;
              float: right;
              width: 170px;
              height: 40px;
              overflow: hidden;
              position: relative;
              .holder-v2 {
                display: block;
                margin: 0 35px 0 20px;
                white-space: nowrap;
                overflow: hidden;
                cursor: pointer;
                z-index: -1;
              }
              select {
                width: 170px;
                height: 40px;
                background: transparent;
                line-height: 20px;
                border: 0;
                -webkit-appearance: none;
                border-radius: 5px;
                -moz-appearance: none;
                option {
                  padding-left: 20px;
                }
              }
              .select-more {
                position: absolute;
                top: 11px;
                bottom: 0;
                right: 20px;
              }
            }
            .hover-sep{
              width: 148px;
              height: 5px;
              position: absolute;
              .hover-sep { top:41px; }
            }
          }
          select {
            height: 40px;
            line-height: 40px;
            position: absolute;
            z-index: 2;
            cursor: pointer;
            outline: none;
            @extend %opacity-0;
            -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
            filter: alpha(opacity=0);
            -khtml-opacity: 0;
            -moz-opacity: 0;
          }
          .holder {
            display: block;
            margin: 0 35px 0 20px;
            white-space: nowrap;
            overflow: hidden;
            cursor: pointer;
            z-index: -1;
          }
          .sort-cat,
          .sort-prod {
            display: none;
            @include store-tile;
            margin-top: 5px;
            position: relative;
            z-index: 2;
            li { height: 40px; }
            &:after,
            &:before{
              content:"";
              display:block;
              position:absolute;
              border-style:solid;
            }
            &:before {
              border-color:transparent transparent $darker-border-color transparent;
              right:14px;
              top:-22px;
              border-width:11px;
            }
            &:after {
              border-color:transparent transparent $white-color transparent;
              right:15px;
              top:-20px;
              border-width:10px;
              width:0;
              z-index:0;
            }
            .sort-by {
              display: block;
              li {
                line-height: 40px;
                font-size: 14px;
                left: 20px;
                position: relative;
                -webkit-transition: left .20s ease-out;
                -moz-transition: left .20s ease-out;
                -ms-transition: left .20s ease-out;
                -o-transition: left .20s ease-out;
                transition: left .20s ease-out;
                &:hover { left: 30px; }
              }
              &:not(:last-child) { @include border-bottom; }
              &:hover,
              &:focus {
                li {
                  a { color: $gray-dark-3-color; }
                }
              }
              .fa-check {
                color: $white-color;
                margin-top: -30px;
                right: 17px;
              }
              &.selected {
                .fa-check { color: $prim-text-color; }
              }
            }
          }
        }
      }
      .filters-blk {
        float: right;
        line-height: 14px;
        text-align: center;
        position: relative;
        .filter {
          float: left;
          padding-top: 12px;
          padding-bottom: 12px;
          @include border;
          margin-top: 10px;
        }
      }
      .alpha-filter-blk {
        float: left;
        font-size: 14px;
        line-height: 14px;
        padding: 13px 30px;
        @include border-bottom;
        .alpha_filter {
          cursor: pointer;
          color: $brand-01;
          &.inactive {
            color: $hint-color;
            pointer-events: none;
            cursor: text;
          }
          &.active {
            color: $prim-text-color;
            cursor: text;
          }
        }
      }
    }
    .all-stores {
      .expander-parent{padding-bottom:0 !important;}
    }
    .store {

    }
    .store-list-container {

    }
    .store-sort {
      li {
        &.store {
          padding: 20px 30px;
          &:not(:last-child) { @include border-bottom; }
          .m-store-row-s {
            &.one-line {
              line-height: 20px;
              .store-name,
              .coupon-count,
              .store-rebate { margin-top: 10px; }
            }
            &.two-lines{
              line-height:20px;
              .store-name,
              .coupon-count,
              .store-rebate{ margin-top: 15px; }
            }
            .name-link {
              .store-name {
                font-size: 16px;
                line-height: 20px;
                color: $prim-text-color;
                width: 200px;
                margin-right: 30px;
              }
              .coupon-count{
                font-size: 14px;
                line-height: 20px;
                color: $tert-text-color;
                width: 148px;
                margin-right: 30px;
              }
              .store-rebate {
                font-size: 16px;
                line-height: 20px;
                width: 215px;
                margin-right: 30px;
                .was_rebate,
                .wasStr {
                  font-size: 12px;
                  line-height: 20px;
                  color: $tert-text-color;
                  padding-left: 5px;
                  float: right;
                }
              }
            }
            .store-shop {
              width: 100px;
              .button { width: 110px; }
            }
          }
        }
      }
    }
    .filter-container { // used in Military Discounts page
      input[type="text"] {
        &.store-filter {
          border: none; // overwrite default border for text fields
        }
      }

    }
  }
}

.container-fixed.cat-page{ width: $max-width; }
.cat-page .store-page,
.cat-page .store-page .container-fixed{width:100%}
.cat-page .feature-stores .col6{width:15.81%}
.cat-page .blk-group .blk1{width:826px;margin-right:0}

.search-filter {
  @include store-tile;
  h2 {
    @extend .prox-r;
    line-height:28px;
    padding:0;
    font-size:28px;
    margin-bottom: 5px;
  }
  .filter-nav {
    li {
      &:hover {
        color: $sec-text-color;
        span { color: $sec-text-color; }
      }
      a {
        color: $prim-text-color;
        &:hover { color: $sec-text-color; }
      }
      .disable {
        span { @extend .prox-b; }
      }
    }
    .custom-price {
      .price-range-box { @include background( $white-color ); }
    }
  }
  .products {
    .fq-filter {
      .filter {
        width: auto;
      }
    }
  }
}

#sort-merchants:hover .sort-cat{display:block;}

.all-stores {
  .store {
    .store-shop {
      .minw-116 {
        min-width: 116px;
      }
      .button {
        &.reg {
          width: 106px;
        }
      }
    }
  }
  .loader {top:auto;left:auto;}
}

.cat-page {
  .recommended{
    height: 71px;
    .recommended-lr {
      width: 241px;
      .recommended-gry {
        margin-top: 23px;
        height: 26px;
        line-height: 26px;
        width: 241px;
        display: block;
        color:#545454;
      }
    }
    .recommended-mid{ width: 475px; }
    .recommended-store-feat {
      border: 2px solid $brand-01;
      border-top: 6px solid $brand-01;
      width: 471px;
      height: 63px;
    }
    .recommended-store-feat-logo { text-align: center; }
    .promo-tile {
      .was_rebate{ padding-top: 5px; }
    }
  }
  .travel-widget-module {
    @include store-tile;
    margin-bottom: 20px;
  }
  .travel-page {
    .travel-heading {
      text-align: center;
      padding-top: 60px;
      padding-bottom: 60px;
      h1 { font-size: 48px; }
    }
    .tt-premium-banner {
      @include store-tile;
      margin-bottom: 20px;
      overflow: hidden;
    }
    .tt-offer { // Travel-category offer block
      height: 358px;
      @include store-tile;
      overflow: hidden;
      .small-banner {
        display: inline-block;
        margin-bottom: 20px;
        img {
          @include round(8px 8px 0 0);
          width: 100%;
          max-width: 225px;
          height: 100%;
          max-height: 150px;
        }
      }
      .logos {
        display: inline-block;
        margin-bottom: 15px;
        padding-right: 20px;
        padding-left: 20px;
      }
      .premium-desc {
        color: $prim-text-color;
        @include rakutenSansUIsemiBold;
        display: block;
        font-size: 16px;
      }
      .cb {
        display: block;
        font-size: 16px;
        padding-top: 5px;
        padding-bottom: 15px;
      }
      .premium-expire {
        display: block;
        font-size: 12px;
        color: $sec-text-color;
      }
    }
  }
}

.promo-list h2.f-grn{color:$brand-01;}
.link-block .fa-caret-right{vertical-align:-2px;}
/* --- All Stores Page -- */
.back-top{
  position:fixed;bottom:70px;right:50%;margin-right:-610px;z-index:9999;width:300px;transition:opacity 0.2s ease-out;
  @extend %opacity-0;
}
.back-top.show{ @extend .op10; }
.up-top span{width:20px;height:20px;}

.back-top-message{position:absolute;top:-345px;width:300px;height:330px;}
.back-top-message.arrow-box{border:1px solid #d7d7d7;}
.back-top-message.arrow-box:after,.back-top-message.arrow-box:before{top:100%;right:30px;border:solid transparent;content:" ";height:0;width:0;position:absolute;pointer-events:none;}
.back-top-message.arrow-box:after{border-color:rgba(255, 255, 255, 0);border-top-color:$white-color;border-width:9px;margin-right:-12px;}
.back-top-message.arrow-box:before{border-color:rgba(215, 215, 215, 0);border-top-color:#d7d7d7;border-width:10px;margin-right:-13px;}
.icon-shipping{background-position:-119px -88px;height:24px;width:30px;margin-right:15px;}
.icon-plus-grn{background-position:-121px -115px;height:15px;width:15px;}
.icon-srch-sm{background-position:-105px -115px;height:15px;width:15px;}
.icon-clock-gry{background-position:-152px -55px;height:15px;width:15px;}
.icon-clock-red{background-position:-152px -71px;height:15px;width:15px;}

.store-page,
.eb-merchant-page {
  // Store Hero <<<<<
  @import "../components/store_pages/stores-hero";
  // Store Hero <<<<<

  // Store Left Column (Aside) <<<<<
  @import "../components/store_pages/stores-left-column";
  // Store Left Column (Aside) <<<<<

  // Store Right Column (Main) <<<<<
  @import "../components/store_pages/stores-right-column";
  // Store Right Column (Main) <<<<<
}

h2.section-heading,
.section-heading{ // Section Heading used to separate modules in Home Page
  .fa-store.iblk { // ISCB HOUSE ICON HIDING
    display: none;
  }
}

.new-ui { //R4 rebrand
  .subcat+p { color: $sec-text-color; }
  a {
    &.nohover {
      &:hover { text-decoration: none; }
    }
  }
  .eb-form-layout {  //Sign up overlay
    .gc-ovr-head{
      .svg-eb-logo3 {
        background-image: url("//static.ebates.com/static/fonts/svg/ebates_w_horiz.svg");
        margin-top: 5px;
      }
    }
  }
  a.f-gry-dk {
    @extend .f-gry-dk;
  }

  #in-store-offers {
    h3 {
      &.f-grn {
        color: $prim-text-color;
      }
    }
    .border-grn-t-2 {
      @extend .border-t;
    }
    .width-bonus {
      span {
        font-size: 18px;
        line-height: 22px;
        color: $sec-text-color;
      }
    }
  }
  //Best buy
  #qr_code_bb {
    color: $prim-text-color;
    background-color: $white-color;
    border: 1px solid $prim-text-color;
    &:hover {
      background-color: $prim-text-color;
      color: $white-color;
    }
  }
  .qr-code {
    .show-join-overlay {
      @extend #qr_code_bb;
    }
  }
  #bestbusycategory {
    .border-grn-t-2 {
      @extend .border-t;
    }
    div.f-14 {
      color: $sec-text-color;
    }
  }
  .eb-modal {
    &.border-grn-t-2 {
      border-top-style: none;
    }
  }
  #qr_module_bb {
    .print_module {
      background-color: $cb-color;
      font-size: 16px;
      &:hover {
        background-color: $cb-color-hover;
      }
    }
  }

  //Tier stores Amazon /  Jet / Walmart
  #merchant-category-module {
    ul+h2 {
      font-size: 28px;
      line-height: 32px;
    }
    h2 {
      a { color: $prim-text-color; }
    }
    a {
      &.button.lg {
        font-size: 16px;
        min-width: unset;
      }
    }
    .subcat { color: $sec-text-color; }
  }

  .amz-wide {
    .divider-10 {
      height: 10px;
      background-color: #F2F2F2;
      display: inline-block;
      border-style:hidden;
      float: left;
      width: 100%;
      &+div+div {
        p {
          padding-left: 10px;
        }
      }
    }
  }

  //Groupon page style
  .gpDeals {
    .col4 {
      &.col-last {
        margin-right: 0px;
      }
    }
  }
  .acct-loading {
    display: none !important; //Hide loading for r4, to-do: clean html and css once decided to remove loading sign
  }
  .gp-load-more {
    .button { font-size: 18px; }
  }
  #change_city_tabs {
    .nav-tabs, .tabnav {border-bottom:none;}
    .s-box {
      border-top: 1px solid $border-color;
      #g-s-box {
        border-color: $darker-border-color;
        input[type="text"] {
          border: none;
        }
      }
    }
  }
  .gp-cta {
    a.prox-b {
      @extend .prox-r;
      .arrow-sm {
        display: none;
      }
    }
  }

  .store-cb-cats {
    @extend .border;
    h2 {
      @extend .border-0;
      @extend .pad-0;
      &+div{
        @extend .border-0;
        @extend .pad-0;
      }
    }
  }
  #subscribeMail {
    &.button {
      &:hover {
        color: $white-color;
        background-color: $prim-text-color;
      }
      color: $prim-text-color;
      background-color: $white-color;
      border: 1px solid $prim-text-color;
    }
  }
  .store-cb-cats-right {
    @extend .pad-30-lr;
    a {
      &.white-font {
        color: $white-color;
        font-size: 16px;
      }
      &.button-h {
        border-radius: 23px;
        display: inline-block;
        height: 46px;
        border: 1px solid $white-color;
        @extend .pad-20-lr;
      }
    }
    .button-target {
      padding: 0;
      border:none;
      line-height: 46px;
    }
  }
  //Special bonus
  .return-title {
    .button {
      &.white-cta {
        @extend .pad-25-lr;
        right: 50px;
        top:30px;
        &:hover { border: 1px solid $prim-text-color; }
      }
    }
  }
}

// Button Landing Page
.button-section {
  .btn-top {
    .button-apply {
      width: 555px;
      padding:85px 0 50px;
      #avail-cont {
        &.ratings {
          text-align: left;
        }
      }
    }
    .video-bg{
      width: 600px;
      height: 398px;
    }
  }
  .ratings {
    .fa-star { color: $cb-color; }
  }
}

// Boosted Cash Back
.boosted-carousel {
  @include store-tile;
  position: relative;
  .prod-cat-caurosel-block,
  .caurosel-block {
    overflow: hidden;
    position: relative;
    height: 323px;
    .prod-deal-block,
    .prod-cat-block,
    .hpprod-cat-block,
    .caurosel-deal-block {
      padding-bottom: 20px;
      overflow-y: hidden;
    }
    .scroll-group {
      @include border-box;
      // background: $white-color;
      padding-right: 20px;
      height: 323px;
      .prime-grid,
      .boosted-grid,
      .scroll-group-block {
        width: 226px;
        text-align: center;
        &.empty-blk { margin-right: 20px; }
        &:nth-child(1) { margin-left: 20px; }
        .scroll-group-block-link {
          height: 323px;
          cursor: pointer;
          padding-top: 30px;
          padding-bottom: 30px;
          &:hover { text-decoration: none; }
          .title-block {
            @include rakutenSansUIsemiBold;
            display: block;
            font-size: 16px;
            line-height: 20px;
            height: 20px;
            color: $prim-text-color;
            &.top {
              margin-bottom: 20px;
              &.catname { margin-bottom: 15px; }
            }
            &.bottom { margin-bottom: 5px; }
          }
          .cat-image-blk {
            display: block;
            height: 166px;
            width: 166px;
            margin: 0 auto 20px auto;
            .cat-image {
              display: block;
              height: 166px;
              width: 166px;
              background-position: center top;
              border-radius: 50%;
              -webkit-background-size: auto 100%;
              background-size: auto 100%;
              &.round-corner {border-radius: 3px;}
              &.square {border-radius: 0;}
              &.top { margin-top: 20px; }
            }
          }
          .cb {
            padding-right: 20px;
            padding-left: 20px;
            margin-bottom: 5px;
          }
          .was_rebate {
            display: block;
            font-size: 12px;
            line-height: 12px;
            height: 12px;
            color: $gray-dark-8-color;
          }
        }
        .exclusion-modal { display: none; }
      }
      .col {
        margin-right: 0;
        &:first-of-type {
          width: 226px;
          height: 305px;
          margin-right: -1px;
        }
      }
    }
    &.tiered-stores-caurosel-block {
      .scroll-group {
        .scroll-group-block {
          .cat-image {
            &.top { margin-top: 30px; }
          }
        }
      }
    }
  }
  .prod-img-block {
    height: 323px;
    width: 226px;
    left: 0;
    position: absolute;
    img {
      height: 323px;
      width: 226px;
      @include round(8px 0 0 8px);
    }
  }
  .ht-semi-circle-right { left: 226px; }
}

.flex-carousel {
  &.scroll-module {
    overflow: hidden;
  }
  .momentum-scroll {
    white-space: nowrap;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    -webkit-scrollbar: none;
    &::-webkit-scrollbar { display: none; }
    div {
      // width: 300px;
      flex-shrink: 0;
    }
    &.scroll-carousel {
      left: 0;
      width: 100%;
    }
  }
}

.specials-carousel {
  .momentum-scroll {
    &.scroll-carousel {
      // height: 156px;
      height: 172px;
      .specials-store-blk {
        width: 185px;
        &:not(:last-child) {
          margin-right: 10px;
        }
      }
    }
  }
}

.breadcrumbs-module {
  padding-bottom: 10px;
}
ul.breadcrumbs {
  padding-left: 0;
  li {
    display: inline-block;
    &.hide { display: none; }
    p {
      font-size: 12px;
      line-height: 16px;
      margin-bottom: 10px;
      color: $prim-text-color;
      a {
        color: $prim-text-color;
        &:hover,
        &:active { color: $prim-text-color; }
        text-decoration: none;
      }
      .fa-angle-right { margin: 0 10px; }
    }
  }
}

.lp-categories {
  .lp-categories-heading { // Headings for Store Landing Pages with Categories like Amazon and eBay
    h2 {
      @include store-tile;
      padding: 20px 30px;
      margin-bottom: 10px;
    }
  }
  .lp-category-blk {
    @include store-tile;
    margin-bottom: 10px;
    .cat-img {
      float: left;
      height: 200px;
      width: 250px;
      img { @include round(8px 0 0 8px); }
    }
    .cat-info {
      float: right;
      width:570px;
      padding: 30px;
      @include border-box;
      .shop-ctas {
        .left-blk {
          float: left;
          h3 {
            font-size: 20px;
            line-height: 24px;
            margin-bottom: 5px;
            a {
              @include rakutenSansUIsemiBold;
              color: $prim-text-color;
              &:hover { text-decoration: none; }
            }
          }
          a {
            font-size: 16px;
            line-height: 20px;
          }
        }
        .right-blk {
          float: right;
        }
      }
      .exclusions {
        position: relative;
        margin-top: 20px;
        .subcat {
          font-size: 16px;
          line-height: 20px;
          margin-bottom: 10px;
          .exclusion {
            color: $sec-text-color;
            font-size: 14px;
            line-height: 18px;
          }
        }
      }
    }
  }
}
.no-abp {
  .cb {
    font-size: 24px;
    line-height: 28px;
  }
  .box-adblk{box-shadow: 0 3px 6px 0 rgba(0,0,0,0.23)}
}
.no-cb-store {
  margin-bottom: 20px;
  .store-info{
    @include store-tile;
    padding: 20px;
    margin-bottom: 10px;
  }
  img{ @include round(8px); }
}
.no-cb-store-banner {
  margin-bottom: 10px;
  .no-cb-store-image{
    @include store-tile;
    margin-top: 10px;
  }
}
.browser-no-cb { // For browsers that don't support support cash back on devices Shop in App
  &.itp-slp {
    @include store-tile;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 76px;
    padding: 20px 40px;
    .fa-chrome {
      display: block;
      float: left;
      margin-top: 14px;
      margin-bottom: 14px;
      line-height: 18px;
    }
  }
}
.d404 { // This is from the 404 page Top Banner
  @include store-tile;
  background: linear-gradient(130.53deg, #311293 0%, #8529cd 100%);
  background-position: center center;
  background-repeat: no-repeat;
  background-size:cover;
  display: block;
  height: 200px;
  img { @include round(8px); }
  .blk-404-info {
    position: relative;
    margin-left: 30px;
    color: $white-color;
    h1 {
      @include stag(Stag Medium Web, 500);
      font-size: 36px;
      line-height: 40px;
      margin-bottom: 5px;
    }
    p {
      font-size: 16px;
      line-height: 22px;
    }
    .ebatesHome{
      .button {
        margin-top: 25px;
        padding: 0 25px
      }
    }
  }
}
