.cursor,.pointer{cursor:pointer;}
.cursor-def{cursor:default;}
.button {
  @include button;
  @include phone-lg-pano { min-width: 109px; } // check mixins for Media Queries mixins.
  &.primary {
    padding: 0 40px;
    color: $white-color;
    @include background($brand-01);
    @include box-shadow(0 4px 8px 0 rgba(10,22,70,0.15));
    &:hover {
      @include background($brand-01-hover);
    }
    &:active {
      @include background($brand-01-active);
    }
    &:focus {
      // border: 3px double $brand-01;
      &::after { }
    }
    &.alt { // Doesn't exist in the New design so will mimic Primary
      color: $white-color;
      @include background($brand-01);
      &:hover {
        @include background($brand-01-hover);
      }
    }
    &.ghost,
    &.white {
      color: $brand-01;
      @include background($white-color);
      span { color: $brand-01; }
      &:hover {
        color: $brand-01-hover;
        @include background($white-color);
        span { color: $brand-01-hover; }
      }
      &:active {
        color: $brand-01;
        @include background($white-color);
        span { color: $brand-01; }
      }
    }
    &.hollow-white {
      color: $white-color;
      @include border( $border-color: $white-color );
      background: transparent;
      &:hover {
        color: black;
        background: rgb(255, 255, 255);
        mix-blend-mode: screen;
        // This does not work for IE so the text is black instead of transparent
      }
    }
    &.hollow-dark {
      color: $prim-text-color;
      @include border( $border-color: $prim-text-color );
      background: transparent;
      &:hover {
        color: black;
        @include border( $border-color: $white-color );
        background: rgb(255, 255, 255);
        mix-blend-mode: screen;
        // This does not work for IE so the text is black instead of transparent
      }
    }
  }
  &.secondary {
    padding: 0 25px;
    color: $white-color;
    @include background($brand-01);
    @include box-shadow(0 4px 8px 0 rgba(10,22,70,0.15));
    &:hover {
      @include background($brand-01-hover);
    }
    &:active {
      @include background($brand-01-active);
    }
    &.alt { // Doesn't exist in the New design so will mimic Secondary
      color: $white-color;
      @include background($brand-01);
      &:hover {
        @include background($brand-01-hover);
      }
    }
    &.ghost {
      color: $brand-01;
      @include background($white-color);
      span { color: $brand-01; }
      &:hover {
        color: $brand-01-hover;
        @include background($white-color);
        span { color: $brand-01-hover; }
      }
      &:active {
        color: $brand-01;
        @include background($white-color);
        span { color: $brand-01; }
      }
      &.selected {
        @extend .button.secondary.alt;
        &:hover { color: $white-color; }
      }
    }
    &.social {
      color: $prim-text-color;
      @include background($white-color);
      span { color: $prim-text-color; }
      &:hover {
        color: $gray-dark-8-color;
        span { color: $gray-dark-8-color; }
      }
      span {
        line-height: 46px;
        float: none;
        left: auto;
        color: $gray-dark-8-color;
        &.fa-google {
          font-size: 18px;
          margin-right: 15px;
          margin-bottom: -3px;
        }
      }
      &.fb {
        color: $white-color;
        @include background($social-facebook-color);
        span {
          color: $white-color;
          &.fa-facebook {
            font-size: 18px;
            margin-right: 15px;
          }
        }
      }
      &.apple {
        color: $white-color;
        @include background($prim-text-color);
        span {
          color: $white-color;
          &.fa-facebook {
            font-size: 18px;
            margin-right: 15px;
          }
        }
      }
      &.twitter {
        color: $white-color;
        @include background($social-twitter-color);
        span {
          color: $white-color;
          &.fa-twitter {
            font-size: 18px;
            margin-right: 15px;
          }
        }
        &:hover { @include background($social-twitter-color-hover); }
      }
    }
  }
  &.tert {
    font-size: 14px;
    @include height(34px);
    @include line-height(34px);
    padding: 0 20px;
    color: $white-color;
    @include background($brand-01);
    @include box-shadow(0 4px 8px 0 rgba(10,22,70,0.15));
    &:hover {
      @include background($brand-01-hover);
    }
    &:active {
      @include background($brand-01-active);
    }
    &.alt { // Doesn't exist in the New design so will mimic Tertiary
      color: $white-color;
      @include background($brand-01);
      &:hover {
        @include background($brand-01-hover);
      }
    }
    &.ghost {
      min-width: 70px;
      color: $brand-01;
      @include background($white-color);
      span { color: $brand-01; }
      &:hover {
        color: $brand-01-hover;
        @include background($white-color);
        span { color: $brand-01-hover; }
      }
      &:active {
        color: $brand-01;
        @include background($white-color);
        span { color: $brand-01; }
      }
    }
    &.social {
      color: $prim-text-color;
      @include background($white-color);
      span { color: $prim-text-color; }
      font-size: 14px;
      &:hover {
        color: $gray-dark-8-color;
        span { color: $gray-dark-8-color; }
      }
      span {
        @include line-height(34px);
        float: none;
        left: auto;
        &.fa-google {
          font-size: 14px;
          width: 14px;
          margin-right: 5px;
          margin-bottom: -5px;
        }
      }
      &.fb {
        color: $white-color;
        @include background($social-facebook-color);
        span {
          color: $white-color;
          &.fa-facebook {
            font-size: 14px;
            margin-right: 15px;
          }
        }
      }
      &.apple {
        color: $white-color;
        @include background($prim-text-color);
        span {
          color: $white-color;
          &.fa-apple {
            font-size: 14px;
            margin-right: 15px;
          }
        }
      }
      &.twitter {
        color: $white-color;
        @include background($social-twitter-color);
        span {
          color: $white-color;
          &.fa-twitter {
            font-size: 14px;
            margin-right: 15px;
          }
        }
        &:hover { @include background($social-twitter-color-hover); }
      }
      &.hdr {
        min-width: 95px;
        @include round(20px);
        @include height(30px);
        @include line-height(30px);
        span {
          @include line-height(30px);
          float: none;
          left: auto;
          &.fa-google {
            margin-right: 0;
            margin-bottom: -5px;
          }
          &.fa-apple,
          &.fa-facebook,
          &.fa-twitter {
            margin-right: 0;
          }
        }
      }
    }
  }
  &.display {
    padding: 0 60px;
    color: $white-color;
    @include background($brand-01);
    @include box-shadow(0 4px 8px 0 rgba(10,22,70,0.15));
    &:hover {
      @include background($brand-01-hover);
    }
    &:active {
      @include background($brand-01-active);
    }
    &.alt { // Doesn't exist in the New design so will mimic Display
      color: $white-color;
      @include background($brand-01);
      &:hover {
        @include background($brand-01-hover);
      }
    }
    &.ghost {
      color: $brand-01;
      @include background($white-color);
      span { color: $brand-01; }
      &:hover {
        color: $brand-01-hover;
        @include background($white-color);
        span { color: $brand-01-hover; }
      }
      &:active {
        color: $brand-01;
        @include background($white-color);
        span { color: $brand-01; }
      }
    }
    &.primary-alt {
      // Used in Home Page Inline Signup page-level-join section
      color: $white-color;
      @include background($cb-color);
      @include border( $border-color: $white-color );
      &:hover {
        color: $cb-color;
        @include background($white-color);
        @include border( $border-color: $white-color );
      }
    }
    &.primary-white { // USE: @extend .button.display.primary-white;
      color: $brand-01;
      @include background($white-color);
      @include border( $border-color: $white-color );
    }
    &.primary-white-alt { // USE: @extend .button.display.primary-white;
      color: $brand-01;
      @include background($white-color);
      @include border( $border-color: $white-color );
    }
    &.hollow-white { // USE: @extend .button.display.hollow-white;
      color: $white-color;
      @include border( $border-color: $white-color );
      background: transparent;
      &:hover {
        color: black;
        background: rgb(255, 255, 255);
        mix-blend-mode: screen;
        // This does not work for IE so the text is black instead of transparent
      }
    }
    &.hollow-dark { // USE: @extend .button.display.hollow-white;
      color: $prim-text-color;
      @include border( $border-color: $prim-text-color );
      background: transparent;
      &:hover {
        color: black;
        @include border( $border-color: $white-color );
        background: rgb(255, 255, 255);
        mix-blend-mode: screen;
        // This does not work for IE so the text is black instead of transparent
      }
    }
  }
  &.circle { // USE: @extend .button.circle;
    padding: 0;
    color: $white-color;
    min-width: 20px;
    min-height: 20px;
    height: auto;
    font-size: 14px;
    line-height: 20px;
    @include round(50%);
    @include background($cb-color);
    &:hover {
      @include background($cb-color-hover);
    }
    &.square-28 {
      @include square(28px);
      @include line-height(28px);

    }
    &.alt { // USE: @extend .button.circle.alt;
      color: $white-color;
      @include background($prim-text-color);
      &:hover {
        @include background($prim-text-color-hover);
      }
    }
    &.ghost { // USE: @extend .button.circle.ghost;
      color: $prim-text-color;
      @include background($white-color);
      span { color: $prim-text-color; }
      @include border( $border-color: $prim-text-color );
      &:hover {
        color: $brand-01-hover;
        @include background($white-color);
        span { color: $brand-01-hover; }
      }
      &:active {
        color: $brand-01;
        @include background($white-color);
        span { color: $brand-01; }
      }
    }
    &.ghost-gry { // USE: @extend .button.circle.gray;
      color: $gray-dark-8-color;
      @include background($white-color);
      span { color: $prim-text-color; }
      @include border( $border-color: $gray-dark-8-color );
      &:hover {
        color: $white-color;
        @include background($gray-dark-8-color );
        span { color: $white-color; }
      }
      &.disabled {
        &:hover {
          color: $gray-dark-8-color;
          @include background($white-color);
          span { color: $gray-dark-8-color; }
          cursor: initial;
        }
      }
    }
  }
}

// Toggle Switch (replaces fa-toggle-on and fa-toggle-off)
// Hide default HTML checkbox
.toggle-switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 22px;
  .toggle-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $border-color;
    -webkit-transition: .4s;
    transition: .4s;
    &:before {
      position: absolute;
      content: "";
      height: 20px;
      width: 20px;
      left: 1px;
      bottom: 1px;
      background-color: white;
      -webkit-transition: .4s;
      transition: .4s;
    }
    &.circle {
      border-radius: 22px;
      &:before {
        border-radius: 50%;
      }
    }
  }
  input {
    opacity: 0;
    width: 0;
    height: 0;
    &:checked {
      + .toggle-slider {
        background-color: $brand-01;
        &:before {
          -webkit-transform: translateX(18px);
          -ms-transform: translateX(18px);
          transform: translateX(18px);
        }
      }
      &.disabled + .toggle-slider { background-color: $border-color; }
    }
    //&:focus + .toggle-slider { box-shadow: 0 0 1px $prim-text-color; }
  }
}
