// To be used for new modules that are Hybrid and shared with Desktop and MW.
// THIS IS BOOTSTRAP-LESS meaning that the MW page that uses this doe not load the Bootstrap CSS.
// However, we may leverage Bootstrap elements like Carousels, so we'll need to leverage and copy those classes here.

.page-info-module { // Used for page modules hat have an SEO Module like H1 or H2 and Expandable Content
  .page-info {
    @include store-tile;
    padding: 0 30px 30px 30px;
    .expander-parent {
      .expand-me {
        color: $sec-text-color;
      }
    }
  }
}
// Store Stores Categories block general styles) >>>
.cb-categories {
  @include store-tile;
  ul {
    display: inline-block;
    width: 100%;
    @include border-box;
    li {
      display: inline-block;
      @include border-box;
      font-size: 16px;
      line-height: 20px;
      a {
        font-size: 16px;
        line-height: 20px;
      }
      .cb-cats-list-title {
        float: left;
        text-align: left;
        width: 80%;
        @include rakutenSansUIsemiBold;
      }
      .cb-cats-list-amt {
        float: right;
        text-align: right;
        width: 20%;
      }
    }
  }
}
.page-info-blk {
  @include store-tile;
  padding: 20px 30px;
  margin-bottom: 10px;
  h1, h2 {
    @include stag(Stag Medium Web, 500);
    font-size: 28px;
    line-height: 36px;
    padding-bottom: 10px;
  }
}

.aside {
  // BR-2340 Fix  for some Store Pages that are Tiered but show CB Categories on the Left Side (aside) instead of the Right Side (main) by also including  .cb-categories
  .aside-blk,
  .cb-categories  {
    @include store-tile;
    display: block;
    width: 100%;
    float: left;
    padding: 30px;
    color: $prim-text-color;
    &:not(:last-child) { margin-bottom: 10px; }
    h2 {
      &:not(.section-heading) {
        @include stag(Stag Medium Web, 500);
        color: $prim-text-color;
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 10px;
        letter-spacing: normal;
        a {
          &:not(.module-heading-link) {
            @include rakutenSansUIsemiBold;
            color: $prim-text-color;
          }
        }
        &:hover {
          text-decoration: none;

          a {
            text-decoration: none;
          }
        }
      }
    }
    h3 {
      &.module-heading {
        @include stag(Stag Medium Web, 500);
        font-size: 22px;
        line-height: 32px;
        margin-bottom: 10px;
      }
    }
    p {
      color: $prim-text-color;
      font-size: 14px;
      line-height: 20px;
      &:not(:last-child) { margin-bottom: 10px; }
      &.mar-20-b { margin-bottom: 20px; }
      a {
        &.cb { &:hover { text-decoration: none; } }
      }
    }
    ul {
      li {
        a {
          .see-more-link {
            padding-left: 5px;
            color: $brand-01;
            @include rakutenSansUIsemiBold;
            .fa-angle-right {
              color: $brand-01;
            }
          }
          &:hover { text-decoration: none; }
        }
      }
    }
  }
  .store-recommendations{
    width: 826px;
    .blk-coupon {
      width: 826px;
      ul { width: 450px; }
    }
  }
  .right-advt,
  .gpt-ad-slot {
    @include store-tile;
    margin-bottom: 10px;
    padding: 15px;
    overflow: auto;
    .img-blk {
      display: block;
      width: 300px;
      margin: 0 auto;
      img {
        @include rectangle(300px, 250px);
      }
    }
    .info-blk {
      display: block;
      margin-top: 15px;
      .info-blk-img {
        @include border-bottom;
        padding-bottom: 15px;
        margin-bottom: 15px;
      }
    }
    .info-txt-blk {
      padding: 15px 15px 10px 15px;
      font-size: 16px;
      line-height: 20px;
      .info-link {
        color: $prim-text-color;
        &:hover { text-decoration: none; }
        .cb { padding-left: 5px; }
      }
    }
  }
  .related { // replaced by aside-nav
    &.related-pages {
      @include store-tile;
      margin-bottom: 10px;
    }
    .related-list {
      a {
        .read-link { color: $tert-text-color; }
      }
    }
  }
  .aside-nav {
    @include store-tile;
    margin-bottom: 10px;
    padding: 40px;
    .module-heading {
      @include stag(Stag Medium Web, 500);
      font-size: 28px;
      line-height: 36px;
      margin-bottom: 5px;
    }
    .aside-list {
      .aside-list-item {
        font-size: 16px;
        line-height: 20px;
        padding-top: 15px;
        padding-bottom: 15px;
        &:not(:last-child) { @include border-bottom; }
        .aside-list-link {
          color: $tert-text-color;
          font-size: 16px;
          line-height: 20px;
          &:hover { color: $sec-text-color; }
        }
      }
      .aside-list-item {
        &.top-level {
          &.top-level-sep { margin-top: 20px; }
        }
        &.sub-level {
          padding-left: 20px;
          &:not(:last-child) { padding-bottom: 12px; }
          .aside-list-link {
            font-size: 14px;
            line-height: 18px;
          }
          .current {
            @include rakutenSansUIsemiBold;
            color: $prim-text-color;
            font-size: 14px;
            line-height: 18px;
          }
        }
        .current {
          @include rakutenSansUIsemiBold;
          letter-spacing: 1px;
        }
        .aside-list-link {
          font-size: 16px;
          line-height: 20px;
        }
      }
    }
  }

  // Keep Rating-Content on its own since it's shared as part of Aside in both Home and Stores >>>
  .rating-content {
    .user-ratings {
      padding-bottom: 20px;
      h2 { margin-bottom: 15px;}
      .rating {
        display: inline-block;
        letter-spacing: 1px;
        position: relative;
        float: left;
        font-size: 20px;
        line-height: 24px;
        white-space: nowrap;
        color: $prim-text-color;
        .fa-star {
          line-height: 24px;
          letter-spacing: 1px;
        }
      }
      .rating-amount {
        @include rakutenSansUIsemiBold;
        font-size: 20px;
        line-height: 24px;
        display: inline-block;
        letter-spacing: 1px;
        margin-left: 10px;
        color: $prim-text-color;
      }
      .reviews-total {
        font-size: 14px;
        line-height: 18px;
        margin-top: 5px;
      }
    }
    .rated-by {
      padding-top: 15px;
      @include border-top( $border-width: 1px );
      .rated-name {
        color: $prim-text-color;
        @include rakutenSansUIsemiBold;
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 5px;
      }
      .rating {
        display: inline-block;
        letter-spacing: 1px;
        font-size: 14px;
        line-height: 18px;
        position: relative;
        .no-wrap { white-space: nowrap; }
      }
      .rated-desc {
        font-size: 14px;
        line-height: 18px;
        color: $sec-text-color;
        word-wrap: break-word;
        -ms-word-break: break-all;
        word-break: break-word;
        margin-top: 10px;
      }
      &:not(:last-child) {
        .rated-desc {
          margin-bottom: 20px;
        }
      }
    }
  }
  // Keep Rating-Content on its own since it's shared as part of Aside in both Home and Stores <<<

  // Store Left Side Button Upsell block >>>
  .btn-container {
    @include store-tile;
    display: block;
    padding: 30px;
    margin-bottom: 10px;
    h2 { margin-bottom: 15px; }
    p { margin-bottom: 20px; }
    .button{
      &.get-button { width: 100%; }
    }
    a {
      img {
        padding-top: 30px;
        padding-right: 0;
        padding-left: 0;
        width: 272px;
        height: auto;
      }
    }
  }
  // Store Left Side Button Upsell block <<<

  // Store Left Side Free Shipping block >>>
  .free-shipping {
    .fa-truck { display: none; }
    p {
      &.cb-text {
        a {
          font-size: 16px;
          line-height: 20px;
          @include rakutenSansUIsemiBold;
          color: $prim-text-color;
          text-decoration: none;
        }
      }
    }
  }
  // Store Left Side Free Shipping block <<<
}

.main {
  .main-blk {
    @include store-tile;
    display: block;
    width: 100%;
    float: left;
    &:not(h2, h3) { color: $sec-text-color; }
    &:not(:last-child) { margin-bottom: 10px; }
    // Store Right Side Tiered Stores Categories block >>>
    &.cb-categories {
      border: none;
      ul {
        padding: 30px;
        li {
          display: block;
          width: 50%;
          margin-top: 10px;
          &:nth-child(odd) {
            float: left;
            padding-right: 30px;
          }
          &:nth-child(even) {
            float: right;
            padding-left: 30px;
          }
          &.cb-cats-list-row {
            margin-top: 10px;
            @include rakutenSansUIsemiBold;
            .cb-cats-list-title { width: 75%; }
            .cb-cats-list-amt { width: 25%; }
            &.heading {
              margin-top: 0;
              margin-bottom: 5px;
              @include rakutenSansUIsemiBold;
              .cb-cats-list-title { width: 70%; }
              .cb-cats-list-amt { width: 30%; }
            }
            &:nth-child(odd) { clear: both; }
          }
        }
      }
    }
    // Store Right Side Tiered Stores Categories block <<<

    // Store Right Side Free Shipping block >>>
    &.free-shipping {
      @include border-left( $border-width: 5px, $border-color: $brand-01 );
      padding: 30px;
      h2 {
        border-bottom: none;
        padding: 0 0 15px 0;
        .fa-truck {
          color: $brand-01;
          padding-left: 20px;
        }
      }
      p {
        font-size: 18px;
        &:not(:last-child) {
          margin-bottom: 10px;
        }
        &.cb-text {
          a {
            font-size: 16px;
            line-height: 20px;
            @include rakutenSansUIsemiBold;
            color: $prim-text-color;
            text-decoration: none;
          }
        }
      }
    }
    // Store Right Side Free Shipping block <<<

    // Store Right Side Shopping Secrets block >>>
    &.mkt-ops {
      @include store-tile;
      .shop-secret {
        padding: 30px;
        h3 {
          @include rakutenSansUIsemiBold;
          color: $prim-text-color;
        }
        p {
          &.shop-txt {
            font-size: 16px;
            line-height: 20px;
            @include rakutenSansUIsemiBold;
            a {
              @include rakutenSansUIsemiBold;
              display: block;
              clear: both;
            }
          }
          &:not(:last-child) {
            margin-bottom: 10px;
            display: block;
          }
        }
        .FAQPage {
          h2 {
            color: $prim-text-color;
            @include stag(Stag Medium Web, 500);
            font-size: 22px;
            line-height: 32px;
            margin-top: 20px;
            margin-bottom: 20px;
          }
        }
      }
    }
    // Store Right Side Shopping Secrets block <<<

    &.join-module {
      margin-bottom: 10px;
      @include store-tile;
      img { @include round(8px); }
    }
  }
  .store-recommendations {
    @include store-tile
    .intro-block {
      background-color: $brand-01;
      @include round(8px 8px 0 0 );
      .intro-heading {
        display: block;
        text-align: center;
        padding: 25px;
      }
    }
  }
}

.caurosel-x { // Horizontal Carousel overflow-0 rel
  overflow: hidden;
  position: relative;
}

// Hot Deals & Store Coupons >>>>>
.hot-deals-module {
  @include store-tile;
  margin-bottom: 10px;
}
.hp-hotdeals { margin-bottom: 10px; }
.hp-hotdeals,
.store-coupons {
  &:not(:last-child) { .coupon-blk { @include border-bottom; } }
  &:last-child { .coupon-blk { border-bottom: none; } }
  .coupon-blk {
    // display: block;  // Used if not using Flexbox
    // float: left;  // Used if not using Flexbox
    @include flexbox();
    @include flex-flow (row);
    @include justify-content(flex-start);
    @include align-items(stretch);
    padding: 30px;
    &:not(:last-child) { @include border-bottom; }
    &:last-child { border-bottom: none; }
    .merchlogo {
      // float: left;  // Used if not using Flexbox
      // width: 150px;  // Used if not using Flexbox
      flex-basis: 150px;
      img {
        display: block;
        margin: 0;
      }
    }
    .coupon-info {
      @include border-box;
      //  width:calc( 100% - 278px ); // Used if not using Flexbox
      padding-left: 30px;
      padding-right: 30px;
      float: left;
      .coupon-desc {
        padding-bottom: 0;
        .title-part {
          font-size: 18px;
          line-height: 22px;
          color: $prim-text-color;
        }
        .rebate {
          &.cb {
            font-size: 16px;
            line-height: 22px;
            padding-right: 0;
            white-space: nowrap;
            word-break: keep-all;
          }
        }
      }
      .coupon-restrictions {
        margin-top: 20px;
        line-height: 17px;
        span {
          color: $gray-dark-8-color;
        }
      }
      .coupon-code-blk {
        height: 25px;
        margin-top: 5px;
        padding-top: 10px;
        .coupon-code {
          float: left;
          font-size: 14px;
          position: relative;
          margin-right: 5px;
          color: $gray-dark-8-color;
          .promo-code {
            @include letter-space( 0.5px );
            cursor: pointer;
            color: $white-color;
            @include background($brand-01);
            @include round(5px);
            padding: 5px;
            margin-right: 15px;
          }

          .copy-coupon-code-tooltip {
            @include round(8px);
            padding-top: 10px;
            padding-bottom: 10px;
            @include background($brand-01);
            font-size: 14px;
            line-height: 14px;
            text-align: center;

            .copy {
              color: $white-color;
              width: 85px;
              display: block;
              margin-left: 5px;
            }

            &.expand {
              .copy {
                width: 151px;
                margin-left: 0;
              }
            }
          }
        }

        .premium-expire {
          font-size: 14px;
          line-height: 18px;
          float: left;

          &.expiring {
            color: $cb-color;
          }

          &.expires {
            color: $gray-dark-8-color;
          }

          .fa-clock-o {
            display: block;
            float: left;
            margin-right: 5px;
            padding-top: 1px;

            &.expiring {
              color: $cb-color;
            }

            &.expires {
              color: $gray-dark-8-color;
            }
          }
        }
      }
    }

    &.no-logo {
      .coupon-info {
        // width:calc( 100% - 128px );  // Used if not using Flexbox
        padding-left: 0;
      }

      &.app-only { // App Only store pages
        .coupon-info {
          .coupon-desc {
            margin-bottom: 15px;

            .title-part {
              font-size: 18px;
              line-height: 22px;
            }
          }
        }

        .email-form {
          width: 300px;

          input {
            width: 279px;
          }

          button {
            width: 279px;
          }
        }
      }
    }

    .shopnow {
      // width: 128px;  // Used if not using Flexbox
      // float: right;  // Used if not using Flexbox
      margin-left: auto;

      .button {
        white-space: nowrap;
      }
    }
  }

  .all-deals {
    display: block;
    clear: both;
    padding: 30px;
    text-align: center;
    @include background($white-color);
    .button {
      font-size: 16px;
    }
  }

  &.coupon-results { // SERP Coupons
    margin-bottom: 0;
    .coupon-blk {
      @include border-bottom($border-width: 1px, $border-color: $border-color);
    }
    .all-deals {
      padding: 20px 30px;
      border-radius: 8px;
    }
  }

  &.mwview {
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 10px;
    .coupon-blk {
      @include flex-flow (row);
      @include flex-direction(column);
      @include justify-content(space-between);
      padding: 15px;
      margin-bottom: 10px;
      @include border-top($border-width: 1px);
      .logo-block { margin-bottom: 10px; }
      .merchlogo {
        display: inline-block;
        min-height: 34px;
        //width: 50%;
        flex-basis: 50%;
      }
      .shopnow {
        display: inline-block;
        //width: 50%;
        flex-basis: 50%;
        float: right;
        .button {
          float: right;
          &.hide { display: none; }
          &.hide-code { background-color: $gray-dd-color; }
        }
      }
      .coupon-info {
        padding-left: 0;
        padding-right: 0;
        float: left;
        .coupon-desc {
          @include rakutenSansUIsemiBold;
          font-size: 14px;
          line-height: 18px;
          margin-bottom: 10px;
        }
        .coupon-restrictions {
          margin-top: 0;
          margin-bottom: 10px;
          font-size: 14px;
          line-height: 18px;
          color: $gray-dark-8-color;
          a {
            // f-gry-dk-8 ht-14
            color: $gray-dark-8-color;
            span {
              display: inline-block;

              &.fa-info-circle {
                //  f-gry-dk2 pointer pad-5-b f-14 icon_cc_cb_info
              }
            }
          }
        }
      }

      .expiration {
        .premium-expire {
          font-size: 12px;
          line-height: 18px;
          color: $gray-dark-8-color;
          display: inline-block;
        }

        .rebate {
          &.cb {
            font-size: 14px;
            line-height: 18px;
            float: right;
            text-align: right;
            display: inline-block;
          }
        }
      }

      .coupon-code {
        padding-top: 15px;
        margin-top: 15px;
        @include border-top($border-width: 1px, $border-color: $border-color);

        .promo-code {
          @include letter-space( 0.5px );
          cursor: pointer;
          color: $white-color;
          @include background($brand-01);
          @include round(5px);
          padding: 5px;
          margin-right: 15px;
          float: left;
          display: inline-block;
          margin-top: 4px;
        }

        .button {
          display: inline-block;
          float: right;
        }
      }
    }
  }

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    // IE10+ specific styles go here
    .coupon-blk {
      display: table;
      .merchlogo {
        width: 150px;
        display: table-cell;
        vertical-align: top;
      }

      .coupon-info {
        display: table-cell;
        vertical-align: top;
        width: 485px;
      }

      .shopnow {
        width: 128px;
        display: table-cell;
        vertical-align: top;
      }
    }
  }
}

.store-coupons {
  &.coupon-results {
    @include store-tile;
    margin-bottom: 20px;
    &.coupon-results-full {
      margin-bottom: 0;
      @include border-top($border-width: 0);
    }
    .cat-header {
      .module-heading {
        display: inline-block;
        width: auto;
        border-bottom: none;
      }
      border-bottom: 1px solid $border-color;
      .coupon-count {
        display: inline-block;
        padding-left: 15px;
        font-size: 14px;
        @include border-left($border-width: 1px, $border-color: $border-color);
        color: $prim-text-color;

        a {
          color: $prim-text-color;
        }
      }
    }
    .see-all {
      @include rakutenSansUIsemiBold;
      font-size: 16px;
      line-height: 16px;
      &:hover {
        text-decoration: none;
        color: $brand-01-hover;
      }
      .fa-angle-right {
        color: $brand-01;
        margin-left: 12px;
      }
    }
  }
}

.adscan-blk {
  @include border;
  padding: 30px;
  margin-bottom: 20px;
  background: $white-color;
  .adscan-blk-left {
    width: 540px;
    display: inline-block;
    float: left;
    h2 {
      font-size: 20px;
      line-height: 24px;
    }
    .cb {
      display: block;
      font-size: 16px;
      line-height: 20px;
      margin-top: 5px;
      margin-bottom: 10px;
    }
    .button {
      &.cta_anchor {
        margin-top: 15px;
      }
    }
  }
  .adscan-blk-right {
    display: inline-block;
    float: right;
  }
}

.rectangle-2 { // We should not use this naming convention as rectangle-2 is absolutely meaningless to a module
  @include border-top($border-width: 0);

  .coupon-blk {
    height: 212px;
    margin-bottom: 10px;
    .coupon-info {
      width: 100%;
      padding: 0;

      .logo-block {
        display: block;
        height: 40px;
        margin-bottom: 20px;

        .merchlogo {
          width: 50%;
          float: left;
        }

        .shopnow {
          width: 50%;
          float: right;
          display: block;

          .button {
            float: right;
          }
        }
      }

      .coupon-desc {
        max-width: 515px;

        .title-part {
          font-size: 14px;
        }
      }
    }

    &.col {
      &.clp-coupon-last-block {
        margin-right: 0px;
      }
    }
  }

  /* coupons caurosel */
  .coupons-caurosel {
    height: 220px;

    .coupons-block {
      height: 250px;

      .blk.col {
        @include store-tile;
        width: 579px;
        margin-right: 12px;
        overflow: hidden;
      }
    }
  }
}

// Hot Deals & Store Coupons <<<<<

// CLP Pages >>>>>
.squareStoresWithCashbackCTA {
  background-color: #5b5b5b;

  h2 {
    color: $white-color;
  }
}

/* Features Stores & Coupons Blocks */
.feat-store-blk {
  .promo-block {
    @include store-tile;
    @include border($border-width: 1px);
    margin-bottom: 10px;

    .merchlogo {
      @include background($white-color);

      a {
        display: block;
        padding: 35px 35px 20px 35px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
        @include store-tile;

        &.arrow-bot {
          position: relative;
          background: $white-color;
          border: 1px solid $white-color;
        }

        &.arrow-bot:after, &.arrow-bot:before {
          top: 100%;
          left: 50%;
          border: solid transparent;
          content: " ";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
        }

        &.arrow-bot:after {
          border-color: rgba(0, 0, 0, 0);
          border-top-color: $white-color;
          // border-width: 10px;
          // margin-left: -10px;
          border-width: 11px 15px 0px;
          margin-left: -15px;
        }

        &.arrow-bot:before {
          border-color: rgba(255, 255, 255, 0);
          border-top-color: $border-color;
          // border-width: 11px;
          // margin-left: -11px;
          border-width: 12px 17px 0;
          margin-left: -17px;
        }

        &:hover {
          text-decoration: none;
        }
      }

      text-align: center;
      .img-blk {
        display: block;
        clear: both;
        width: 100px;
        height: 27px;
        margin: 0 auto 10px auto;
      }

      .store-name {
        display: block;
        font-size: 16px;
        line-height: 24px;
        max-height: 48px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .bottom-blk {
      @include background($default-bg-color);
      text-align: center;
      padding: 30px 20px 10px 20px;

      .button {
        font-size: 14px;
        color: $cb-color;

        span {
          color: $cb-color;
        }

        margin-bottom: 10px;
      }

      .prev-rebate {
        display: block;
        height: 14px;
        font-size: 14px;
        line-height: 14px;
      }
    }
  }

  &.feat-coupons-blk {
    .promo-block {
      @include background($white-color);

      a {
        text-decoration: none;
      }

      .merchlogo {
        height: 215px;
        display: flex;
        align-items: center;
        padding: 30px;
        @include border-box;
        background-color: transparent;

        .img-blk {
          display: block;
        }

        .coupon-desc {
          display: block;
          font-size: 16px;
          line-height: 24px;
          max-height: unset;
          overflow: auto;
          text-overflow: unset;
          color: $prim-text-color;
        }
      }

      .bottom-blk {
        display: block;
        height: 80px;
        padding: 25px 20px 20px 20px;
        @include border-box;
        background-color: transparent;

        .curr-rebate {
          font-size: 14px;
          line-height: 14px;
          padding-bottom: 8px;
          display: block;
          clear: both;
        }

        .premium-expire {
          &.expires {
            color: $gray-dark-8-color;
            font-size: 14px;
            line-height: 14px;
          }
        }
      }

      .coupon-code-blk {
        top: 204px;
        width: 100%;
        text-align: center;
        z-index: 9;

        .coupon-code {
          height: 23px;
          cursor: pointer;
          max-width: 187px;
          overflow: hidden;
          display: block;
          margin: 0 auto;

          .code-txt {
            display: inline-block;
            color: $tert-text-color;
            font-size: 12px;
            height: 23px;
            line-height: 23px;
            padding: 0 4px 0 8px;
            @include round(10px 0 0 10px);
          }

          .promo-code {
            display: inline-block;
            color: $default-bg-color;
            @include background($brand-01-hover);
            @include round(5px);
            font-size: 12px;
            height: 23px;
            line-height: 23px;
            padding: 0 4px 0 4px;
            max-width: 130px;

            .promo-code-txt {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }

          .copy-coupon-code-tooltip {
            @include round(8px);
            margin-left: 40px;
            &.tooltip {
              z-index: 999;
              color: $white-color;
              @include background($brand-01);
              padding-top: 10px;
              padding-bottom: 10px;
            }

            &.expand {
              left: 46px;

              .copy {
                display: inline-block;
                width: 151px;
                margin-left: 0;
              }
            }
          }
        }
      }
    }
  }
}

.clp-round-4 .LIST .feat-store-blk.feat-coupons-blk.cols-4 .promo-block .coupon-code-blk .coupon-code .copy-coupon-code-tooltip.expand {
    left: 29px;
}

/* Features Stores Caurosel */
.stores-caurosel-block{
  max-height: 307px;
  .stores-block {
    height: 325px;
    overflow-y: hidden;
  }
}

.squareStoresWithCashbackCTA,
.square-5, .clp-round-4  {
  .stores-caurosel-block{
    .stores-block {
      .blk.col {
        width: 224px;
        overflow: hidden;
        &:not(:last-child) {
          margin-right: 12px;
        }
      }
    }
  }
  .coupons-caurosel {
    height: 242px;
    .coupons-block {
      height: 260px;

      .blk.col {
        width: 224px;
        overflow: hidden;
        &:not(:last-child) {
          margin-right: 12px;
        }
      }
    }
  }
}

.square-5, .clp-round-4 {
  .coupons-caurosel {
    height: 303px;

    .coupons-block {
      height: 320px;

      .promo-block {
        margin-bottom: 0;
      }
    }
  }
}

// CLP Pages <<<<<

// Travel Thursdays >>>
.travel-thursday {
  .travel-thursdays-hero {
    position: relative;
    min-width: 1170px;

    &.campaign-bg {
      height: 280px;
      background-size: cover;

      .bg-copy-img {
        width: 850px;
        display: block;
        margin: 0 auto;
      }
    }

    .travel-thursdays-hero-content {
      color: $white-color;
      text-align: center;

      h1 {
        @include stag(Stag Medium Web, 500);
        font-size: 28px;
        line-height: 36px;
        padding-top: 50px;
        letter-spacing: 0.5px;

        span {
          @include stag(Stag Light Web, 300);
          font-size: 80px;
          line-height: 84px;
          margin-top: 10px;
          display: block;
          clear: both;
        }
      }

      h2 {
        color: $white-color;
        font-size: 28px;
        line-height: 32px;
        padding-top: 20px;
      }
    }
  }
  .travel-thursdays-info {
    @include background($white-color);

    .blk-group {
      &.cols-3 {
        padding-top: 40px;
        padding-bottom: 40px;
        &.icon-set {
          text-align: center;
          .icon {
            width: 60px;
            height: 50px;
            margin: 0 auto;
            padding-bottom: 10px;
            img {
              max-width: 60px;
              max-height: 50px;
              vertical-align: center;
              &.icon-set-icon-1 { padding-top: 10px; }
              &.icon-set-icon-2 { height: 50px; }
              &.icon-set-icon-3 { height: 50px; }
            }
          }
          .refer-info-details {
            h2 {
              font-size: 20px;
              line-height: 24px;
              margin-bottom: 5px;
              @include stag(Stag Medium Web, 500);
              letter-spacing: 0.5px;
            }
            p {
              font-size: 16px;
              line-height: 20px;
              color: $sec-text-color;
            }
          }
        }
      }
    }
  }

  .travel-thursdays-data {
    .tt-slot-1,
    .tt-sneek-peak {
      margin-top: 10px;
      margin-bottom: 20px;
    }

    .tt-slot-2 {
      img {
        max-height: 146px;
      }
    }
  }

  &.mwview { // Mobile Web Styles
    .travel-thursdays-hero {
      @include screen(phone-only) {
        min-width: 320px;
      }

      &.campaign-bg {
        @include screen(phone-only) {
          height: 140px;
          background-position: center;
        }

        .bg-copy-img {
          @include screen(phone-only) {
            width: 100%;
          }
        }
      }

      .travel-thursdays-hero-content {
        padding-right: 25px;
        padding-left: 25px;

        h1 {
          font-size: 14px;
          line-height: 18px;
          padding-top: 20px;

          span {
            font-size: 28px;
            line-height: 32px;
            margin-top: 5px;
            letter-spacing: 0.2px;
          }
        }

        h2 {
          font-size: 16px;
          line-height: 20px;
          padding-top: 5px;
        }
      }
    }

    .travel-thursdays-info {
      .blk-group {
        &.cols-3 {
          padding-top: 30px;
          padding-bottom: 0;

          &.icon-set {
            text-align: center;

            .icon {
              width: 50px;
              height: 40px;
              margin-bottom: 10px;

              img {
                max-width: 50px;
                max-height: 40px;

                &.icon-set-icon-1 {
                  padding-top: 12px;
                }
              }
            }

            .refer-info-details {
              margin-bottom: 30px;

              h2 {
                font-size: 16px;
                line-height: 20px;
                letter-spacing: 0.4px;
              }

              p {
                font-size: 14px;
                line-height: 18px;
              }
            }
          }

          .col {
            @include screen(phone-only) {
              width: 100%;
              margin-right: 0;
              margin-bottom: 0;
            }
          }
        }
      }
    }

    .travel-thursdays-data {
      .section-heading {
        margin-left: 15px;
      }

      .tt-slot-1 {
        .flex-carousel {
          &.scroll-module {
            height: 110px;
          }

          .momentum-scroll {
            > div {
              width: 165px;
              height: 110px;
              flex-shrink: 0;
              margin-right: 10px;

              a {
                display: block;
                max-height: 110px;

                img {
                  height: 110px;
                }
              }

              &:first-child {
                margin-left: 10px;
              }

              &:last-child {
                a {
                  margin-right: 10px;
                }
              }
            }

            &.scroll-carousel {
              overflow-y: hidden;
              height: 120px;
            }
          }
        }
      }

      .tt-slot-2 {
        margin-top: 20px;
        padding-right: 10px;
        padding-left: 10px;

        img {
          max-height: 70px;
        }
      }

      .tt-sneek-peak {
        margin-bottom: 0;

        .flex-carousel {
          &.scroll-module {
            height: 282px;
          }

          .momentum-scroll {
            > div {
              width: 196px;
              height: 282px;
              flex-shrink: 0;
              margin-right: 10px;

              img {
                width: 196px;
                height: 282px;
              }

              &:first-child {
                margin-left: 10px;
              }

              &:last-child {
                img {
                  margin-right: 10px;
                }
              }
            }

            &.scroll-carousel {
              overflow-y: hidden;
              height: 292px;
            }
          }
        }
      }
    }
  }
}
// Travel Thursdays <<<
.sec-promo-list {
  .sec-promo-list-items{
      @include store-tile;
      background-color: $lighter-border-color;
      padding: 20px 20px 10px 20px;
      .sec-promo-list-item{
          overflow: auto;
          @include store-tile;
          padding-top:15px;
          padding-bottom:15px;
          margin-bottom: 10px ;
    }
  }
}
