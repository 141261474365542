.pad-0{padding:0;}
.pad-2{padding:2px;}
.pad-5{padding:5px;}
.pad-10{padding:10px;}
.pad-12{padding:12px;}
.pad-15{padding:15px;}
.pad-18{padding:18px;}
.pad-20{padding:20px;}
.pad-25{padding:25px;}
.pad-30{padding:30px;}
.pad-40{padding:40px;}
.pad-45{padding:45px;}
.pad-50{padding:50px;}

.pad-0-b{padding-bottom:0;}
.pad-5-b{padding-bottom:5px;}
.pad-8-b{padding-bottom:8px;}
.pad-10-b{padding-bottom:10px;}
.pad-12-b{padding-bottom:12px;}
.pad-13-b{padding-bottom:13px;}
.pad-14-b{padding-bottom:14px;}
.pad-15-b{padding-bottom:15px;}
.pad-17-b{padding-bottom:17px;}
.pad-18-b{padding-bottom:18px;}
.pad-20-b{padding-bottom:20px;}
.pad-25-b{padding-bottom:25px;}
.pad-30-b{padding-bottom:30px;}
.pad-35-b{padding-bottom:35px;}
.pad-40-b{padding-bottom:40px;}
.pad-45-b{padding-bottom:45px;}
.pad-50-b{padding-bottom:50px;}
.pad-60-b{padding-bottom:60px;}
.pad-70-b{padding-bottom:70px;}
.pad-80-b{padding-bottom:80px;}
.pad-140-b{padding-bottom:140px;}
.pad-150-b{padding-bottom:150px;}

.pad-0-l{padding-left:0;}
.pad-2-l{padding-left:2px;}
.pad-4-l{padding-left:4px;}
.pad-5-l{padding-left:5px;}
.pad-7-l{padding-left:7px;}
.pad-10-l{padding-left:10px;}
.pad-15-l{padding-left:15px;}
.pad-18-l{padding-left:18px;}
.pad-20-l{padding-left:20px;}
.pad-21-l{padding-left:21px;}
.pad-25-l{padding-left:25px;}
.pad-30-l{padding-left:30px;}
.mar-35-l{margin-left:35px;}
.pad-40-l{padding-left:40px;}
.pad-50-l{padding-left:50px;}
.pad-55-l{padding-left:55px;}
.pad-60-l{padding-left:60px;}
.pad-65-l{padding-left:65px;}

.pad-0-lr{padding-right:0;padding-left:0;}
.pad-5-lr{padding-right:5px;padding-left:5px;}
.pad-10-lr{padding-right:10px;padding-left:10px;}
.pad-15-lr{padding-right:15px;padding-left:15px;}
.pad-17-lr{padding-left:17px;padding-right:17px;}
.pad-18-lr{padding-left:18px;padding-right:18px;}
.pad-20-lr{padding-right:20px;padding-left:20px;}
.pad-21-lr{padding-right:21px;padding-left:21px;}
.pad-25-lr{padding-right:25px;padding-left:25px;}
.pad-30-lr{padding-right:30px;padding-left:30px;}
.pad-32-lr{padding-right:32px;padding-left:32px;}
.pad-40-lr{padding-right:40px;padding-left:40px;}
.pad-50-lr{padding-right:50px;padding-left:50px;}
.pad-55-lr{padding-right:55px;padding-left:55px;}
.pad-60-lr{padding-left:60px;padding-right:60px;}
.pad-70-lr{padding-left:70px;padding-right:70px;}
.pad-80-lr{padding-left:80px;padding-right: 80px}

.pad-0-r{padding-right:0 !important;}
.pad-2-r{padding-right:2px;}
.pad-3-r{padding-right:3px;}
.pad-5-r{padding-right:5px;}
.pad-7-r{padding-right:7px;}
.pad-10-r{padding-right:10px;}
.pad-15-r{padding-right:15px;}
.pad-20-r{padding-right:20px;}
.pad-30-r{padding-right:30px;}
.pad-40-r{padding-right:40px;}
.pad-45-r{padding-right:45px;}
.pad-50-r{padding-right:50px;}
.pad-58-r{padding-right:58px;}

.pad-0-t{padding-top:0;}
.pad-1-t{padding-top:1px;}
.pad-2-t{padding-top:2px;}
.pad-3-t{padding-top:3px;}
.pad-4-t{padding-top:4px;}
.pad-5-t{padding-top:5px;}
.pad-7-t{padding-top:7px;}
.pad-8-t{padding-top:8px;}
.pad-9-t{padding-top:9px;}
.pad-10-t{padding-top:10px;}
.pad-12-t{padding-top:12px;}
.pad-13-t{padding-top:13px;}
.pad-14-t{padding-top:14px;}
.pad-15-t{padding-top:15px;}
.pad-16-t{padding-top:16px;}
.pad-18-t{padding-top:18px;}
.pad-20-t{padding-top:20px;}
.pad-22-t{padding-top:22px;}
.pad-23-t{padding-top:23px;}
.pad-25-t{padding-top:25px;}
.pad-30-t{padding-top:30px;}
.pad-35-t{padding-top:35px;}
.pad-36-t{padding-top:36px;}
.pad-40-t{padding-top:40px;}
.pad-45-t{padding-top:45px;}
.pad-50-t{padding-top:50px;}
.pad-60-t{padding-top:60px;}
.pad-65-t{padding-top:65px;}
.pad-80-t{padding-top: 80px;}

.pad-0-tb{padding-top:0;padding-bottom:0;}
.pad-2-tb{padding-top:2px;padding-bottom:2px;}
.pad-5-tb{padding-top:5px;padding-bottom:5px;}
.pad-6-tb{padding-top:6px;padding-bottom:6px;}
.pad-8-tb{padding-top:8px;padding-bottom:8px;}
.pad-10-tb{padding-top:10px;padding-bottom:10px;}
.pad-12-tb{padding-top:12px;padding-bottom:12px;}
.pad-13-tb{padding-top:13px;padding-bottom:13px;}
.pad-14-tb{padding-top:14px;padding-bottom:14px;}
.pad-15-tb{padding-top:15px;padding-bottom:15px;}
.pad-20-tb{padding-top:20px;padding-bottom:20px;}
.pad-22-tb{padding-top:22px;padding-bottom:22px;}
.pad-25-tb{padding-top:25px;padding-bottom:25px;}
.pad-30-tb{padding-top:30px;padding-bottom:30px;}
.pad-40-tb{padding-top:40px;padding-bottom:40px;}
.pad-50-tb{padding-top:50px;padding-bottom:50px;}
.pad-55-tb{padding-top:55px;padding-bottom:55px;}
.pad-60-tb{padding-top:60px;padding-bottom:60px;}

.mar-center{margin:0 auto;}

.mar-0{margin:0;}
.mar-5{margin:5px;}
.mar-10{margin:10px;}
.mar-15{margin:15px;}
.mar-20{margin:20px;}
.mar-25{margin:25px;}
.mar-30{margin:30px;}
.mar-35{margin:35px;}
.mar-40{margin:40px;}
.mar-50{margin:50px;}
.mar-60{margin:60px;}

.mar-0-t{margin-top:0;}
.mar-1-t{margin-top:1px;}
.mar-2-t{margin-top:2px;}
.mar-3-t{margin-top:3px;}
.mar-5-t{margin-top:5px;}
.mar-6-t{margin-top:6px;}
.mar-7-t{margin-top:7px;}
.mar-8-t{margin-top:8px;}
.mar-10-t{margin-top:10px;}
.mar-12-t{margin-top:12px;}
.mar-15-t{margin-top:15px;}
.mar-18-t{margin-top:18px;}
.mar-20-t{margin-top:20px;}
.mar-25-t{margin-top:25px;}
.mar-30-t{margin-top:30px;}
.mar-35-t{margin-top:35px;}
.mar-40-t{margin-top:40px;}
.mar-45-t{margin-top:45px;}
.mar-48-t{margin-top:48px;}
.mar-50-t{margin-top:50px;}
.mar-60-t{margin-top:60px;}
.mar-65-t{margin-top:65px;}
.mar-80-t{margin-top:80px;}
.mar-85-t{margin-top:85px;}
.mar-100-t{margin-top:100px;}
.mar-125-t{margin-top:125px;}

.mar-0-b{margin-bottom:0;}
.mar-2-b{margin-bottom:2px;}
.mar-5-b{margin-bottom:5px;}
.mar-8-b{margin-bottom:8px;}
.mar-10-b{margin-bottom:10px;}
.mar-15-b{margin-bottom:15px;}
.mar-20-b{margin-bottom:20px;}
.mar-25-b{margin-bottom:25px;}
.mar-30-b{margin-bottom:30px;}
.mar-32-b{margin-bottom:32px;}
.mar-35-b{margin-bottom:35px;}
.mar-40-b{margin-bottom:40px;}
.mar-45-b{margin-bottom:45px;}
.mar-50-b{margin-bottom:50px;}
.mar-60-b{margin-bottom:60px;}
.mar-65-b{margin-bottom:65px;}
.mar-95-b{margin-bottom:95px;}
.mar-100-b{margin-bottom:100px;}

.mar-0-r{margin-right:0;}
.mar-1-r{margin-right:1px;}
.mar-2-r{margin-right:2px;}
.mar-5-r{margin-right:5px;}
.mar-8-r{margin-right:8px;}
.mar-10-r{margin-right:10px;}
.mar-15-r{margin-right:15px;}
.mar-20-r{margin-right:20px;}
.mar-25-r{margin-right:25px;}
.mar-30-r{margin-right:30px;}
.mar-35-r{margin-right:35px;}
.mar-40-r{margin-right:40px;}
.mar-50-r{margin-right:50px;}
.mar-60-r{margin-right:60px;}
.mar-70-r{margin-right:70px;}
.mar-4-r-neg, iblk.reset-mar{margin-right:-4px;}

.mar-0-l{margin-left:0;}
.mar-1-l{margin-left:1px;}
.mar-5-l{margin-left:5px;}
.mar-10-l{margin-left:10px;}
.mar-12-l{margin-left:12px;}
.mar-15-l{margin-left:15px;}
.mar-20-l{margin-left:20px;}
.mar-25-l{margin-left:25px;}
.mar-30-l{margin-left:30px;}
.mar-40-l{margin-left:40px;}
.mar-50-l{margin-left:50px;}
.mar-60-l{margin-left:60px;}
.mar-70-l{margin-left:70px;}
.mar-130-l{margin-left:130px;}

.mar-0-lr{margin-right:0;margin-left:0;}
.mar-5-lr{margin-right:5px;margin-left:5px;}
.mar-10-lr{margin-right:10px;margin-left:10px;}
.mar-15-lr{margin-right:15px;margin-left:15px;}
.mar-20-lr{margin-right:20px;margin-left:20px;}

.mar-5-tb{margin-top:5px;margin-bottom:5px;}
.mar-10-tb{margin-top:10px;margin-bottom:10px;}
.mar-20-tb{margin-top:20px;margin-bottom:20px;}
.mar-30-tb{margin-top:30px;margin-bottom:30px;}
.mar-40-tb{margin-top:40px;margin-bottom:40px;}
.mar-50-tb{margin-top:50px;margin-bottom:50px;}

// Negative Margins
.mar-1-t-neg{margin-top:-1px;}
.mar-2-t-neg{margin-top:-2px;}
.mar-5-t-neg{margin-top:-5px;}
.mar-10-t-neg{margin-top:-10px;}
.mar-2-b-neg{margin-bottom:-2px;}
.mar-5-b-neg{margin-bottom:-5px;}
.mar-30-l-neg{margin-left:-30px;}
.mar-20-l-neg{margin-left:-20px;}
.mar-10-l-neg{margin-left:-10px;}

.blk-center,.center-div{margin-left:auto;margin-right:auto;}

.col-last{margin-right:0;}
