.logo-rak-w {
  background-image: url("#{$static-path-rakuten}rakuten_nav_white.svg");
  background-repeat: no-repeat;
  background-size: 100%;
}
.logo-rak-grad {
  background-image: url("#{$static-path-rakuten}rakuten_nav_gradient.svg");
  background-repeat: no-repeat;
  background-size: 100%;
}
.logo-rak-b {
  background-image: url("#{$static-path-rakuten}rakuten_nav_black.svg");
  background-repeat: no-repeat;
  background-size: 100%;
}

.phase-2 { // Reserved for Rebrand Phase 2

}