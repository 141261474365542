// Square Blocks >>>
.square-10{ @include square; }
.square-12{ @include square(12px); }
.square-14{ @include square(14px); }
.square-15{ @include square(15px); }
.square-16{ @include square(16px); }
.square-18{ @include square(18px); }
.square-20{ @include square(20px); }
.square-28{ @include square(28px); }
.square-25{ @include square(25px); }
.square-30{ @include square(30px); }
.square-36{ @include square(36px); }
.square-40{ @include square(40px); }
.square-46{ @include square(46px); }
.square-50{ @include square(50px); }
.square-60{ @include square(60px); }
.square-70{ @include square(70px); }
.square-72{ @include square(72px); }
.square-80{ @include square(80px); }
.square-90{ @include square(90px); }
.square-100{ @include square(100px); }
.square-150{ @include square(150px); }
.square-160{ @include square(160px); }
.square-165{ @include square(165px); }
.square-200{ @include square(200px); }
.square-240{ @include square(240px); }
.square-250{ @include square(250px); }
.square-300{ @include square(300px); }
.square-350{ @include square(350px); }
// Square Blocks <<<

.ht-0,.zero-height{height:0;padding:0;display:block;}
.ht-1{height:1px;}
.ht-2{height:2px;}
.ht-3{height:3px;}
.ht-5{height:5px;}
.ht-12{height:12px;}
.ht-14{height:14px;}
.ht-16{height:16px;}
.ht-18{height:18px;}
.ht-20{height:20px;}
.ht-22{height:22px;}
.ht-24{height:24px;}
.ht-27{height:27px;}
.ht-28{height:28px;}
.ht-30{height:30px;}
.ht-32{height:32px;}
.ht-34{height:34px;}
.ht-36{height:36px;}
.ht-38{height:38px;}
.ht-40{height:40px;}
.ht-44{height:44px;}
.ht-46{height:46px;}
.ht-48{height:48px;}
.ht-50{height:50px;}
.ht-54{height:54px;}
.ht-60{height:60px;}
.ht-70{height:70px;}
.ht-80{height:80px;}
.ht-100{height:100px;}
.ht-112{height:112px;}
.ht-156{height:156px;}
.ht-160{height:160px;}
.ht-170{height:170px;}
.ht-171{height:171px;}
.ht-200{height:200px;}
.ht-220{height:220px;}
.ht-240{height:240px;}
.ht-250{height:250px;}
.ht-263{height:263px;}
.ht-270{height:270px;}
.ht-300{height:300px;}
.ht-350{height:350px;}
.ht-100-perc{height:100%;}

.logo-100{ @include rectangle(100px, 27px); }
.logo-150{ @include rectangle(150px, 40px); }
.img-274{ @include rectangle(274px, 150px); }
.img-300{ @include rectangle(300px, 250px); }

.w-0{width:0;}
.w-50{width:50px;}
.w-60{width:60px;}
.w-70{width:70px;}
.w-95{width:95px;}
.w-100{width:100px;}
.w-106{width:106px;}
.w-110{width:110px;}
.w-120{width:120px;}
.w-150{width:150px;}
.w-180{width:180px;}
.w-220{width:220px;}
.w-230{width:230px;}
.w-250{width:250px;}
.w-270{width:270px;}
.w-274{width:274px;}
.w-290{width:290px;}
.w-300{width:300px;}
.w-320{width:320px;}
.w-340{width:340px;}
.w-360{width:360px;}
.w-400{width:400px;}
.w-440{width:440px;}
.w-500{width:500px;}
.w-600{width:600px;}
.w-700{width:700px;}

input.full,button.full,a.full{ width:100%; }
