// NOTE:
// The following File Extensions are cached at the Akamai level:
// .eot - Embedded OpenType - Used by older IE Browsers with #iefix and IE 11 without #iefix.
// http://caniuse.com/#feat=eot

// .svg - Used by Safari Mac 10+, iOS Safari 9.3+, Android Browser 4.3 - 4.4.4
// http://caniuse.com/#feat=svg-fonts

// .ttf - True Type Font (Open Type Font) - Used by IE 11, Edge 14+, Firefox 50+, Chrome 49+, Opera 43+, Safari 10+, iOS Safari 9.3+, Android Browser 4.3+, Chrome for Android 56.
//  http://caniuse.com/#feat=ttf

// .woff - Web Open Font Format V1 - Used by IE 11, Edge 14+ Firefox 50+, Chrome 49+, Opera 43+, Safari 10+, iOS Safari 9.3+, Android Browser 4.3+, Chrome for Android 56.
// http://caniuse.com/#feat=woff

// .woff2 - Web Open Font Format V2 - Used by Edge 14+, Firefox 50+, Chrome 49+, Opera 43+, Safari 10+ (Mac OS Sierra +), iOS Safari 10.2+, Android Browser 53, Chrome for Android 56.
// http://caniuse.com/#feat=woff2

// Begin FontAwesome
// individual fa- font classes should be loaded from /static/rev/global/css/fonts_fa.css

// NOTE: Full set of FA fonts - Slower Download - Heavier File Size
// $fonts-fa-path: "/global_files/fonts/icon/fontawesome/v.4.7.0/fonts"; // #{$fonts-fa-path} PATH to FULL FONT AWESOME

// NOTE: Limited set only loads the FA fonts the site needs - Faster Download - Lighter File Size
$fonts-fa-path: "/global_files/fonts/icon/fontawesome/v.4.5.0.7/fonts/fonts.1.1.9"; // #{$fonts-fa-path}

@font-face{
  font-family:'fontawesome-webfont';
  src:url('#{$fonts-fa-path}/fontawesome-webfont.eot?ghyph');
  src:url('#{$fonts-fa-path}/fontawesome-webfont.eot?ghyph#iefix') format('embedded-opentype'),
  url('#{$fonts-fa-path}/fontawesome-webfont.woff2?ghyph') format('woff2'),
  url('#{$fonts-fa-path}/fontawesome-webfont.ttf?ghyph') format('truetype'),
  url('#{$fonts-fa-path}/fontawesome-webfont.woff?ghyph') format('woff'),
  url('#{$fonts-fa-path}/fontawesome-webfont.svg?ghyph#fontawesome-webfont') format('svg');
  font-weight:normal;
  font-style:normal;
}
[class^="fa-"],[class*=" fa-"]{display:inline-block;font-family:'fontawesome-webfont' !important;font-style:normal;font-weight:normal;font-variant:normal;text-transform:none;line-height:1;text-rendering:auto;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;speak:none;}
.fa{display:inline-block;}
.fa-heart:before{content:"\f004";}
.fa-heart-o:before{content:"\f08a";}
.fa-clock-o:before{content:"\f017";}
.fa-search:before{content:"\f002";}
.fa-close:before,.fa-remove:before,.fa-times:before{content:"\f00d";}
.fa-plus:before{content:"\f067";}
.fa-certificate:before{content:"\f0a3";}
.fa-truck:before{content:"\f0d1";}
.fa-bell:before{content:"\f0f3";}
.fa-bell-o:before{content:"\f0a2";}
.fa-check:before{content:"\f00c";}
.fa-bookmark:before{content:"\f02e";}
.fa-bookmark-o:before{content:"\f097";}
.fa-google-plus:before{content:"\f0d5";}
// .fa-google:before{content:"\f1a0";} // Overwrite FA Icon
.fa-google {
  background: url("https://static.ebates.com/static/fonts/svg/google/google_G_logo.svg") no-repeat 100%;
  margin: auto;
  height: 16px;
  width: 16px;
  &:before { display: none; }
}
.fa-facebook:before,.fa-facebook-f:before{content:"\f09a";}
.fa-caret-down:before{content:"\f0d7";}
.fa-caret-up:before{content:"\f0d8";}
.fa-caret-left:before{content:"\f0d9";}
.fa-caret-right:before{content:"\f0da";}
.fa-envelope:before{content:"\f0e0";}
.fa-envelope-o:before{content:"\f003";}
.fa-lock:before{content:"\f023";}
.fa-credit-card:before{content:"\f09d";}
.fa-angle-up:before{content:"\f106";}
.fa-angle-down:before{content:"\f107";}
.fa-angle-right:before{content:"\f105";}
.fa-angle-left:before{content:"\f104";}
.fa-comments:before{content:"\f086";}
.fa-comments-o:before{content:"\f0e6";}
.fa-star:before{content:"\f005";}
.fa-star-o:before{content:"\f006";}
.fa-star2:before{content:"\f007";}
.fa-star-o2:before{content:"\f009";}
.fa-gift:before{content:"\f06b";}
.fa-globe:before{content:"\f0ac";}
.fa-bars:before,.fa-navicon:before,.fa-reorder:before{content:"\f0c9";}
.fa-align-left:before{content:"\f036";}
.fa-question-circle:before{content:"\f059";}
.fa-pencil:before{content:"\f040";}
.fa-spinner:before{content:"\f110";}
.fa-warning:before,.fa-exclamation-triangle:before{content:"\f071";}
.fa-calendar:before{content:"\f073";}
.fa-calendar-o:before{content:"\f133";}
.fa-calendar-plus-o:before{content:"\f271";}
.fa-calendar-minus-o:before{content:"\f272";}
.fa-calendar-times-o:before{content:"\f273";}
.fa-calendar-check-o:before{content:"\f274";}
.fa-circle:before{content:"\f111";}
.fa-arrow-circle-right:before{content:"\f0a9";}
.fa-arrow-left:before{content:"\f060";}
.fa-arrow-right:before{content:"\f061";}
.fa-arrow-up:before{content:"\f062";}
.fa-arrow-down:before{content:"\f063";}
.fa-check-circle:before{content:"\f058";}
.fa-map-marker:before{content:"\f041";}
.fa-info-circle:before{content:"\f05a";}
.fa-star-half:before{content:"\f089";}
.fa-star-half-empty:before{content:"\f123";}
.fa-star-half-full:before{content:"\f123";}
.fa-star-half-o:before{content:"\f123";}
.fa-chevron-left:before{content:"\f053";}
.fa-chevron-right:before{content:"\f054";}
.fa-chevron-down:before{content:"\f078";}
.fa-chevron-up:before{content:"\f077";}
.fa-plus-square:before{content:"\f0fe";}
.fa-minus-square:before{content:"\f146";}
.fa-times-circle:before{content:"\f057";}
.fa-plus-circle:before{content:"\f055";}
.fa-minus-circle:before{content:"\f056";}
.fa-arrow-circle-left:before{content:"\f0a8";}
.fa-arrow-circle-up:before{content:"\f0aa";}
.fa-arrow-circle-down:before{content:"\f0ab";}
.fa-exclamation-circle:before{content:"\f06a";}
.fa-list-ul:before{content:"\f0cb";}
.fa-list-ol:before{content:"\f0cc";}
.fa-th-list:before{content:"\f00b";}
.fa-th:before{content:"\f00a";}
.fa-print:before{content:"\f02f";}
.fa-home:before{content:"\f015";}
.fa-dollar:before,.fa-usd:before{content:"\f155";}
.fa-money:before{content:"\f0d6";}
.fa-arrows-v:before{content:"\f07d";}
.fa-arrows-h:before{content:"\f07e";}
.fa-trademark:before{content:"\f25c";}
.fa-registered:before{content:"\f25d";}
.fa-calculator:before{content:"\f1ec";}
.fa-credit-card-alt:before{content:"\f283";}
.fa-search-plus:before{content:"\f00e";}
.fa-search-minus:before{content:"\f010";}
.fa-minus:before{content:"\f068";}
.fa-toggle-off:before{content:"\f204";}
.fa-toggle-on:before{content:"\f205";}
.fa-ellipsis-h:before{content:"\f141";}
.fa-ellipsis-v:before{content: "\f142";}
.fa-safari:before{content:"\f267";}
.fa-chrome:before{content:"\f268";}
.fa-firefox:before{content:"\f269";}
.fa-opera:before{content:"\f26a";}
.fa-internet-explorer:before{content:"\f26b";}
.fa-edge:before{content:"\f282";}
.fa-circle-thin:before{content:"\f1db";}
.fa-circle-o:before{content:"\f10c";}
.fa-chevron-circle-left:before{content:"\f137";}
.fa-chevron-circle-right:before{content:"\f138";}
.fa-chevron-circle-up:before{content:"\f139";}
.fa-chevron-circle-down:before{content:"\f13a";}
.fa-plane:before{content:"\f072";}
.fa-suitcase:before{content:"\f0f2";}
.fa-automobile:before,.fa-car:before{content:"\f1b9";}
.fa-ship:before{content:"\f21a";}
.fa-bed:before,.fa-hotel:before{content:"\f236";}
.fa-sliders:before{content:"\f1de";}
.fa-feed:before,.fa-rss:before{content:"\f09e";}
.fa-life-bouy:before,.fa-life-buoy:before,.fa-life-ring:before,.fa-life-saver:before,.fa-support:before{content:"\f1cd";}
.fa-twitter:before{content:"\f099";}
.fa-pinterest-p:before{content:"\f231";}
.fa-play-circle-o:before{content:"\f01d";}
.fa-play-circle:before{content:"\f144";}
.fa-pause-circle:before{content:"\f28b";}
.fa-pause-circle-o:before{content:"\f28c";}
.fa-stop-circle:before{content:"\f28d";}
.fa-stop-circle-o:before{content:"\f28e";}
.fa-question:before{content:"\f128";}
.fa-info:before{content:"\f129";}
.fa-exclamation:before{content:"\f12a";}
.fa-quote-left:before{content:"\f10d";}
.fa-quote-right:before{content:"\f10e";}
.fa-list-alt:before{content:"\f022";}
.fa-user:before{content:"\f00f";}
.fa-thumbs-o-up:before{content:"\f087";}
.fa-thumbs-o-down:before{content:"\f088";}
.fa-tag:before{content:"\f02b";}
.fa-tags:before{content:"\f02c";}
.fa-scissors:before{content:"\f0c4";}
.fa-cut:before{content:"\f0c4";}
.fa-chain:before{content:"\f0c1";}
.fa-link:before{content:"\f0c1";}
.fa-chain-broken:before{content:"\f127";}
.fa-unlink:before{content:"\f127";}
.fa-comment:before{content:"\f075";}
.fa-comment-o:before{content:"\f0e5";}
.fa-square:before{content:"\f0c8";}
.fa-square-o:before{content:"\f096";}
.fa-gears:before{content:"\f085";}
.fa-bullhorn:before{content:"\f0a1";}
.fa-phone:before{content:"\f095";}
.fa-paper-plane:before,.fa-send:before{content:"\f1d8";}
.fa-file-text-o:before{content:"\f0f6";}
.fa-folder-o:before{content:"\f114";}
.fa-folder-open-o:before{content:"\f115";}
.fa-user-circle:before{content:"\f2bd";}
.fa-user-circle-o:before{content:"\f2be";}
.fa-user-o:before{content:"\f2c0";}
.fa-refresh:before{content:"\f021";}
.fa-repeat:before,.fa-rotate-right:before{content:"\f01e";}
.fa-share:before,.fa-mail-forward:before{content:"\f064";}
.fa-location-arrow:before{content: "\e900";}
.fa-times-rectangle:before,.fa-window-close:before{content:"\f2d3";}
.fa-sort-desc:before,.fa-sort-down:before{content:"\f0dd";}
.fa-sort-asc:before,.fa-sort-up:before{content:"\f0de";}
.fa-sort:before,.fa-unsorted:before{content:"\f0dc";}
.fa-youtube-play:before{content: "\f16a";}
.fa-genderless:before{content: "\f22d";}
/*.fa-instagram:before{content:"\f16d";}*/
/*.fa-instagram2:before{content: "\f16e";}*/
.fa-instagram:before{content:"\f16d";}
.fa-apple:before{content: "\f179";}
.fa-android:before{content: "\f17b";}
.fa-external-link:before {content: "\f08e";}
.fa-volume-up:before { content: "\f028"; }
.fa-linkedin:before { content: "\f0e1"; }
// End FontAwesome