@import "../partials/functions";
@import "../partials/variables";
@import "../partials/mixins";
form {
  input[type=text],
  input[type=password],
  input[type=email],
  input[type=search],
  input[type=range],
  input[type=date],
  input[type=datetime] {
    @include round(8px);
    @include border-box;
    &.round-3 { @include round(8px); } // overwrite any markup using round-3
  }
}
.ebform {
  input[type=text],
  input[type=password],
  input[type=email],
  input[type=search],
  input[type=range],
  input[type=date],
  input[type=datetime] {
    @include height(46px);
    @include round(8px);
    @include border-box;
    font-size: 16px;
    padding: 0 20px;
    &.round-3 { @include round(8px); } // overwrite any markup using round-3
  }
  input[type=search] { -webkit-appearance: textfield; }
  .icon-l {
    input.icon-field { padding-left: 46px; }
    .field-icon {
      position: absolute;
      margin-right: -46px;
      top: 0;
      left: 0;
    }
  }
  .icon-r {
    input.icon-field { padding-right: 46px; }
    .field-icon {
      position: absolute;
      margin-left: -46px;
      top: 0;
      right: 0;
    }
  }
  .field-icon {
    @include round;
    @include border-box;
    @include position(absolute);
    @include square(46px);
    line-height: 46px;
    font-size: 16px;
    cursor: pointer;
    text-align: center;
  }
  .icon-46 {
    @extend .field-icon;
    @include position(relative);
  }
  .error-box {
    line-height: 18px;
    color: $error-color;
  }
  li {
    position: relative;
    margin-bottom:10px;
  }

  // Radio Group
  .radgroup {
    display: block;
    position: relative;
    padding: 11px 0 11px 40px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      &:checked ~ .checkmark {
        background-color: $white-color;
        border:1px solid $prim-text-color;
        &:after {
          display: block;
          background-color: $prim-text-color;
        }
      }
    }
    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 18px;
      width: 18px;
      margin: 11px;
      background-color: $white-color;
      border-radius: 50%;
      border:1px solid $darker-border-color;
      &:after {
        content: "";
        position: absolute;
        display: none;
        top: 4px;
        left: 4px;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: $white-color;
      }
    }
    &:hover input ~ .checkmark {
      background-color: $white-color;
      border:1px solid $prim-text-color;
    }
  }
  .select-group {
    @include _select-form-reset; // Select Reset Mixin
    @include round;
    @include border($border-color: $darker-border-color);
    select {
      @include height(46px);
      @include border-box;
      font-size: 16px;
      padding: 0 20px;
      width: 100%;
      border: none;
      outline: none;
      display: inline-block;
      -webkit-appearance:none;
      -moz-appearance: none;
      appearance: none;
      cursor: pointer;
      transition: border-color 0.2s ease, outline 0.2s ease;
      &::-ms-expand { display: none; }
      option{
        display: block;
        background-color: $white-color;
        -webkit-appearance: none;
        outline: none;
      }
      &[disabled], &:disabled{
        opacity: 0.4;
        cursor: not-allowed;
      }
      &:not(:focus):invalid { color: $hint-color; }
    }
    &::after {
      font-family: 'fontawesome-webfont' !important;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      content: '\f107'; // Can be any FA value
      position: absolute;
      font-size: 16px;
      @include border-box;
      top: 15px;
      right: 0;
      bottom: 0;
      padding: 0 20px 0 0;
      background: none;
      pointer-events: none;
      -webkit-transition: .25s all ease;
      -o-transition: .25s all ease;
      transition: .25s all ease;
      /* Use to overwrite some of the above values if there is some kind of color UI associated with the right arrow section.
      top: 0;
      height: 46px;
      width: 48px;
      padding: 15px 0 0 18px;
      background: $white-color;
      @include border-left( $border-color: $darker-border-color )
       */
    }
    &:hover::after {
      color: $darker-border-color;
      /* Use to overwrite if there is some kind of color UI associated with the right arrow section.
      @include border-left( $border-color: $white-color )
       */
    }
  }
}
.mwview .ebform .radgroup .checkmark:after {
  top: 3px;
  left: 3px;
}
input, textarea, select, button { @include rakutenSansUI(Rakuten Sans UI, 400); }

/*Password Tooltiip*/
.eb-form-layout .pwd-tooltip,
.eb-form-SEM .pwd-tooltip,
.eb-form-hotels-signup .pwd-tooltip,
.eb-form-Store-Shopping-Overlays .pwd-tooltip,
.eb-form-new-landing .pwd-tooltip,
.eb-form-skinny .pwd-tooltip {
  position: absolute;
  text-align: left;
  left: -232px;
  top: -46px;
  width: 180px;
  z-index: 100001;
}
.eb-form-Store-Shopping-Overlays .pwd-tooltip:before,
.eb-form-layout .pwd-tooltip:before,
.eb-form-SEM .pwd-tooltip:before,
.eb-form-hotels-signup .pwd-tooltip:before,
.eb-form-new-landing .pwd-tooltip:before,
.eb-form-skinny .pwd-tooltip:before {
  border-top: 1px solid $darker-border-color;
  border-left:1px solid $darker-border-color;
  transform:rotate(135deg);
  -ms-transform: rotate(135deg);
  height: 10px;
  width: 10px;
  top: 50%;
  margin-top: -6px;
  right: -7px;
  content: "";
  position: absolute;
  background-color: $white-color;
}
.error-red { color: $error-color; }
.darkorange { color: $darkorange-color; }
.eb-form-layout .error+input.in-error,
.eb-form-SEM .error+input.in-error,
.eb-form-hotels-signup .error+input.in-error.text,
.eb-form-Store-Shopping-Overlays .error+input.in-error.text,
.eb-form-new-landing .error+input.in-error.text,
.eb-form-skinny .error+input.in-error.text,
.eb-form-Store-Shopping-Overlays input.text.border-red,
.eb-form-new-landing .eb-auth-form input.text.border-red {
  border-color: $error-color;
  outline: none;
}
.st-ma-modal .eb-form-layout .error,
.landing-su-form .eb-auth-form .error {
  padding: 20px;
  left: -210px !important
}
.refresh-icon+.error { margin-top: 50px; }
/*End password Tooltip*/

input[type=text],
input[type=password],
input[type=email],
input[type=search],
input[type=range],
input[type=select],
input[type=date],
input[type=datetime] {
  border:solid 1px $darker-border-color;
  outline: 0;
  box-shadow:none;
  &.border { border:solid 1px $darker-border-color; }
}
input {
  &::placeholder {
    color: $hint-color;
    opacity: 1;
  }
}
