@import "../partials/functions";
@import "../partials/variables";
@import "../partials/mixins";

// Main Header >>>
.pre-header {
  .h-browser-type {
    .h-icon { margin-top: 10px; }
    .fa-chevron-circle-right{ margin-top: 13px; }
  }
  &.rak-header {
    background: $prim-text-color;
    height: 48px;
    p, a { line-height: 48px; }
  }
}
.header-wrapper {
  height: 128px;
  // This the MAIN tree structure within .header-wrapper
  // .h-mainheader
    // .h-search
      // .logo
      // .h-search-form
      // .user
      // #fb-root
    // .h-ribbon
      // #main-nav .main-nav
}

.h-mainheader {
  height: 128px;
  min-width: $max-width;
  top: 0;
  z-index: 100;

  // Header Transitions >>>>>
  transition: height .5s;
  .trans-bg { transition: background 1s }
  .trans-color-bg  {transition: background 1s, color 1s; }
  .trans-color { transition: color 1s; }
  &.fixed {
    top: 0;
    border-bottom: none;
    .h-ribbon {
      box-shadow:  0 4px 8px rgba(10, 22, 70, 0.15);
      @include border-bottom($border-color: $border-color-header);
      bottom: 0;
    }
    &.min {
      height: 80px;
      .h-search {
        @include background($white-color);
      }
    }
  }
  // Header Transitions <<<<<
  .h-ribbon {
    height: 48px;
    bottom: 0;
    @include border-bottom($border-color: $border-color-header);
  }
}
//temp solution to support inline branch banner in help pages
.branch-banner-is-active.mwview .h-mainheader.fixed,
.branch-banner-is-active.mobile .h-mainheader.fixed {
  margin-top: 76px;
}

.mwview iframe#branch-banner-is-active {
  z-index: 1099;
}

.gc-ovr-head .logo2{width:168px;height:35px;}/*used by Sign In Overlays*/


// .h-search >>>>>
.h-search {
  // @include gradient(225deg, $gradient-header); // background: linear-gradient(225deg, $header-color-start 0%, $header-color-mid 37.53%, $header-color-end 100%);
  height: 80px;
  z-index: 200;
  @include border-bottom($border-color: $border-color-header);
  .logo3 {
    padding-top: 27px;
    float: left;
    width: 96px;
    height: 27px;
    @include tablet-screens-full { margin-left:10px; }
    .svg-eb-logo3 {
      width: 96px;
      height: 27px;
    }
    &.alt {
      width: 93px;
      height: 50px;
      a {
        width: 93px;
        height: 50px;
      }
      .svg-eb-logo3 {
        &.alt {
          width: 93px;
          height: 50px;
          background-size: 93px 50px;
        }
      }
    }
  }
  &.border-b { @include border-bottom($border-color: $border-color-header); }
  .header-category-menu {
    display: block;
    float: left;
    position: relative;
    margin-left: 30px;
    .category-menu-trigger {
      cursor: pointer;
      text-decoration:none;
      color: $sec-text-color;
      height: 80px;
      line-height: 80px;
      font-size: 14px;
      padding-left: 35px;
      padding-right: 35px;
      display: block;
      &:hover,
      &:focus {
        color: $brand-01-hover;
        .categories-icon,
        .chevron-down-icon { fill: $brand-01-hover; }
        .chevron-down-icon { transform: rotate(180deg); }
      }
      .categories-icon {
        margin-right: 8px;
        margin-bottom: 0;
      }
      .chevron-down-icon {
        margin-left: 6px;
        margin-top: -1px
      }
      .category-menu-title { @include rakutenSansUIsemiBold; }
    }
    // Category Nav Sub-Menu >>>>>
    .categ-nav-sub {
      display: none;
      position: absolute;
      width: auto;
      text-align: left;
      z-index:99;
      top: 80px;
      left: -165px;
      .main-cat {
        margin-left: 40px;
        min-height: 456px;
        min-width: 270px;
        position: absolute;
        padding-top: 20px;
        padding-bottom: 20px;
        @include store-tile;
        // @include round (8px 0 0 8px);
        &.hovered { @include round (8px 0 0 8px); }
        .main-cat-li-all {
          display: block;
          width: 100%;
          padding-left: 30px;
          padding-right: 30px;
          a {
            display: inline-block;
            width: 100%;
            padding-top: 10px;
            padding-bottom: 10px;
            line-height: 18px;
            &.main-cat-link { float: left; }
            .fa-external-link { float: right; }
            text-decoration: none;
          }
          &.last {
            a {
              @include border-bottom($border-width: 1px, $border-color: $border-color);
              padding-bottom: 15px;
              margin-bottom: 5px;
            }
          }
        }
        .main-cat-li {
          padding-left: 30px;
          padding-right: 30px;
          display: block;
          overflow: auto;
          .main-cat-link {
            font-size: 14px;
            line-height: 18px;
            padding-top: 10px;
            padding-bottom: 10px;
            &:hover {
              cursor: pointer;
              color: $brand-01-hover;
              span { color: $brand-01-hover; }
            }
            .main-cat-txt {
              width: 180px;
              display: inline-block;
              float: left;
              &:hover {
                cursor: pointer;
                color: $brand-01-hover;
                .fa-angle-right { color: $brand-01-hover; }
              }
            }
            .fa-angle-right { padding-left: 20px; }
          }
          .main-cat-sub {
            display: none;
            position: absolute;
            top:0;
            left: 270px;
            min-height: 100%;
            min-width: 620px;
            @include store-tile;
            @include round (0 8px 8px 0);
            &.main-cat-sub-wid{ width: 850px; }
            ul {
              width: 260px;
              &.featured{
                width: 260px;
                float: left;
                padding-top: 30px;
                .subcat-top {
                  display: block;
                  padding-left: 30px;
                  padding-bottom: 10px;
                  margin-right: 30px;
                  &.featured-subcat-top { padding-top: 20px; }
                  .subcat-name {
                    display: block;
                    font-size: 16px;
                    line-height: 20px;
                  }
                }
                .subcat-top-single {
                  display: block;
                  clear: both;
                  padding-top: 5px;
                  padding-bottom: 5px;
                  padding-left: 30px;
                  margin-right: 30px;
                }
              }
              &.recommended{
                width: 200px;
                float: left;
                padding-top: 30px;
                padding-right: 30px;
                .subcat-top {
                  display: block;
                  padding-bottom: 10px;
                  .subcat-name {
                    display: block;
                    font-size: 16px;
                    line-height: 20px;
                  }
                }
                .main-cat-sub-li {
                  .cat-sub-name {
                    display: block;
                    padding-top: 5px;
                    line-height: 18px;
                    color: $prim-text-color;
                    &:hover {
                      color: $brand-01-hover;
                    }
                  }
                  .cat-cb {
                    font-size: 14px;
                    line-height: 18px;
                    padding-bottom: 5px;
                    display: block;
                  }
                  a {
                    &:hover {
                      text-decoration: none;
                      .cat-sub-name {
                        color: $brand-01-hover;
                      }
                    }
                  }
                }
              }
              &.fs{ width: 260px; }
              &.fst{ width: 260px; }
              li {
                &:hover {
                  .subcat-link {
                    color: $brand-01-active;
                    text-decoration: none;
                  }
                }
                .subcat-link {
                  font-size: 14px;
                  line-height: 18px;
                  color: $prim-text-color;
                }
              }
            }
            .main-cat-sub-image {
              width: 360px;
              float: right;
              font-size: 14px;
              .image-container-blk {
                display: block;
                padding: 30px;
                .image-blk {
                  display: block;
                  padding-bottom: 10px;
                  a {
                    padding: 0;
                    display: table;
                    img {
                      display: block;
                      @include store-tile;
                      margin:0 auto;
                    }
                  }
                }
                .feat-store-link {
                  display: block;
                  font-size: 14px;
                  line-height: 18px;
                  padding-top: 5px;
                  padding-bottom: 5px;
                  span {
                    display: inline-block;
                    font-size: 14px;
                    line-height: 18px;
                    @include rakutenSansUIsemiBold;
                    &.cb {
                      padding-left: 5px;
                    }
                  }
                }
              }
            }
          }
          &:hover {
            .fa-angle-right.f-gry-dk-8 { color: $brand-01-hover; }
            .main-cat-link {
              span { color: $brand-01-hover; }
            }
          }
        }
      }
    }
    &:hover {
      .categ-nav-sub {
        display: block;
        z-index:99;
      }
    }
    // Category Nav Sub-Menu <<<<<
  }

  // Search Suggest Form inside .h-search >>>>>
  .h-search-form {
    width: 607px;
    position: absolute;
    left: 310px;
    padding-top: 16px;
    @include respond-to-min-max(320, 1180) { left: 215px; }
    form {
      width: 607px;
      margin: 0;
      padding: 0;
      input { // Search Field Input
        &.h-search-field {
          z-index:9999;
          font-size: 14px;
          height: 48px;
          // line-height: 48px;
          vertical-align: middle; // needed to fix an issue with Safari 13.1 so commenting out line-height: 48px;
          line-height: normal; // needed to fix an issue with Safari 13.1 so commenting out line-height: 48px;
          position: absolute;
          border: 1px solid $tert-text-color;
          border-right: none;
          @include round(8px 0 0 8px);
          &:focus,
          &:hover {
            border: 1px solid $brand-01-hover;
            border-right: none;
          }
          &.search-focused {
            border: 1px solid $brand-01-hover;
            border-right: none;
            @include round(8px 0 0 0);
          }
          &.text {
            width: 559px;
            padding: 0 20px;
            display: block;
            z-index: 9998;
            outline: none;
          }
          &::-ms-clear { display: none; }
          &::placeholder {
            color: $tert-text-color;
            opacity: 1; // Firefox
          }
          &:-ms-input-placeholder { // Internet Explorer 10-11
            color: $tert-text-color;
          }
          &::-ms-input-placeholder { // Microsoft Edge
            color: $tert-text-color;
          }
        }
      }
      .ac_results { // Search Results Container
        padding: 0;
        overflow: hidden;
        position: absolute;
        @include store-tile;
        @include round(0 0 8px 8px); // Overwrite 2 of the round corners from store-tile
        @include border;
        z-index: 9995;
        border-top: 1px solid $tert-text-color;
        margin-top: 1px;
        transition: all 2s;
        ul {
          width: 605px;
          font-size: 16px;
          @include border-bottom;
          padding: 10px 0;
          &.button-suggest-cont .suggestion {
            padding: 0 20px;
            line-height: 36px;
          }
          .button-suggest-icon {
            height: 24px;
            width: 24px;
            padding: 6px;
            float: right;
          }
          &:empty { display: none; }
          &.store-suggest-cont,
          &.button-suggest-cont,
          &.prod-suggest-cont {
            margin-bottom: 0;
          }
        }
        li {
          margin: 0;
          padding: 10px 20px;
          line-height: 16px;
          cursor: pointer;
          display: block;
          overflow: hidden;
          clear: both;
          font-size: 16px;
          &:hover, &.selected {
            background: $extra-light-gray;
          }
          .store-name {
            color: $black-color;
            float: left;
            width: 340px;
            display: block;
          }
          .cashback {
            color: $brand-01;
            float: right;
            font-weight: normal;
            width: 150px;
            text-align: right;
          }
        }
        .see-all {
          color: $brand-01;
          font-weight: normal;
          font-size: 16px;
          a {
            &:hover {
              text-decoration: none;
            }
          }
        }
      }
      .button {
        &.search-form-button {
          // Search Field Button
          float: right;
          height: 48px;
          line-height: 48px;
          min-width: 48px;
          width: 48px;
          padding: 0 19px;
          background: $brand-01;
          @include round(0 8px 8px 0);
          border: 1px solid $brand-01;
          border-left: none;
          .fa-search {
            display: block;
            color: $white-color;
            font-size: 15px;
            margin-bottom: 1px;
            margin-left: -1px;
          }
          &.search-focused {
            border: 1px solid $tert-text-color;
            border-left: none;
          }
          &.color-change{
            background: $brand-01;
            border: 1px solid $brand-01;
            border-left: none;
            .fa-search { color: $white-color; }
            &:hover{
              background-color: $brand-01-hover;
            }
          }
        }
      }
    }
    @include tablet-screens-full { margin-left:99px; }
  }
  // Search Suggest Form inside .h-search <<<<<

  // User Menu Styles >>>>>
  .user {
    right: 0;
    top: 0;
    position: relative;
    z-index: 9999;
    padding-top: 30px;
    @include tablet-screens-full { margin-right: 25px; }
    ul {
      li {
        float: none;
        position: relative;
        padding: 0;
        &.connect {
          padding: 5px 30px 15px 30px;
          height: 30px;
          @include border-bottom;
          .fb-btn,
          .gp-btn {
            float: left;
            padding-top: 5px;
            .button {
              width: 100px;
              padding: 0 10px;
            }
          }
          .fb-btn {
            .button { margin-right: 10px; }
          }
        }
      }
    }
    .account-menu {
      position: relative;
      float: right;
      cursor: pointer;
      .account-menu-trigger {
        &.member-name {
          overflow: hidden;
          height: 20px;
          line-height: 20px;
          padding-bottom: 30px;

          span {
            color: $sec-text-color;
            font-size: 14px;

            &.user-total-cb,
            &.user-name {
              @include rakutenSansUIsemiBold;
              height: 20px;
              line-height: 20px;
              display: inline-block;
              white-space: nowrap;
              @include webkit-hack(1) { margin-top: 1px; }
            }

            &.user-total-cb {
              float: right;
              padding-left: 5px;
              .chevron-down-icon {
                margin-left: 6px;
                margin-top: -1px
              }
            }
            &.user-name {
              max-width: 125px;
              overflow: hidden;
              text-overflow: ellipsis;
              .user-icon {
                fill: $sec-text-color;
                margin-right: 8px;
                margin-bottom: 0;
                &:hover,
                &:focus {
                  fill: $brand-01-hover;
                }
              }
            }
          }

          &:hover *,
          &:focus * {
            color: $brand-01-hover;
            fill: $brand-01-hover;
          }
          &:hover,
          &:focus {
            .chevron-down-icon { transform: rotate(180deg); }
          }
        }
      }
      .dropdown {
        display: none;
        @include store-tile;
        position: absolute;
        top: 50px;
        right: 0;
        li {
          a {
            &:not(.button) {
              font-size: 14px;
              line-height: 14px;
              display: block;
              padding: 15px 30px;
              color: $tert-text-color;

              &:hover {
                color: $white-color;
                background-color: $brand-01-hover;
                text-decoration: none;
              }
            }
          }
          &:first-child a { @include round(8px 8px 0 0); }
          &:last-child a { @include round(0 0 8px 8px); }
          &.cash-pending {
            .pending-cb-amount {
              color: $brand-01;
              @include rakutenSansUIsemiBold;
            }
            &:hover * { color: $white-color; }
          }
        }
        .border-b { border-bottom:1px solid $darker-border-color; }
      }
      &:hover {
        .dropdown{
          display: block;
          z-index: 99999;
          width: 270px;
        }
      }
      > a,
      > a span { color: $white-color; font-size: 16px; }
    }
    &.logged-out {
      width: 235px;
      max-width: 235px;
      margin-top: 10px;
      float: right;
      @include padding-t(11px);
      text-align: right;
      .btn-join,
      .btn-sign-in { display: inline-block; }
      .button {
        min-width: 106px;
        @include round(30px);
        @include height(40px);
        @include line-height(40px);
        padding:0 20px;
      }
    }
    .button {
      &.eb-auth-link {
        @extend .button.ext.r4;
        @include round(30px);
        @include height(40px);
        @include line-height(40px);
        padding:0 20px;
        width: auto;
      }
    }
    a { &.eb-si-link { color:$white-color; } }
    .f-grn {
      color: $prim-text-color;
      &:hover { color: $gray-dark-8-color; }
    }
  }
  // User Menu Styles <<<<<

  span.ellips{text-overflow:ellipsis;width:auto;}

  .r-help {
    a {
      color: $white-color;
      font-size: 16px;
    }
  }
}
// .h-search <<<<<

// main-nav >>>>
.main-nav {
  position: relative;
  text-align: justify;
  &:before{content:"";display:block;width:100%;}
  &:after{content:"";display:inline-block;width:100%;}
  &.navtop {
    > li{
      display: inline-block;
      > a {
        @include rakutenSansUI(Rakuten Sans UI, 400);
        color: $tert-text-color;
        font-size: 15px;
        &:hover { color: $brand-01-hover; }
      }
    }
  }
  .tab{
    height: 48px;
    line-height: 52px;
    display: block;
    position: relative;
    padding-right: 12px;
    padding-left: 12px;
    .book-icon,
    .dollar-icon,
    .star-and-dots-icon,
    .help-icon,
    .hot-deals-icon,
    .in-store-icon,
    .referral-icon,
    .travel-icon {
      margin-right: 8px;
      margin-bottom: 2px;
      fill: $tert-text-color;
    }
    .chevron-down-icon {
      margin-left: 0;
      margin-top: -2px;
      fill: $tert-text-color;
    }
    &:hover,
    &:focus {
      color: $brand-01-hover;
      .book-icon,
      .chevron-down-icon,
      .dollar-icon,
      .star-and-dots-icon,
      .help-icon,
      .hot-deals-icon,
      .in-store-icon,
      .referral-icon,
      .travel-icon {
        fill: $brand-01-hover;
      }
      .chevron-down-icon { transform: rotate(180deg); }
    }
    &:active {
      &::after {
        content: "";
        position: absolute;
        background: $brand-01;
        border-radius: 8px 8px 0 0;
        bottom: -1px;
        left: 0;
        right: 0;
        height: 4px;
      }
    }
  }
}

#tab1 .main-nav #nav-tab1 > .tab span,
#tab2 .main-nav #nav-tab2 *,
#tab3 .main-nav #nav-tab3 *,
#tab4 .main-nav #nav-tab4 *,
#tab5 .main-nav #nav-tab5 *,
#tab6 .main-nav #nav-tab6 *,
#tab6 .main-nav #nav-tab6 > .tab span,
#tab7 .main-nav #nav-tab7 *,
#tab8 .main-nav #nav-tab8 *,
#tab1 .category-menu-trigger {
  color: $brand-01;
  .book-icon,
  .categories-icon,
  .chevron-down-icon,
  .dollar-icon,
  .star-and-dots-icon,
  .help-icon,
  .hot-deals-icon,
  .in-store-icon,
  .referral-icon,
  .travel-icon {
    fill: $brand-01;
  }
}
#tab1 .main-nav #nav-tab1,
#tab2 .main-nav #nav-tab2,
#tab3 .main-nav #nav-tab3,
#tab4 .main-nav #nav-tab4,
#tab5 .main-nav #nav-tab5,
#tab6 .main-nav #nav-tab6,
#tab7 .main-nav #nav-tab7,
#tab8 .main-nav #nav-tab8 {
  .tab {
    position: relative;
    &::after {
      content: "";
      position: absolute;
      background: $brand-01;
      border-radius: 8px 8px 0 0;
      bottom: -1px;
      left: 0;
      right: 0;
      height: 4px;
    }
  }
}
body {
  &[id^="tab"] { // Main Nav Higlighted Tab Links
    .main-nav {
      [id^="nav-tab"] {
        &.no-highlight {
          &:hover { color: $brand-01-hover; }
        }
      }
      &.navtop > li.travel > a { color: $brand-01-hover; }
    }
  }
}
// main-nav <<<<<

.landing-auth-header {
  .h-search {
    background: none;
    a {
      &.eb-auth-link,
      &.eb-si-link {
        font-size: 16px;
      }
    }
  }
}

.lifecycle { display: none !important; }

.rakuten-logo-white { // For use of white logo outside the header
  background-image: url("#{$static-path-rakuten}rakuten_nav_white.svg");
}
.rakuten-logo-gradient { // For use of gradient logo outside the header
  background-image: url("#{$static-path-rakuten}rakuten_nav_gradient.svg");
}
// Main Header <<<


/* // Temporarily commenting out BR-1943
.adDisclosureActive .header-wrapper {
  padding-bottom: 25px;
}
.adDisclosureActive .breadcrumbs-module { //Align the Ad Disclosure Module with breadcrumbs
  margin-top: -25px;
}
*/
