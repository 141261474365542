.border { @include border; }
.border-alt { @include border ( $border-color: $darker-border-color) ; }
.border-t{ @include border-top; }
.border-t-0{ @include border-top( $border-width: 0 ); }

.border-tr{border-top:1px solid $border-color;border-right:1px solid $border-color;}
.border-b{border-bottom:1px solid $border-color;}
.border-b-w{border-bottom:1px solid $white-color;}
.border-b-0{border-bottom:none;}
.border-b-only{border:none;border-bottom:1px solid $border-color;}
.border-br{border-right:1px solid $border-color;border-bottom:1px solid $border-color;}
.border-l{border-left:1px solid $border-color;}
.border-l-0{border-left:none;}
.border-lr{
  border-left: 1px solid $border-color;
  border-right: 1px solid $border-color;
}
.border-r{border-right:1px solid $border-color;}
.border-r-0,.bor-0-r{border-right:none;}
.border-0,.bor-0{border:none;}

.border-lte{ @include border( $border-color: $default-bg-color); }

.border-lte-b{border-bottom:1px solid $default-bg-color;}
.border-lte2{ @include border( $border-color: #d7d7d7) ; }
.border-lte3{ @include border( $border-color: #d7d7d7) ; }
.border-gry-3{@include border( $border-color: $prim-text-color);}
.border-lte3-t-2{border-top:2px solid $prim-text-color;}
.border-gry-6{ @include border( $border-color: $sec-text-color); }

.border-grn{ @include border( $border-color: $brand-01); }
.border-grn-t{ @include border-top( $border-color: $brand-01 );}
.border-grn-t-2{ @include border-top( $border-width: 2px, $border-color: $brand-01 ); }
.border-grn-t-5{ @include border-top( $border-width: 5px, $border-color: $brand-01 ); }
.border-grn-l-4{ @include border-left( $border-width: 5px, $border-color: $brand-01 ); }
.border-grn-b{ @include border-bottom( $border-width: 1px, $border-color: $brand-01 ); }
.border-grn-b-3 { @include border-bottom( $border-width: 3px, $border-color: $brand-01 ); }
.border-grn-b-4 { @include border-bottom( $border-width: 4px, $border-color: $brand-01 ); }
.border-grn-tb {border-top:1px solid $brand-01; border-bottom:1px solid $brand-01;}

.border-org{ @include border( $border-color: $cb-color); }
.border-w,.border-white{ @include border( $border-color: $white-color); }
.border-w2,.border-white-2,.border-white2{ @include border( $border-width: 2px, $border-color: $white-color); }

.border-err { @include border( $border-color: $error-color ); } //color:$error-color;

.border-black{ @include border( $border-color: $black-color); }
.border-t-black{ @include border-top( $border-color: $black-color); }

.border-b-black{border-bottom:1px solid $black-color;}
.border-cc-blue{@include border( $border-color: #017edb) ;}
.border-bonus-blu{ @include border( $border-color: $bonus-color );}

.border-pink{
  @include border( $border-color: $bday-color ); //birthday promo
}
.border-pink-t-3{
  @include border-top( $border-width: 3px, $border-color: $bday-color ) ; //birthday promo
}