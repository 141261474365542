@import "variables";
//$fonts-rakuten-ui-path: "//static.ebates.com/static/fonts/rakutenSansUI/";
//$fonts-stag-path: "//static.ebates.com/static/fonts/stag/";
$fonts-path: "/global_files/fonts/";         // #{$fonts-stag-path}

// http://cloud.typenetwork.com/projects/2583/fontface.css/
@font-face { // Rakuten Sans UI - Weight 500
  font-family: Rakuten Sans UI Semibold;
  font-weight: 500;
  font-style: Normal;
  src: url("#{$fonts-path}/rakutenSansUI/RakutenSansUI_W_Bd.woff2") format("woff2"),
  url("#{$fonts-path}/rakutenSansUI/RakutenSansUI_W_Bd.woff") format("woff");
}

@font-face { // Rakuten Sans UI - Normal - Weight 400
  font-family: Rakuten Sans UI;
  font-weight: 400;
  font-style: Normal;
  src: url("#{$fonts-path}/rakutenSansUI/RakutenSansUI_W_Rg.woff2") format("woff2"),
  url("#{$fonts-path}/rakutenSansUI/RakutenSansUI_W_Rg.woff") format("woff");
}

@font-face { // Rakuten Sans UI - Weight 300
  font-family: Rakuten Sans UI;
  font-weight: 300;
  font-style: Normal;
  src: url("#{$fonts-path}/rakutenSansUI/RakutenSansUI_W_Rg.woff2") format("woff2"),
  url("#{$fonts-path}/rakutenSansUI/RakutenSansUI_W_Rg.woff") format("woff");
}

.texgs-i { font-style: italic; }

/*
        This font software is the property of Commercial Type.

        You may not modify the font software, use it on another website, or install it on a computer.

        License information is available at http://commercialtype.com/eula
        For more information please visit Commercial Type at http://commercialtype.com or email us at info[at]commercialtype.com

        Copyright (C) 2018 Schwartzco Inc.
        License: 1807-RPUYFX
*/

// See the file /global_files/fonts/stag/1807-RPUYFX.css for all available Stag fonts if new ones are needed
// Static path when cross-origin gets resolved: //static/fonts/stag/

@font-face {
  font-family: 'Stag Light Web';
  src: url('#{$fonts-path}/stag/Stag-Light-Web.eot');
  src: url('#{$fonts-path}/stag/Stag-Light-Web.eot?#iefix') format('embedded-opentype'),
  url('#{$fonts-path}/stag/Stag-Light-Web.woff2') format('woff2'),
  url('#{$fonts-path}/stag/Stag-Light-Web.woff') format('woff');
  font-weight:  300;
  font-style:   normal;
  font-stretch: normal;
}
.Stag-Light-Web,
.prox-r .stag-light,
.prox-b .stag-light,
.stag-light {
  font-family: 'Stag Light Web';
  font-weight:  300;
  font-style:   normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'Stag Web';
  src: url('#{$fonts-path}/stag/Stag-Book-Web.eot');
  src: url('#{$fonts-path}/stag/Stag-Book-Web.eot?#iefix') format('embedded-opentype'),
  url('#{$fonts-path}/stag/Stag-Book-Web.woff2') format('woff2'),
  url('#{$fonts-path}/stag/Stag-Book-Web.woff') format('woff');
  font-weight:  400;
  font-style:   normal;
  font-stretch: normal;
}
.Stag-Book-Web,
.prox-r .stag-book,
.prox-b .stag-book,
.stag-book {
  font-family: 'Stag Web';
  font-weight:  400;
  font-style:   normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'Stag Medium Web';
  src: url('#{$fonts-path}/stag/Stag-Medium-Web.eot');
  src: url('#{$fonts-path}/stag/Stag-Medium-Web.eot?#iefix') format('embedded-opentype'),
  url('#{$fonts-path}/stag/Stag-Medium-Web.woff2') format('woff2'),
  url('#{$fonts-path}/stag/Stag-Medium-Web.woff') format('woff');
  font-weight:  500;
  font-style:   normal;
  font-stretch: normal;
}
.Stag-Medium-Web,
.prox-r .stag-med,
.prox-b .stag-med,
.stag-med {
  font-family: 'Stag Medium Web';
  font-weight: 500;
  font-style:  normal;
  font-stretch: normal;
}

@import "../partials/font-icons";

body {
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) { // Leave for now.
    // font-feature-settings: "liga", "kern";
    // -webkit-font-smoothing: antialiased;
    // -moz-osx-font-smoothing: grayscale;
  }
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// UI icons >>>
.arrow-right-icon,
.book-icon,
.cart-icon,
.categories-icon,
.chevron-down-icon,
.circle-plus-icon,
.close-icon,
.dollar-icon,
.star-and-dots-icon,
.gift-icon,
.hamburger-icon,
.help-icon,
.home-icon,
.hot-deals-icon,
.in-store-icon,
.referral-icon,
.search-icon,
.sign-out-icon,
.travel-icon,
.user-icon,
.information-icon {
  width: 16px;
  height: 16px;
  display: inline-block;
  vertical-align: middle;
  fill: $sec-text-color;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  &:hover,
  &:focus { fill: $brand-01-hover; }
  &:not(:root) { overflow: hidden; }
}
.circle-plus-icon {
  fill: $cb-color;
  margin-right: 4px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.information-icon {
  color: $sec-text-color;
  margin-left: 5px;
}
// UI icons <<<