html{
  font-size: $baseFontSize;
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) { font-size: $baseFontSizeIE; }
}
body{
  @include rakutenSansUI(Rakuten Sans UI, 400);
  @include background;
  font-size:$font-default;
  color:$prim-text-color;
  margin:0;
  // overflow-x: hidden;
}
body * {font-size:100%;}
#container{ padding:0; }
.container-fluid{ width: 100%; }
.container-fixed,
.container{
  width: 100%;
  margin: 0 auto;
  padding: 0;
}
.container-fixed {
  .row{
    display: block;
    clear: both;
  }
}

.blk-group,.blk,.blk-group:after,.blk:after,.blk-group:before,.blk:before{ @include border-box; }
.blk-group:before,.blk-group:after{display:table;content:"";line-height:0;}
.blk-group:after{clear:both;}
.blk-group{list-style-type:none;padding:0;margin:0;}
.blk-group>.blk-group{clear:none;float:left;margin:0 !important;}
.blk{float:left;width:100%;}
.blk-r{float:right;width:100%;}
.blk.no-flt{float:none;}

// NEW COLUMN GRIDS
.blk-group {
  width:100%;
  [class^="cols-"] .col,[class*=" cols-"] .col{margin-right:percentage(12 / 1170);}
  .cols .col{ @include width(percentage(1)); width:$max-width;}
  .cols-2 .col{ @include width(percentage(579 / 1170)); } // width:579px;
  .cols-3 .col{ @include width(percentage(382 / 1170)); } // width:382px;
  .cols-4 .col{ @include width(percentage(283.5 / 1170)); } // width:283.5px;
  .cols-5 .col{ @include width(percentage(224.4 / 1170)); } // width:224px;
  .cols-6 .col{ @include width(percentage(185 / 1170)); } // width:185px;
  .cols-8 .col{ @include width(percentage(135.75 / 1170)); } // width:136px;
  .cols-10 .col{ @include width(percentage(106.2 / 1170)); } // width:106px;
  .cols-12 .col{ @include width(percentage(86.5 / 1170)); } // width:86.5px;

  [class^="cols-"] .col.mar-center,[class*=" cols-"] .col.mar-center{float:none;margin:auto;}

  .aside{
    @include width(percentage(334 / 1170)); // width:334px;
    margin-right:percentage(10.01 / 1170);
    &.frt { margin-right:0; }
  }

  .main{
    @include width(percentage(825.5 / 1170)); // width:826px; // changed from 826/1170 to 825.5/1170 for EBCONTENT-19906
    .cols-2 .col{ @include width(percentage(407 / 826)); } // width:407px;
    .cols-3 .col{ @include width(percentage(267.33 / 826)); } // width:267.33px;
    .cols-4 .col{ @include width(percentage(197.5 / 826)); } // width:197.5px;
    [class^="cols-"] .col,[class*=" cols-"] .col{margin-right:percentage(11.66 / 802);}
    &.flt { margin-right:percentage(10.01 / 1170); }
  }
  .cols-2 .col:nth-child(2n+0),
  .cols-3 .col:nth-child(3n+0),
  .cols-4 .col:nth-child(4n+0),
  .cols-5 .col:nth-child(5n+0),
  .cols-6 .col:nth-child(6n+0),
  .cols-8 .col:nth-child(8n+0),
  .cols-10 .col:nth-child(10n+0),
  .cols-12 .col:nth-child(12n+0){margin-right:0;}

  .cols-4 .col-full{ @include width(percentage(874.5 / 1170)); } // width:874.5px; Used in Luxury All Stores
  .cols-4 .cols-3 .col{ @include width(percentage(382 / 1170)); } // width:382px; Used in Luxury All Stores
  .cols-4 .cols-3 .col:nth-child(4n){margin-right:1.02564%;} // Used in Luxury All Stores
  .cols-4 .cols-3 .col:nth-child(3n){margin-right:0;} // Used in Luxury All Stores
}
.stretch { width: 100%; }
// NEW COLUMN GRIDS

.flt{float:left;}
.frt{float:right;}
.flt-0{float:none;}

.top-0{top:0;}
.pos-fix,.fixed{position:fixed;}
.pos-rel,.rel,.relative{position:relative;}
.pos-abs,.absolute{position:absolute;}
.pos-abs.top0{top:0;}
.pos-abs-r{position:absolute;right:0;}
.pos-abs-l{position:absolute;left:0;}
.pos-abs.left0{left:0;}
.pos-stat{position:static;}

.v-center{position:relative;top:50%;-webkit-transform:translateY(-50%);-ms-transform:translateY(-50%);transform:translateY(-50%);}
.v-top,.valign-top{vertical-align:top;}
.v-middle,.valign-mid{vertical-align:middle;}
.v-mid-flex{display:flex;display:-webkit-flex;justify-content:center;flex-direction:column;}

.ofh, .overflow-0, .noscroll{ overflow: hidden; }
.overflow-auto{ overflow: auto; }
.scroll-x{ overflow-x: scroll; }
.scroll-y{ overflow-y: scroll; }
.noscroll-x{ overflow-x: hidden; }
.noscroll-y{ overflow-y: hidden; }

.clr,.clear{clear:both;}
.clearfix { @include clearfix; }
.b-box{ @include border-box; }

.inl{display:inline;}
.iblk,.i-icn{display:inline-block;}
.block,.bblk,.icn{display:block;}
.col-full{width:100%;}
.col-half{width:50%;}
.col-third{width:33%;width:33.33%;}
.col-fourth{width:25%;}
.col-two-third{width:66.6667%;}

.col-last {margin-right:0;}

.hide,.tablet-only{display:none;}

.ellipsis{
  text-overflow: ellipsis;
  width: auto;
  white-space: nowrap;
  overflow: hidden;
}
.block-ellipsis {
  display: block;
  display: -webkit-box;
  max-width:100%;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 1;
  max-height: 1.2em;
}
.line-clamp-2 {
  @extend .block-ellipsis;
  -webkit-line-clamp: 2;
  max-height: 2.4em;
}
.line-clamp-3 {
  @extend .block-ellipsis;
  -webkit-line-clamp: 3;
  max-height: 3.6em;
}
.line-clamp-4 {
  @extend .block-ellipsis;
  -webkit-line-clamp: 4;
  max-height: 4.6em;
}
.ellipsisfade {
  position: relative;
  max-height: 1.2em;
  overflow: hidden;
}
.ellipsisfade:after {
  content: "";
  text-align: right;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 40%;
  height: 1.2em;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 50%);
}
.ellipsisfade-2 {
  @extend .ellipsisfade;
  max-height: 2.4em;
}
.ellipsisfade-3 {
  @extend .ellipsisfade;
  max-height: 3.6em;
}
.ellipsisfade-2:after,
.ellipsisfade-3:after {
  @extend .ellipsisfade:after;
}
// FlexBox Grid | New Flex Grid being created in _grid-flex.scss