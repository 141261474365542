// Grid Layout
.grid-group {
  // https://caniuse.com/#feat=css-grid
  // NOT SUPPORTED FULLY BY IE 11
  display: -ms-grid;
  display: grid;
  float: left;
  &[class^="cols-"],
  &[class*=" cols-"] { grid-gap: 12px; }
  &.cols-5 {
    -ms-grid-columns: 1fr (12px 1fr)[5];
    grid-template-columns: repeat(5, [col-start] 1fr);
    .grid-col {
      min-width: 224px;
    }
  }
  &.cols-6 {
    -ms-grid-columns: 1fr (12px 1fr)[6];
    grid-template-columns: repeat(6, [col-start] 1fr);
    min-width: 185px;
  }
}
// FlexBox Grid
.flex,
.container-flex {
  @include flexbox();
  @include flex-flow ( row wrap );
  @include justify-content( flex-start );
  @include align-items( flex-start );
}
.flex,
.container-flex {
  // min-width: 320px;
  max-width: $max-width;
  // margin-left: auto;
  // margin-right: auto;
  &.row {
    @include flexbox();
    @include flex-direction( row );
    &.rev { @include flex-direction( row-reverse ); }
    &.cols,
    &.full {
      -webkit-box-flex: 0;
      -ms-flex: 0 1 100%;
      flex: 0 1 100%;
    }
    &.cols-2,
    &.half {
      -webkit-box-flex: 0;
      -ms-flex: 0 2 calc(50% - 12px);
      flex: 0 2 calc(50% - 12px);
    }
    &.cols-3,
    &.third {
      -webkit-box-flex: 0;
      -ms-flex: 0 1 calc(33.333333% - 12px);
      flex: 0 1 calc(33.333333% - 12px);
    }
    &.cols-4,
    &.fourth {
      -webkit-box-flex: 0;
      -ms-flex: 0 1 calc(25% - 12px);
      flex: 0 1 calc(25% - 12px);
    }
    &.cols-5,
    &.fifth {
      -webkit-box-flex: 0;
      -ms-flex: 0 1 calc(20% - 12px);
      flex: 0 1 calc(20% - 12px);
    }
    &.cols-6,
    &.sixth {
      -webkit-box-flex: 0;
      -ms-flex: 0 1 calc(16.666666% - 12px);
      flex: 0 1 calc(16.666666% - 12px);
    }
  }
  &.col {
    @include flex-direction( column );
    &.rev { @include flex-direction( column-reverse ); }
  }
}

// To align along the Main Axis (Horizontally)
.just-start { @include justify-content( flex-start ); } // default // The class .row in .container-flex is default so if using .row, no need to use .just-start
.just-end { @include justify-content( flex-end ); }
.just-center { @include justify-content( center ); }
.just-around { @include justify-content( space-around ); } // space around inner children.
.just-between { @include justify-content( space-between ); } // space between inner children.
.just-even { @include justify-content( space-evenly ); } // space between inner children.

// To align along the Cross Axis (Vertically)
.al-start { @include align-items( flex-start ); } // default // align vertically to top of row
.al-end { @include align-items( flex-end ); } // align vertically to bottom of row
.al-center { @include align-items( center ); } // align vertically to middle of row
.al-stretch { @include align-items( stretch ); } // stretch vertically to fit a row's height
.al-base { @include align-items( baseline ); } // align vertically to the baseline (used sparingly to align to baseline of larger font items)

// Flex Line Wrapping
.wrap { @include flex-wrap( wrap ); } // default
.wrap-no { @include flex-wrap( nowrap ); }
.wrap-rev { @include flex-wrap( wrap-reverse ); }

.flow{ @include flex-flow ( row wrap ); }
.flow-rev{ @include flex-flow ( row wrap-reverse ); }
.flow-nowrap{ @include flex-flow ( row nowrap ); }

// Align Self:
.self-stretch { @include align-self( stretch ); } // Used to align shorter siblings of stubborn child to the height of the stubborn when manu rows have stubborns
.self-align-end { @include align-self( flex-end ); }

.flex-1{ @include flex( 1 ); }

// Flex Grow
.flex-grow-1{ @include flex-grow( 1 ); } // We can include flex-grow in any class and increase if needed
.flex-grow-2{ @include flex-grow( 2 ); }
.flex-grow-3{ @include flex-grow( 3 ); }
.flex-grow-4{ @include flex-grow( 4 ); }
.flex-grow-5{ @include flex-grow( 5 ); }

// Flex Shrink
// by default, Flesh Shrink is set to 1. If set to 0, there should be no shrink, but may overflow the container.
.flex-shrink-0{ @include flex-shrink( 0 ); } // will set a set of divs to not shrink and exceed the boundaries when used with nowrap. Useful in Flex Carousels
.flex-shrink-1{ @include flex-shrink( 1 ); } // Default
.flex-shrink-2{ @include flex-shrink( 2 ); }

// Flex Basis
.flex-basis-auto{ // use this to make the default flex basis regardless of original pixels. The auto here should be overwritten to control full parent column widths.
  @include flex-basis( auto );
}
.flex-basis-fit-content{
  @include flex-basis( fit-content );
}

.flex-grid {
  display: flex;
  .cell {
    flex: 1; /* shortcut for flex-frow 1, flex-shrink 1 and basis 0% */
    > div { height: 100%; }
  }
  &.gutters {
    margin: -10px 0 10px -12px;
    > .cell,
    > div{
      padding: 10px 0 0 12px;
    }
  }
  .half { flex-basis: 50%; }
  .third { flex-basis: 33.33333%; }
  .fourth { flex-basis: 25%; }
  .fifth { flex-basis: 20%; }
  .sixth { flex-basis: 16.6666%; }
  .eighth { flex-basis: 12.5%; }
  .tenth { flex-basis: 10%; }
  .twelfth { flex-basis: 8.33333%; }
}

.flex-vcenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

// FlexBox Grid