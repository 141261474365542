@function sum($left, $right) {
  @return $left + $right
}

// Grid Functions >>>
@function col-width($columns: 12, $page-width: 100%, $gap: 1.0256410256%) {
  @return ($page-width - $gap*($columns - 1)) / $columns;
}
// Grid Functions <<<

@function strip-unit($value) {
  @return $value / ($value*0 + 1);
}

// Font Size Functions >>>
@function font-em($pixel, $context: 14px) {
  @return ($pixel / $context) * 1em;
}
@function font-rem($pixel, $context: 14px) {
  @return ($pixel / $context) * 1rem;
}
@function font-percent($pixel, $context: 14px) {
  @return ($pixel / $context) * 100%;
}
// Font Size Functions <<<