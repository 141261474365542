// Background Colors >>>
.bg-0{background:transparent;}

.ui-01,
.bg-dark,
.bg-gry-3{
  @include background($prim-text-color);
  &.hover {
    @extend .cursor;
    &:hover{ @include background($prim-text-color-hover); }
  }
} // .bg-gry-3 to be deprecated

.ui-02,
.bg-gry-c{ @include background($darker-border-color); }

.ui-03,
.bg-gry-d { @include background($gray-dd-color); }

.ui-04,
.bg-gry { @include background($default-bg-color); }

.bg-sec,.bg-gry-6{ @include background($sec-text-color); }
.bg-tert,.bg-gry-8,.bg-gry-dk-8{ @include background($gray-dark-8-color); }
.bg-hint{ @include background($hint-color); }
.bg-ex-lte{ @include background($extra-light-gray); }

.bg-int,.bg-grn,.grn-bar{ @include background($brand-01); }
.bg-int.hover{
  @extend .cursor;
  &:hover{ @include background($brand-01-hover); }
}
.brand-03{ @include background($brand-03); }
.brand-04{ @include background($brand-04); }

.bg-org,
.cb-color-bg,
.bg-ext{
  @include background($cb-color);
  &.hover {
    @extend .cursor;
    &:hover{ @include background($cb-color-hover); }
  }
}


.bg-sub-heder{ @include background($sub-header-color); }
.bg-gry2,.bg-gry-2{ @include background($gray-de-color); }
.bg-gry-f6{ @include background($gray-f6-color); }
.bg-grf{ @include background(#363636); }
.bg-black{ @include background($black-color); }
.blk-reg,.bg-reg,.bg-w,.white-bg{ @include background($white-color); }
.bg-blu{ @include background(#017edb); }
.bg-bonus,
.bg-blu-bonus,
.bg-blu2 // do not use bg-blu2
{ @include background($bonus-color); } // Some instances use #43aed2 and that should be removed
.bg-blu-disable{ @include background(#9ebce2); }
.bg-cred,.bg-cc-blue{ @include background($cc-color); }
.bg-yel{ @include background($yellow-color); }
.bg-err{ @include background($error-color); }
.bg-pink{ @include background($bday-color); } // birthday promo
.bg-4u,.bg-gold{ @include background($just-for-you-color); } // Just For You Bonus On Dark BG
.bg-4u-dk{ @include background($just-for-you-color-dark); } // Just For You Bonus On Light BG
.bg-fb{ @include background($social-facebook-color); }
.bg-fb.hover{
  @extend .cursor;
  &:hover{ @include background($social-facebook-color-hover); }
}
.bg-goog{ @include background($social-google-color); }
.bg-goog.hover{
  @extend .cursor;
  &:hover{ @include background($social-google-color-hover); }
}
.bg-twitter{
  @include background($social-twitter-color);
  &.hover{
    @extend .cursor;
    &:hover{ @include background($social-twitter-color-hover); }
  }
}
.dark-green-bg{ @include background(#060); } // legacy - to review and remove

.bg-peach { @include background($color-peach); }
.bg-indigo { @include background($color-indigo); }
.bg-teal { @include background($color-teal); }

.bg-brand-gradient { background: $color-brand-gradient; }
.bg-brand-alt-gradient { background: $color-brand-alt-gradient; }

// Background Colors <<<
