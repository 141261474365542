// SCSS Experimental >>>
// .button.ext.dark:hover{ @include background( darken($cb-color, 10%) ); }
// .button.ext.light:hover{ @include background( lighten($cb-color, 20%) ); }

// .responsive{
//   [class*="col-"]{float:left;}
//   .col-1{width: 8*$col;}
//   .col-2{width: 4*$col;}
//   .col-3{width: 4*$col;}
// }

// For loop using "through" goes iterates and returns all
// In this example 1 to 12 will be output
// @for $cols from 1 through 12 {
//   .cols#{$cols} {
//     width: $cols * 7.3931623932%;
//   }
// }

// For loop using "to" goes iterates and returns all but the last one
// In this example 1 to 11 will be output
// @for $cols2 from 1 to 12 {
//   .cols2#{$cols2} {
//     width: $cols2 * 7.3931623932%;
//   }
// }

// An each (foreach) loop used for generating a list of image classes as an example.
// Note that the name of the class and image must match.
// $svgs: svg-eb-logo, svg-eb-logo2, targ-cb-lte, targ-cb-drk, in-store-logo, ebates-e;
// @each $svg in $svgs {
//   .#{$svg} {
//    background-image:url("#{$fonts-path}svg/#{$svg}.svg");background-repeat:no-repeat;background-size:100%;
//   }
// }

// An each (foreach) loop used for generating based on key:value pairs.
$font-sizes: (tiny: 8px, small: 11px, medium: 13px, large: 18px);
@each $name, $size in $font-sizes {
  .#{$name} {
    font-size: $size;
  }
}

// While loops
$f: 2;
@while $f <= 8 {
  .picture-#{$f} {
    width: $f * 10%;
  }
  $f: $f + 2;
}
// SCSS Experimental <<<
