
.tab-nav-target{}

// New Global Tabbed Navigation.
.nav-tabs,
.tabnav {
  border-bottom: 1px solid $border-color;
  &.tab-less { border-bottom: none; }
  li {
    width: auto;
    float: left;
    text-transform: uppercase;
    &.travel-tab-selected { width: 220px; } // For Travel Category
    a,
    > span {
      font-size: 14px;
      line-height: 18px;
      color: $gray-dark-8-color;
      letter-spacing: 0.05rem;
      padding: 21px 30px;
      height: 60px;
      &.active {
        color: $prim-text-color;
        @include border-bottom( $border-width: 2px, $border-color: $prim-text-color );
      }
    }
    &.current {
      &.no-tab { border-bottom: none; text-transform: unset; }
      a {
        height: 60px;
        color: $prim-text-color;
        @include border-bottom( $border-width: 2px, $border-color: $prim-text-color );
      }
    }
  }
  &.scroll-module {
    height: 61px;
    li { float: none; }
  }
}