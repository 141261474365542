.round{ @include round(10px); }
.roundtop{ @include round(10px 10px 0 0); }
.roundtop-4{ @include round(4px 4px 0 0); }
.roundtop-l{ @include round(10px 0 0 0); }
.roundtop-r{ @include round(0 10px 0 0); }
.roundbottom, .roundfoot{ @include round(0 0 10px 10px); }
.roundright{ @include round(0 10px 10px 0); }
.roundleft{ @include round(10px 0 0 10px); }
.round-big,.round-20{ @include round(20px); }

.round3,.round-3{ @include round; }
.round3-l,.round-3-l{ @include round(3px 0 0 3px); }
.round3-r,.round3-right,.round-3-r,.round-3-right{ @include round(0 3px 3px 0); }
.round3-t,.round-3-t{ @include round(3px 3px 0 0); }
.round3-b,.round-3-b{ @include round(0 0 3px 3px); }

.round3-r-0{border-bottom-right-radius:0 !important;}
.round3-l-0{border-bottom-left-radius:0;}

.round5,.round-5{ @include round(5px); }
.round5-r,.round-5-r{ @include round(0 5px 5px 0); }
.round5-l,.round-5-l{ @include round(5px 0 0 5px); }
.round6, .round-6{ @include round(6px); }
.round8,.round-8{ @include round(8px); }
.rounded-image{ @include round(8px); }

.round30,.round-30{ @include round(30px); }
.round40,.round-40{ @include round(40px); }
.round46,.round-46{ @include round(46px); }
.roundbottomright{ @include round(0 0 10px 0); }
.circle{ @include round(50%); }

.round40-r { @include round(0 40px 40px 0); }
.round40-l { @include round(40px 0 0 40px); }

.bg-hover {
  @include transition( box-shadow 0.2s );
  @include transition-timing-function( ease-in-out );
  &:hover {
    @include box-shadow(0 8px 14px -6px rgba(153,153,153,0.65)); // Used by the Home Page Hovers
  }
}
.shadow-1 {
  @include box-shadow(0 8px 14px -6px rgba(153,153,153,0.65)); // Used by the Home Page Hovers
}
.shadow-2 {
  @include box-shadow(0 2px 2px 0 rgba(0,0,0,0.2));
}
.shadow-3 {
  @include box-shadow(0 3px 5px 0 rgba(0,0,0,0.4)); // Used by back to top, help button
  &:hover{
    @include box-shadow(0 3px 5px 0 rgba(0,0,0,0.2));
  }
}
.shadow-4 {
  @include box-shadow(0 4px 8px 0 rgba(10,22,70,0.15));
}
.shadow-5 {
  @include box-shadow(0 2px 4px 0 rgba(0,0,0,0.23));
}
.shadow-6 { // used by MW Home Page Amazon and Boosted Modules
  @include box-shadow(0 1px 4px 0 rgba(0,0,0,0.23));
}

.top-shadow {
  -webkit-box-shadow: 0 0 4px 0 rgba(153,153,153,.5);
  -moz-box-shadow: 0 0 4px 0 rgba(153,153,153,.5);
  box-shadow: 0 0 4px 0 rgba(153,153,153,.5);
}

// Extend Only Selector Example >>>
%opacity-0{opacity:0;}
// Extend Only Selector Example <<<

@for $op from 0 to 11 {
  .op#{$op} {
    opacity: $op * 0.1;
  }
}

.rating {
  display: inline-block;
  position: relative;
  &::before {
    content: "\f006\f006\f006\f006\f006";display:block;
  }
  span {
    position: absolute;
    top: 1px;
    left: 0;
    overflow: hidden;
    &::before {
      content:"\f005\f005\f005\f005\f005";
      display: block;
    }
  }
}

.table-blk {
  display: table;
  width: 100%;
  height: 100%;
  .table-row { display: table-row; }
  .table-cell {
    display: table-cell;
    // text-align: center;
    &.al-top { vertical-align: top; }
    &.al-mid { vertical-align: middle; }
    &.al-bot { vertical-align: bottom; }
  }
}