.store-hero {
  .dd-cb {
    display: block;
    @include store-tile;
    @include round(5px);
    position: absolute;
    top: 15px;
    left: 16px;
    a { line-height: 27px; height: 27px; }
    .cb { font-size: 14px; }
  }
  .store-hero-image {
    @include store-tile;
    box-shadow: 0 4px 8px 0 rgba(10,22,70,0.05);
    img { margin: 0 auto; }
  }
}