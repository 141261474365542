// Store Left Side <<<<<
.store-left {
  .cb {
    &.f-16{ font-size: 16px; }
  }

  .arrow-cb-sm.arr-right,.arrow-black.arr-right { //Overwrite with CSS rules from font-awesome, will change layout once r4 go to 100%
    display: inline-block;
    font-family: 'fontawesome-webfont' !important;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    speak: none;
    border-style: hidden;
    &:before{
      content: "\f105";
      color: $cb-color;
    }
  }

  .arrow-black.arr-right {
    &:before{
      content: "\f105";
      color: $black-color;
    }
  }

  // Store Left Side Favorites Block >>>
  .faves-blk {
    text-align: center;
    position: relative;
    .faves-circ {
      display: block;
      position: absolute;
      height: 42px;
      width: 42px;
      top: -11px;
      left: 13px;
      z-index: 2;
      background-color: $white-color;
      @include round(40px);
      @include border(1px);
    }
    .hero-faves {
      display: block;
      position: absolute;
      top: 1px;
      left: 13px;
      height: 42px;
      width: 42px;
      z-index: 4;
      background-color: $white-color;
      @include border($border-color: $white-color);
      ul {
        height: 42px;
        width: 42px;
        line-height: 42px;
        display: block;
        font-size: 17px;
        position: absolute;
        top: -12px;
        li {
          height: 42px;
          width: 42px;
          line-height: 42px;
          display: block;
          padding: 0;
          &.hero-fav {
            @include round(40px);
            .fav-lg {
              cursor: pointer;
              font-size: 20px;
              text-align: center;
              margin: 8px 0 0 0;
              display: block;
              width: 42px;
              height: 42px;
              padding: 0;
              span {
                font-size: 9px;
                display: none;
                line-height: 42px;
                width: 42px;
                height: 42px;
                position: absolute;
                top: -5px;
              }
              &:hover {
                span { display: block; }
              }
              &.off {
                color: $border-color;
                &:hover {
                  color: $cb-color;
                  span {
                    top: -4px;
                    color: $border-color;
                    &:before { content: "\f067"; }
                  }
                }
              }
              &.on {
                color: $cb-color;
                &:hover {
                  color: $border-color;
                  span {
                    color: $cb-color;
                    &:before { content: "\f00d"; }
                  }
                }
              }
            }
          }
        }
      }
      .fav-message {
        font-size: 14px;
        @include store-tile;
        @include round(5px);
        display: none;
        border: none;
        position: absolute;
        top: -40px;
        left: 2px;
        padding: 0 12px;
        height: 25px;
        line-height: 25px;
        width: 150px;
        &.arrow-box {
          &:after,
          &:before{ left: 22px; }
        }
        &.fav-message-show { display: block !important; } // Not sure if .fav-message-show is used still. Will check after refactor
      }
    }
    .cb-amounts {
      position: relative;
      z-index: 3;
      li {
        display: block;
        width: 100%;
        .lg-img,
        .sm-img{
          padding-top: 10px;
          image-rendering: auto;
        }
        &.sub-offer{
          color: $gray-dark-8-color;
          font-size: 14px;
          height: 18px;
          line-height: 18px;
          &.mar-10-b { margin-bottom: 10px; }
        }
        &.cb-was {
          margin-top: 15px;
          line-height: 18px;
        }
        &.cb-blk {
          padding-top: 15px;
          margin-bottom: 20px;
          &.mobile-only { margin-bottom: 10px; }
          .cb {
            display: block;
            font-size: 24px;
            line-height: 28px;
            height: 28px;
            white-space: nowrap;
            &.mar-10-b { margin-bottom: 10px; }
          }
        }
      }
    }
  }
  // Store Left Side Favorites block <<<
  .cb-facts {
    .cb-facts-amt {
      @include rakutenSansUIsemiBold;
      font-size: 20px;
      margin-bottom: 5px;
    }
    .cb-facts-desc {
      @include rakutenSansUIsemiBold;
      font-size: 16px;
      &:not(.last) {
        margin-bottom: 20px;
      }
    }
  }

  .store-info {
    .merch-desc {
      margin-bottom: 10px;
      line-height: 18px;
    }
  }

  .in-store {
    text-align: center;
    .in-store-cb {
      @include rakutenSansUIsemiBold;
      font-size: 24px;
      line-height: 28px;
    }
    .in-store-cb-prev {
      line-height: 14px;
      display: block;
      clear: both;
      color: $gray-dark-8-color;
      margin: 5px 0 10px 0;
    }
    .button { width: 250px; }
  }

  // Store Left Side Shopping Secrets block >>>
  .mkt-ops {
    .shop-secret {
      p {
        margin-bottom: 15px;
        &.shop-txt {
          display: block;
          @include rakutenSansUIsemiBold;
          color: $sec-text-color;
          font-size: 16px;
          line-height: 20px;
          margin-bottom: 0;
          .cb {
            display: block;
            @include rakutenSansUIsemiBold;
          }
        }
      }
    }
  }
  // Store Left Side Shopping Secrets block <<<

  // Store Left Side Available Offers block >>>
  .offers-available {
    ul {
      width: 100%;
      li {
        width: 100%;
        height: 30px;
        line-height: 30px;
        @include flexbox();
        @include flex-direction( row );
        @include justify-content( space-between );
        .offer-amt { text-align: right; }
        &.offer-totals { @include rakutenSansUIsemiBold; }
      }
    }
  }
  // Store Left Side Available Offers block <<<

  // Store Left Side Tiered Stores Categories block >>>
  .cb-categories {
    ul {
      padding-top: 5px;
      li {
        width: 100%;
        clear: both;
        padding-top: 5px;
      }
    }
  }
  // Store Left Side Tiered Stores Categories block <<<

  // Store Left Side Related Posts block >>>
  .related-posts {
    li {
      color: $sec-text-color;
      line-height: 18px;
      &:not(:last-child) {
        margin-bottom: 15px;
      }
    }
  }
  // Store Left Side Related Posts block <<<

  .aside-advt { // Ads used in pages like Amazon
    padding-right: 15px;
    padding-left: 15px;
    a {
      display: block;
      width: 100%;
      img { margin: 0 auto; }
    }
  }

  .related-stores {
    li {
      a { color: $prim-text-color; }
      &:not(:last-child) {
        padding-bottom: 5px;
      }
      span {
        display: block;
        clear: both;
      }
    }
  }
}
// Store Left Side <<<<<