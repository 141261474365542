/* tooltips */
.tooltip-load {
  @include tooltip-load();
}
.tooltip {
  @include zindex( 100001 );
  padding: 20px;
  @extend .shadow-4;
}
// For creating new tooltips, include the tooltip Mixin and modify the values as needed.
// @include tooltip($size, $direction, $bgcolor, $bordercolor);
// $size = Size of Tooltip Arrow
// $direction = Direction of Arrow. Ex: top points up and is displayed below the target
// $bgcolor = Color of Tooltip Background. Use Hex, RGBA or transparent for background-less (not that we'll evver need that)
// $bordercolor = Color of Tooltiop and Arrow Color. Use Hex, RGBA or none for border-less
.tooltip-top{
  @include tooltip(10px, top, rgba(255, 255, 255, 1), $darker-border-color);
}
.tooltip-bottom{
  @include tooltip(10px, bottom, rgba(255, 255, 255, 1), $darker-border-color);
}
.tooltip-left{
  @include tooltip(10px, left, rgba(255, 255, 255, 1), $darker-border-color);
}
.tooltip-right{
  @include tooltip(10px, right, rgba(255, 255, 255, 1), $darker-border-color);
}
.tooltip-click-load {
  @include tooltip-click-load();
}
.tooltip-show{visibility:visible;opacity:1;}


// BELOW STYLES ARE LEGACY AND NOT INCLUDED IN THE STYLE GUIDE
.tooltip-trigger{display:inline-block;}
.tooltip-trigger:hover .tooltip-container{visibility:visible;}
.tooltip-container{
  visibility:hidden;
  z-index:999;
  @include border( $border-color: rgba(153,153,153,0.20) );
}
.tooltip-container.show{visibility:visible;}
.tooltip-container:after, .tooltip-container:before{border:solid transparent;content:" ";height:0;width:0;position:absolute;/*pointer-events:none;*/}
.tooltip-container:after{border-color:rgba(255, 255, 255, 0);border-width:10px;}
.tooltip-container:before{border-color:rgba(153,153,153,0);border-width:11px;}
/* bottom arrow tooltip */
.tooltip-container.arrow-b{bottom:150%;left:50%;margin-left:-60px;}
.tooltip-container.arrow-b:after,
.tooltip-container.arrow-b:before{top:100%;left:50%;}
.tooltip-container.arrow-b::after{margin-left:-10px;top:100%;left:50%;border-top-color:$white-color;}
.tooltip-container.arrow-b:before{border-top-color:rgba(153,153,153,0.65);margin-left:-11px;}
/* top arrow tooltip */
.tooltip-container.arrow-t{top:150%;left:50%;margin-left:-60px;}
.tooltip-container.arrow-t:after,
.tooltip-container.arrow-t:before{bottom:100%;left:50%;}
.tooltip-container.arrow-t::after{margin-left:-10px;bottom:100%;left:50%;border-bottom-color:$white-color;}
.tooltip-container.arrow-t:before {border-bottom-color:rgba(153,153,153,0.20);margin-left:-11px;}
/* left arrow tooltip */
.tooltip-container.arrow-l{top:-5px;left:110%;}
.tooltip-container.arrow-l:after,
.tooltip-container.arrow-l:before{right:100%;top:50%;}
.tooltip-container.arrow-l::after{margin-top:-10px;top:50%;right:100%;border-right-color:$white-color;}
.tooltip-container.arrow-l:before{border-right-color:rgba(153,153,153,0.65);margin-top:-11px;}
/* right arrow tooltip */
.tooltip-container.arrow-r{top:-5px;right:110%;}
.tooltip-container.arrow-r:after,
.tooltip-container.arrow-r:before{left:100%;top:50%;}
.tooltip-container.arrow-r::after{margin-top:-10px;top:50%;left:100%;border-left-color:$white-color;}
.tooltip-container.arrow-r:before{border-left-color:rgba(153,153,153,0.65);margin-top:-11px;}


// legacy copy to clipboard tooltips
.copy-coupon-code-tooltip{
  @include round(8px);
  display:none;
  height:15px;
  position:absolute;
  z-index:100001;
  min-width:80px;
  padding-left:15px;
  padding-right:15px;
  margin-left:7px;
  margin-top:10px;
  cursor: pointer;
  &:after,
  &:before{
    right:calc(50% - 9px);
    top:-16px;
    border:solid transparent;
    content:"";
    height:0;
    width:0;
    position:absolute;
    border-bottom-color: $brand-01;
  }
  &:after{border-width:8px}
  &:before{border-width:10px;margin-top:-4px}
}

.copy-coupon-code-tooltip.expand{margin-left:-24px!important;}
span.coupon-code:hover .copy-coupon-code-tooltip{display:block;}

.copy-coupon-code-tooltip.v2{left:auto!important;margin-left:auto;}
.copy-coupon-code-tooltip.expand.v2{left:auto!important;margin-left:-30px;}