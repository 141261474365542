
.blk0 {
  @include width(percentage(1));
  width:$max-width;
  .col1{ @include width(percentage(1)); }
  .col2{ @include width(percentage(579 / 1170)); }
  .col3{ @include width(percentage(382 / 1170)); }
  .col3.col_2_3{
    @include width(percentage(776 / 1170)); // width:776px;
  }
  .col4{ @include width(percentage(283.5 / 1170)); }
  .col4.col_3_4{
    // width:874px;
    @include width(percentage(874.5 / 1170));
  }
  .col5{ @include width(percentage(224.4 / 1170)); }
  .col5.col_2_5{
    // width:461px;
    @include width(percentage(460.8 / 1170));
  }
  .col5.col_3_5{
    // width:698px;
    @include width(percentage(697.2 / 1170));
  }
  .col5.col_4_5{
    // width:934px;
    @include width(percentage(933.6 / 1170));
  }
  .col5-full{width:234px;width:20%;}
  .col6{ @include width(percentage(185 / 1170)); }
  .col6:nth-child(6n+0){margin-right:0;}
  .m-feature-store-grid:last-of-type {margin-right:0}
  .col6.col_5_6{
    // width:973px;
    @include width(percentage(973 / 1170));
  }
  .col8{ @include width(percentage(135.75 / 1170)); }
  .col10{ @include width(percentage(106.2 / 1170)); }
  .col12{ @include width(percentage(86.5 / 1170)); }
}
.col2,.col3,.col3_2_3,.col4,.col5,.col6,.col8,.col10,.col12{
  // margin-right:12px;
  margin-right:percentage(12 / 1170);
}

.blk1{
  // width:826px;
  @include width(percentage(826 / 1170));
  // margin-right:10px;
  margin-right:percentage(10 / 1170);
  .col1{ @include width(percentage(1)); }
  .col2{
    // width:406px;
    @include width(percentage(406/ 826));
  }
  .col3{width:266px;}
  .col4{
    // width:196px;
    @include width(percentage(196/ 826));}
  .col5{width:158px;}
  .col6{width:130px;}
  .col2, .col3, .col4, .col5, .col6{
    // margin-right:14px;
    margin-right:percentage(14 / 826);
  }
}

.blk2{
  // width:334px;
  @include width(percentage(334 / 1170));
  .col2{width:50%;}
}

// Used in legacy landing pages, do not use for regular pages >>>
.blk0.pad-20 {
  width:1130px;
  .col1{width:1130px;width:100%;}
  .col2{width:559px;}
  .col3{width:368px;}
  .col3.col_2_3{width:748px;}
  .col4{width:273px;}
  .col4.col_3_4{width:843px;}
  .col5{width:216px;}
  .col5.col_2_5{width:444px;}
  .col5.col_3_5{width:672px;}
  .col5.col_4_5{width:900px;}
  .col6{width:178px;}
  .col6.col_5_6{width:938px;}
}
// Used in legacy landing pages, do not use for regular pages <<<

.blk1.pad-20 .col2{width:386px;}


.arrow,.arrow-sm,.arrow,.arrow-grn,.arrow-grn-sm,.arrow-grn-15,.arrow-big,.arrow-lg,.triangle,.arrow-cb,.arrow-cb-sm,.arrow-cb-lg,.arrow-cb-6,.arrow-black,.arrow-black-7,.arrow-sm-black{display:inline-block;width:0;height:0;}
.arrow-sm.arr-up{border-left:4px solid transparent;border-right:4px solid transparent;border-bottom:4px solid $white-color;}
.arrow-sm.arr-down{border-left:4px solid transparent;border-right:4px solid transparent;border-top:4px solid $white-color;}
.arrow-sm.arr-right{border-top:4px solid transparent;border-bottom:4px solid transparent;border-left:4px solid $white-color;}
.arrow-sm.arr-right.green{border-left:5px solid $brand-01;}
.arrow-sm.arr-left{border-top:4px solid transparent;border-bottom:4px solid transparent;border-right:4px solid $white-color;}
.arrow.arr-up.triangle.tri-up{border-left:5px solid transparent;border-right:5px solid transparent;border-bottom:5px solid $white-color;}
.arrow.arr-down,.triangle.tri-down{border-left:5px solid transparent;border-right:5px solid transparent;border-top:5px solid $white-color;}
.arrow.arr-right,.triangle.tri-right{border-top:5px solid transparent;border-bottom:5px solid transparent;border-left:5px solid $white-color;}
.arrow.arr-left,.triangle.tri-left{border-top:5px solid transparent;border-bottom:5px solid transparent;border-right:5px solid $white-color;}
.arrow-cb.arr-right{border-top:5px solid transparent;border-bottom:5px solid transparent;border-left:5px solid $cb-color;}
.arrow-cb-sm.arr-right{border-top:4px solid transparent;border-bottom:4px solid transparent;border-left:4px solid $cb-color;}
.arrow-cb-lg.arr-right{border-top:8px solid transparent;border-bottom:8px solid transparent;border-left:8px solid $cb-color;}
.arrow-cb-6.arr-right{border-top:6px solid transparent;border-bottom:6px solid transparent;border-left:6px solid $cb-color;}
.arrow-black.arr-down{border-left:5px solid transparent;border-right:5px solid transparent;border-top:5px solid $black-color;}
.arrow-black.arr-right{border-top:5px solid transparent;border-bottom:5px solid transparent;border-left:5px solid $black-color;}
.arrow-black.arr-up{border-left:5px solid transparent;border-right:5px solid transparent;border-bottom:5px solid $black-color;}
.arrow-sm-black.arr-up{border-left:4px solid transparent;border-right:4px solid transparent;border-bottom:4px solid $black-color;}
.arrow-sm-black.arr-down{border-left:4px solid transparent;border-right:4px solid transparent;border-top:4px solid $black-color;}
.arrow-sm-black.arr-right{border-top:4px solid transparent;border-bottom:4px solid transparent;border-left:4px solid $black-color;}
.arrow-black-7.arr-down{border-left:7px solid transparent;border-right:7px solid transparent;border-top:7px solid $black-color;}
.arrow-lg.arr-down{border-left:8px solid transparent;border-right:8px solid transparent;border-top:8px solid $white-color;}
.arrow-grn.arr-right{border-top:5px solid transparent;border-bottom:5px solid transparent;border-left:5px solid $brand-01;}
.arrow-grn.arr-down{border-left:5px solid transparent;border-right:5px solid transparent;border-top:5px solid $brand-01;}
.arrow-grn-15.arr-down{border-left:15px solid transparent;border-right:15px solid transparent;border-top:15px solid $brand-01;}
.arrow-grn-sm.arr-right{border-top:4px solid transparent;border-bottom:4px solid transparent;border-left:4px solid $brand-01;}
.arrow-box{border:1px solid #d7d7d7;}
.arrow-box:after,.arrow-box:before{top:100%;/*left:50%*/;border:solid transparent;content:" ";height:0;width:0;position:absolute;pointer-events:none;}
.arrow-box:after{border-color:rgba(255, 255, 255, 0);border-top-color:$white-color;border-width:8px;margin-left:-12px;}
.arrow-box:before{border-color:rgba(215, 215, 215, 0);border-top-color:#d7d7d7;border-width:9px;margin-left:-13px;}

.dis-tbl{display:table;/*<table>*/}
.dis-tbl-thead{display:table-header-group;/*<thead>*/}
.dis-tbl-body{display:table-row-group;/*<body>*/}
.dis-tbl-colgroup{display:table-column-group;/*<colgroup>*/}
.dis-tbl-foot{display:table-footer-group;/*<foot>*/}
.dis-tbl-caption{display:table-caption;/*<caption>*/}
.dis-tbl-tr{display:table-row;/*<tr>*/}
.dis-tbl-td{display:table-cell;/*<td>*/}
.dis-tbl-td-mid{display:table-cell;vertical-align:middle;}
.dis-tbl-col{display:table-column;/*<col>*/}