/* SCSS Document */
@import "../partials/functions";
@import "../partials/variables";
@import "../partials/mixins";
.int-wrap{
  margin: 70px auto 25px auto;
  padding: 0;
  width: 600px;
  transition: all .5s;
  .interstitial-box {
    padding: 30px 0 0;
    text-align: center;
    .mimage_wrapper {
      display: block;
      background: $white-color;
      width: 150px;
      height: 40px;
      margin: -60px auto 0 auto;
      padding: 10px 64px;
      .mimage{
        display: block;
        width: 150px;
        height: 40px;
      }
    }
    .int-content {
      list-style-type: none;
      margin: 8px 0 0;
      transition: all .5s;
      opacity: 0;
      &.ad-blocker {
        margin: 40px 0 0;
        h2 {
          font-size: 24px;
          line-height: 28px;
          margin-bottom: 20px;
          padding: 0 20px;
          .cb {
            font-size: 24px;
            line-height: 28px;
          }
        }
        p {
          font-size: 16px;
          line-height: 20px;
          color: $prim-text-color;
          padding-left: 10px;
          padding-right: 10px;
          a {
            &.blocker-help-link{
              display: block;
              margin-top: 15px;
              position: relative;
              color: $brand-01;
              text-decoration: none;
              margin-bottom: 30px;
            }
            &.learn-more-link{
              color: $brand-01;
              text-decoration: none;
            }
          }
        }
        .button {
          &.reload { margin-bottom: 10px; }
        }
      }
      &.faded-in{opacity:1;}
      .zero-height { height: 0; }
      .f-gry-43{color: $sec-text-color;}
      &.ad-mar {
        margin:0 !important;
        padding-bottom: 0 !important;
        width: 600px;
      }
      a {
        &.underline { text-decoration: underline; }
      }
      .moment{
        color: $prim-text-color;
        font-size: 16px;
        line-height: 32px;
        img{ margin:0 0 2px 8px; }
      }
      .special-term,
      .exclusions {
        color: $prim-text-color;
        font-size: 14px;
        line-height: 18px;
        margin: 20px 40px 0 40px;
        text-align: left;
      }
      .nothing {
        font-size: 20px;
        line-height: 28px;
        color:$prim-text-color;
        margin: 8px 20px 0;
        padding: 12px 0 0;
      }
      &.ccuser {
        .nothing {
          border-top: 0;
          margin-top: 0;
        }
        .catsection {
          margin-top: 16px;
          margin-bottom: 8px;
        }
      }
      .none { text-decoration:none; }
      .button.ext {
        @include color($white-color);
        @include background($cb-color);
        &:hover{ @include background($cb-color-hover); }
      }
      .button.int.default {
        @include color($prim-text-color);
        @include background($border-color);
        @include border;
      }
    }
    .int-itp {
      padding: 40px 50px;
      .merch-info {
        @include rakutenSansUIsemiBold;
        font-size: 24px;
        line-height: 28px;
        margin-bottom: 20px;
      }
      .cb-incentive {
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 30px;
      }
      .get-button-cta {
        margin-bottom: 50px;
        .fa-chrome {
          display: block;
          float: left;
          padding-top: 14px;
          padding-bottom: 14px;
          line-height: 18px;
          margin-right: 10px;
        }
        .get-button-copy {
          display: inline-block;
          line-height: 18px;
          padding-top: 14px;
          padding-bottom: 14px;
        }
      }
    }
  }
  .congratulations {
    @include stag(Stag Medium Web, 500);
    font-size: 28px;
    line-height: 36px;
    padding: 12px 0 15px 0;
    margin: 0 20px;
  }
  .on-way{
    color: $cb-color;
    @include stag(Stag Medium Web, 700);
    font-size: 40px;
    line-height: 52px;
    margin:0 20px;
  }
  .cb-by-rakuten {
    display: inline-block;
    margin-top: 12px;
    padding: 6px 8px;
    font-size: 14px;
    line-height: 16px;
    background-color: #f4f6fd;
    @include round(3px);
  }
  .on-way-earn {
    font-size: 20px;
    padding-top: 20px;
  }
  .smalltxt {
    color: $prim-text-color;
    font-size: 14px;
    line-height: 18px;
    padding: 6px 0 12px;
  }
  .desktop.redirectLink {
    a { color: $prim-text-color; }
  }
  .catsection {
    .cattitle {
      color:$prim-text-color;
      font-size: 18px;
      line-height: 22px;
      padding: 12px 0;
    }
    .catlist {
      li {
        display: inline-block;
        width: 100%;
        clear: both;
        background: $white-color;
        min-height: 35px;
        @include border-box;
        padding-top: 5px;
        padding-bottom: 5px;
      }
      .catitem {
        color: $prim-text-color;
        font-size: 18px;
        line-height: 22px;
        margin: 0 10px;
        padding: 0;
        &.catname {
          max-width: 330px;
          text-align: left;
        }
        &.text-truncate {
          text-overflow: ellipsis;
          overflow:hidden;
        }
        &.cb-amount {
          color: $cb-color;
          @include rakutenSansUIsemiBold;
        }
      }
    }
  }
  .single-line{
    &.top{ margin-bottom:15px; }
    &.bot{ margin-top:20px; }
  }
}

.info{border-top:1px solid $darker-border-color;margin:20px 20px 0;padding:20px 0 15px;text-align:left;}
.more-info{border-bottom:1px solid $darker-border-color;padding:0;text-align:left;}
.see-more{text-decoration:none;margin-left:10px;}


#post-filter-panel{padding-bottom:20px;}

.hide{display:none;}
.ab-text{font-size:24px;line-height:28px;padding:6px 0;}

.ie-info {
  border-bottom: 1px solid $darker-border-color;
  border-top: 1px solid $darker-border-color;
  margin: 10px 20px 0;
  text-align: left;
  ol {
    margin: 0 0 0 30px;
    li { padding-left:4px; }
  }

}
.ie-tools{background:transparent url(//static.ebates.com/global_files/layout_v1/images/interstitial/gear.png) no-repeat center center;display:inline-block;height:24px;width:24px;margin: 0 1px -5px;*margin: 0 5px -5px 1px;}

.catsection {
  margin: 8px 20px 0;
  padding: 0 18px 20px;
  ul{
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
}

.int-withcat .nothing{border:0;margin:0;}

.ccsection {
  background: #ffefef url("//static.ebates.com/global_files/layout_v1/images/interstitial/cc_interstitial_bg.1.0.png");
  background-size: cover;
  color: $prim-text-color;
  margin: 0;
  overflow: hidden;
  padding: 20px 16px 24px;
  text-align: left;
  img{
    display: block;
    margin: 0 8px 0 0;
  }
}
.rktnccsection{
  background: #ffefef url("//static.ebates.com/static/images/rakuten/cclp/banner-BG.jpg");
  background-size: cover;
  color: $prim-text-color;
  margin: 0;
  overflow: hidden;
  padding: 25px 15px 25px 25px;
  text-align: left;
  img{
    display: block;
    margin-right:20px;
	width:128px;
    height:76px;
  }
  .stag-med{font-weight:300;}
  .mar-l-148{margin-left: 148px;}
}
.cctitle{border-bottom:1px solid $border-color;color:#017edb;font-size:33px;padding:16px 0 0;}
.cctext{font-size:17px;padding:4px 0 0;}
/* --- button --- */


.direct{color:#999 !important;}

/* Tablet Mobile */
.btn { display: inline-block; margin-bottom: 0; font-weight: normal; text-align: center; vertical-align: middle;cursor: pointer;background-image: none;border: 1px solid transparent;white-space: nowrap;padding: 6px 12px;font-size: 14px;line-height: 1.42857143;border-radius: 4px;-webkit-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;text-decoration:none;}
.btn-sm, .btn-group-sm>.btn {padding: 5px 10px;font-size: 12px;line-height: 1.5;border-radius: 3px;}
a.btn-default {color: $prim-text-color;background-color: #eee; border-color: $darker-border-color;margin-top:10px;margin-bottom:10px;}
a.btn-orange {background-color: #FC3F26;color: $white-color;margin-top:10px; margin-bottom:10px;}
.btn-continue {width:120px;height:30px;line-height:30px;}
.appStoreBtn {width:150px;}
.int-wrap.mobile-gatepage .continue{display:block;}

.single-line{
  border:1px solid $border-color;
  height:2px; width:556.5px; margin-left:auto; margin-right:auto; box-sizing:border-box;
  &.top{ margin-top:20px; margin-bottom:5px; }
  &.bot{ margin-bottom:10px; margin-top:25px; }
}

.landing-su-form .referral-email-address {
  padding: 10px 0 10px 20px;
}