// Theme >>>
// Allowed values: dark, light, default
$theme: Default;
$themeMainFont: "Rakuten Sans UI";
$themeMainFontWeight: 400;
$themeMainFontStyle: normal;
$baseFontSizeIE: 10px; // Used for IE since it has a a different default font size
$baseFontSize: 62.5%; // 10px; // divide 10 by 16 (default browser font size) // This defines what 1rem is
$bodyFontSize: 1.4rem;
$font-default: 1.4rem;
$bodyLineHeight: 1.8rem;
$max-width: 117rem; // if we change this to 100% then the grid becomes 100% responsive.
$gutter-vertical: 1rem; // 10px;
$gutter-horizontal: 1.2rem; // 12px;
$auto-width: auto;
// Theme <<<

// Colors >>>
$default-bg-color: #fff;
$prim-text-color: #252525;
$prim-text-color-hover: #484848;
$sec-text-color: #575757;
$tert-text-color: #737373;
$inverse-text-color: #fff;
$hint-color: #737373; // Do Not Use. Use $tert-text-color
$action-text-color: #8529cd;
$brand-01: #8529cd;
$brand-01-hover: #9b50d6;
$brand-01-active: #7119b8;
$brand-03: #20a1e6;
$brand-04: #a30593;
$border-color: #ddd;
$darker-border-color: #ccc; // Used in Forms and Header borders
$lighter-border-color: #e7e7e7;
$border-color-header: #b9b9b9;
$cb-color: #e80c5b;
$cb-color-hover: #f55;
$color-peach: #fe9c7b;
$color-indigo: #311293;
$color-teal: #82eae7;
$color-brand-gradient: linear-gradient(75.8deg, #82eae7 0%, #311293 100%);
$color-brand-alt-gradient: linear-gradient(46.34deg, #ED5050 0%, #311293 100%);
$social-google-color: #dc4e41;
$social-google-color-hover: #e55145;
$social-facebook-color: #1877f2;
$social-facebook-color-hover: #1877f2;
$social-twitter-color: #00aced;
$social-twitter-color-hover: #30baee;
$gray-dark-8-color: #737373; // Do Not Use. Use $tert-text-color
$gray-dark-9-color: #999;
$section-inverse-primary-color: #292A6B;
// Colors <<<

$gray-dark-3-color: #252525;
$gray-dark-6-color: #666;
$extra-light-gray: #f4f4f4;
$white-color: #fff;
$gray-dd-color: #ddd;
$gray-de-color: #dedede;
$gray-ec-color: #ececec;
$gray-f6-color: #f6f6f6;
$black-color: #252525;
$just-for-you-color: #c49859;
$just-for-you-color-dark: #b68b47;
$bonus-color: #20A1E6;
$cc-color: #017edb; // #3d78c3;
$error-color: #d8000d;
$error-color-old: #f00;
$yellow-color: #fc0;
$darkorange-color: #ff8c00;
$bday-color: #f9557c;
$sub-header-color: #E4E9F5;

$header-color-start: #cb6683;
$header-color-mid: #871e8d;
$header-color-end: #5e63bd;

// Gradients:
$gradient-header:
        $header-color-start 0%,
        $header-color-mid 37.53%,
        $header-color-end 100%;

$gradient-2:
        $header-color-start 0%,
        $header-color-start 33%,
        $header-color-mid 33%,
        $header-color-mid 66%,
        $header-color-end 66%,
        $header-color-end 100%;

$gradient-3:
        $header-color-start 0%,
        $header-color-start 10%,
        $header-color-mid 25%,
        $header-color-mid 39%,
        $header-color-end 79%,
        $header-color-end 100%;

$gradient-4:
        $header-color-start,
        $header-color-mid,
        $header-color-end,
        $header-color-start,
        $header-color-mid,
        $header-color-end,
        $header-color-start,
        $header-color-mid,
        $header-color-end;

// MW ONLY | DO not use for Desktop >>>
$placeholder-color: #aaa; // for Desktop use $hint-color instead
$legal-gray: #737373;  // Do Not Use. Use $tert-text-color
$r4-btn-ht: 46px;
// MW ONLY | DO not use for Desktop <<<

@if $theme == new-ui {
  $prim-text-color: #252525;
// } @else if $theme == Light {
}

// Fonts >>>
$font-20: 20px;

$lh-default: 1;
// Fonts <<<

$static-path: "//static.ebates.com/";
$static-path-rakuten: "//static.ebates.com/static/svg/rakuten/";
$fonts-path: "//static.ebates.com/static/fonts/";
$images-path: "//static.ebates.com/images/";
$static-image-path-rakuten: "//static.ebates.com/static/images/";

$step5: 5;
$unit-fixed: px;