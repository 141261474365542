// Footer Desktop >>>
.foot {
  .container-fluid,
  .container-fixed { display: block; }
  .container-fixed,
  .container-fixed .blk0,
  .container-fixed .blk0 .col1{ width: $max-width; }
  .container-fluid { min-width: $max-width; }
  .help-flyout{
    bottom: 20px;
    .fa-question-circle { vertical-align: middle; padding-bottom: 3px; }
  }
}
.footer-link-block {
  padding-top: 60px;
  padding-bottom:66px;
  a {
    color: $default-bg-color;
    &.f-tert { color: $gray-dark-8-color; }
  }
  .footer-link-group {
    @include border-bottom( $border-width: 1px, $border-color: $gray-dark-6-color );
  }
  .footer-social-group {
    .social-list{
      li{
        display: inline-block;
        &:last-child .mar-25-r{ margin-right:0; }
      }
    }
  }
}

.footer-join {
  @include background ($brand-01);
  color: $white-color;
  ul {
    padding-top: 40px;
    padding-bottom: 40px;
    &> li h4 {
      font-size: 36px;
      line-height: 40px;
      padding-bottom: 15px;
      letter-spacing: 0.03167rem;
    }
    li {
      &:nth-child(2) {
        font-size: 20px;
        line-height: 24px;
        padding-bottom: 30px;
      }
      &:nth-child(3) { padding-bottom: 15px; }
      .button {
        &.display{ width: 300px; }
      }
    }
    .footer-sign-in {
      padding-bottom: 0;
      a{
        color: $white-color;
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
}

#footer {
  padding: 0;
  text-align: center;
  display: block;
  clear: both;
  background: $prim-text-color;
}
.footer-content-rakuten{
  .rakuten-content {
    margin-top: 32px;
    margin-bottom: 32px;
  }
  .left-links{
    display: block;
    float: left;
    position: relative;
    height: 128px;
    width: 384px;
    margin-right: 80px;
    .rakuten-footer-logo{
      width: 149px;
      height: 100px;
    }
  }
  .center-links{
    width: 530px;
    li{width: 170px;}
  }
  .right-links{
    width: 360px;
    li{width: 170px;}
  }
  a { color: $gray-dark-8-color; }
}
// Footer  Desktop <<<

/* --- Generic fixed footer --- */
.footer-fixed {
  border: none;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 202;
  padding: 0;
  transition: all .3s;box-shadow:0 -3px 3px rgba(0, 0, 0, 0.15);
  &, .sf-content { height: 66px; }  // TODO - make it a variable
  * { line-height: 1; }
  &.min { bottom:-66px; }
  .min-max-tab {
    left: 20px;
    top: -36px;
    height: 36px;
    width: 36px;
    background: $gray-dark-8-color;
    box-shadow:0 -3px 6px -3px rgba(0, 0, 0, 0.3) inset;
    z-index: 302;
    border-top-left-radius: 18px;
    border-top-right-radius: 18px;
    &.min {
      .fa-times {
        &:before { content: "\f077"; }
      }
    }
  }
  .footer-fixed-center {
    margin: auto;
    width: $max-width;
    position: relative;
    height: inherit;
  }
}

.up-top{
  @include square(50px);
  @include round(50%);
  @include box-shadow(0 3px 5px 0 rgba(0,0,0,0.4));
  @include border-box;
  cursor: pointer;
  transition: box-shadow .3s;
  transition: opacity 0.3s ease-out;
  -webkit-transition: box-shadow .3s;
  border: 0;
  opacity: 0;
  &.show { opacity: 1; }
}

.up-top-container {
  .up-top {
    margin-right: 74px;
    position: absolute;
    top: -121px;
    z-index: 9999;
    right: 0;
    &.hotels {
      position: absolute;
      right: 0;
      .up-top {
        position: absolute;
        margin-left: 0;
        left: -124px;
        top: -192px;
        -webkit-transition: box-shadow .3s;transition:box-shadow .3s;
      }
    }
  }
}

.referPage.up-top-container{ position: fixed;bottom:-2px;right:0px;}
.min .up-top{top:-120px;}
@media(max-width:1260px) {
  .footer-fixed-center .min-max-tab{left:0;}
}
/* Help Flyout for Footer and Sticky Footer >>> */
.help-flyout{
  -webkit-transition: box-shadow .3s;
  transition: box-shadow .3s;
  height: 38px;
  line-height: 38px;
  bottom: 84px;
  z-index: 101;
  right: 0;
  margin-right: 20px;
  &.button { width: 115px; }
  .fa-question-circle {
    vertical-align: middle;
    padding-bottom: 3px;
  }
}
.min .help-flyout{bottom:86px;}
@media(min-width:1400px) {
  .help-flyout,
  .up-top-container .up-top {
    left: 50%;
    margin-left: 598px;
  }
  .up-top-container {
    .hotels {
      position: absolute;
      top: -80px;
      left:calc( 50% + 598px );
      .up-top.show {
        position: absolute;
        margin-left: 0;
        top: -111px;
        left: 0;
      }
    }
  }
  .referPage.up-top-container{ position: fixed;bottom:-2px;left:50%;right:initial}
}
/* Help Flyput for Foter and Sticky Footer <<< */
.sf-left {
  &.col6 {
    &.col_5_6 { width: 800px;
      margin-right: 0;
    }
  }
}
.sf-right {
  &.col6 {
    width: 370px;
    margin-right: 0;
  }
}
.footer-fixed {
  &.merchant-sf{
    @include background($white-color);
    -webkit-transition:all .3s;
    .d1{
      height: 66px;
      width: 800px;
      top: 0;
      left: 0;
      z-index: 8;
      &.min { margin-left: -2000px; }
      img { bottom: 0; left: 0; }
      .sf-merch-a {
        height: 66px;
        width: 800px;
        display: block;
        top: 0;
      }
    }
    .sf-cb {
      @include border-box();
      width: 171px;
      bottom: 0;
      overflow: hidden;
      z-index: 10;
      &.mar-20-l {
        margin-left: 18px; // hack for misaligned button when zoomed out. Not touching SF code.
      }
    }
    .sf-right {
      .sf-code { color: $prim-text-color; }
    }
    .sf-cb,
    .button{
      opacity: 1;
      &.min{ opacity: 0; }
    }
  }
}

/* --- Fixed footer - Merchant bonus --- */
.merchant-bonus {
  * { color: $prim-text-color; }
  .bonus-badge {
    top: -31px;
    left: 0;
    transition: all .4s;
    &.min {
      top:0;
      opacity: 0;
      transform: scale(0, 1);
      filter: alpha(opacity=0);
    }
  }
  .shop-now {
    display: block;
    padding: 23px 0 0 75px;
    white-space: nowrap;
    &:hover { text-decoration: none; }
  }
  .footer-fixed-center { overflow: visible; }
}


.modal-dialog-ui {
  background: url("#{$images-path}home/2014/q2/meria-overlay/ui-sprite.1.0.0.png") no-repeat 0 0;
  overflow: auto;
}
.cc-dialog-close {
  overflow: hidden;
  position: absolute;
  z-index: 999;
  top: -15px;
  right: -10px;
  a {
    background-position: -35px 0;
    cursor: pointer;
    display: block;
    height: 29px;
    width: 29px;
    &:hover { text-decoration: none; }
  }
}

/* --- Flyout --- */
.flyout {
  top: 250px;
  right: -4px;
  border-radius: 4px;
  padding: 0 8px 5px 5px;
  width: 85px;
  height: 60px;
  z-index: 101;
}
.sf-code{
 .tooltip-container {
   &.arrow-b {
     background-color: $gray-dark-8-color;
     margin-left: 58px;
     bottom: 50%;
	 &:after {border-top-color: $gray-dark-8-color;}
   }
 }
}
.footerlinkmodal{
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 9999999;
    background: 0 0;
    text-align: center;
    overflow: auto;
    .framodal {
       background: $white-color;
       opacity: 1;
       position: relative;
       display: inline-block;
       margin-top: 85px;
    }
}
