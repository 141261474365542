//* --- HP Carousel --- */
.hp-promo-carousel {
  @include store-tile;
  position: relative;
  float: left;
  height: 280px;
  width: 973px;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000;
  ul > li {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
  }
  .incentive {
    display: block;
    position: absolute;
    top:86px;
    left: 528px;
    width: 410px;
    .carouseltitle {
      display: block;
      height: 80px;
      // @include rakutenSansUI(Rakuten Sans UI, 400);
      @include stag(Stag Medium Web, 500);
      font-size: 30px;
      line-height: 40px;
      color: $prim-text-color;
    }
    .carouselcb {
      display: block;
      height: 30px;
      line-height: 24px;
      margin-top: 5px;
      .cb {
        @include rakutenSansUIsemiBold;
        font-size: 16px;
        line-height: 24px;
      }
      .code-text {
        font-size: 16px;
        color: $sec-text-color;
        margin-left: 10px;
      }
      .code {
        font-size: 16px;
        color: $sec-text-color;
        @include rakutenSansUIsemiBold;
        letter-spacing: 1px;
      }
    }
    .carousel-cb { margin-top: 2px; }
  }
  .prev, // Next and Previous controls
  .next {
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    font-size: 60px;
    height: 60px;
    line-height: 60px;
    padding: 35px 5px;
    color: rgba(0,0,0,0.3);
    min-width: 60px;
    -webkit-touch-callout: none;
    @include user-select;
    z-index: 11;
    background: rgba(255,255,255,0.3);
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-tap-highlight-color: transparent;
    &:hover{
      cursor:pointer;
      text-decoration:none;
      color: rgba(0,0,0,0.5);
      background: rgba(255,255,255,0.5);
    }
  }
  .next {
    @include round(8px 0 0 8px);
    right:-35px;
  }
  .prev {
    @include round(0 8px 8px 0);
    left: -35px;
    text-align: right;
  }
  .counter {
    position: absolute;
    top: 65%;
    right: 1px;
    z-index: 12;
    font-size: 12px;
    color: $gray-dark-9-color;
    width: 32px;
    text-align: center;
    * { color: $gray-dark-9-color; }
  }
  .transition {
    transition: all .5s;
    -ms-transition: all .5s;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
  }
}
.top-z-index{z-index:10;}
.next-right{transform:translateX(-973px);-webkit-transform:translateX(-973px);-ms-transform:translateX(-973px);}
.next-left{transform:translateX(973px);-webkit-transform:translateX(973px);-ms-transform:translateX(973px);}
//* --- End HP Carousel --- */

// Carousel Controllers >>>
.ht-special-circle-left,
.ht-special-circle-right,
.ht-semi-circle-left,
.ht-semi-circle-right {
  bottom: 0;
  cursor: pointer;
  height: 80px;
  margin: auto;
  opacity: 0.8;
  position: absolute;
  top: -10px;
  width: 40px;
  background: $white-color;
}
.ht-special-circle-right,
.ht-semi-circle-right {
  @include round(0 40px 40px 0);
  box-shadow: 2px 0 6px 0 rgba(0, 0, 0, 0.24);
  &:hover { box-shadow: 2px 0 6px 0 rgba(0, 0, 0, 0.60); }
  left: 1px;
  &.scroll-control-rt { @include round(0 40px 40px 0); }
}
.ht-special-circle-left,
.ht-semi-circle-left {
  @include round(40px 0 0 40px);
  box-shadow: -2px 0 6px 0 rgba(0, 0, 0, 0.24);
  &:hover { box-shadow: -2px 0 6px 0 rgba(0, 0, 0, 0.60); }
  right: 0;
  &.scroll-control-lt { @include round(40px 0 0 40px); }
}
.ht-icon-left,
.ht-icon-right {
  font-size: 24px;
  color: $sec-text-color;
  width: 15px;
  line-height: 30px;
  &:hover { color: $tert-text-color }
}
.ht-icon-left {
  padding: 25px 15px 25px 10px;
  text-align: left;
}
.ht-icon-right {
  padding: 25px 10px 25px 15px;
  text-align: right;
}
// Carousel Controllers <<<

.promo-block { // All Promo Tile Promo Blocks
  @include store-tile;
  text-align: center;
  margin-bottom: 10px;
  .promo-block-content {
    .img-wrap { display: block; }
    .was_rebate {
      font-size: 12px;
      line-height: 12px;
      height: 12px;
      color: $tert-text-color;
    }
    .see_store {
      color: $tert-text-color;
      margin-top: 5px;
    }
  }
  .cb-x-img-blk { @include round(8px); }
}
.tcb-module {
  margin-bottom: 10px;
  .h2-bg-image {
    @include store-tile;
    padding: 30px;
    h2 {
      @include stag(Stag Web, 400);
      font-size: 24px;
      line-height: 20px;
      padding:10px 0 10px 30px;
      float: left;
    }
  }
  .special-stores {
    &.stores-caurosel-block {
      height: 165px;
      .bday-special-stores-block {
        height: 185px;
      }
    }
    .promo-block {
      background-size:cover !important;
      background-position: center !important;
      background-repeat: no-repeat;
      &.blk {
        &.col {
          width: 185px;
          margin-right: 12px;
        }
      }
    }
  }
  .special-stores-blk {
    .round40-l {border-radius:40px 0 0 40px;}
  }
  .scroll-group {
    .special-last-block{ margin-right: 0 !important; }
  }
  .special-btn:hover{background-color:$white-color !important;color:$prim-text-color !important;}
}

//* --- HP Trending Stores --- */
.home-trend {
  width: 182px;
  height: 280px;
  h1{
    @include rakutenSansUIsemiBold;
    font-weight: 500;
    font-size: 9px;
    height: 12px;
    line-height: 12px;
    padding-top: 6px;
    color: $gray-dark-8-color;
    text-align: center;
  }
  ul {
    &.home-trend-list {
      @include store-tile;
      overflow: hidden;
      text-align: center;
      li {
        &.home-trend-list-item {
          @include border-bottom($border-color: $lighter-border-color);
          height:43px;
          &.trend {
            height: 40px;
            line-height: 40px;
          }
          a {
            height: 43px;
            width: 100%;
            display: block;
            .logo { margin-top: 8px; }
          }
          &:last-child { height: 42px; }
        }
      }
    }
  }
}
//* --- End HP Trending Stores --- */

//* --- HP Premium Stores --- */
.premium-store {
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;
  @include store-tile;
  .banner-blk {
    display: block;
    text-align: center;
    .small-banner{
      display: inline-block;
      width: 100%;
      height: 150px;
      overflow: hidden;
      @include round(8px 8px 0 0);
    }
    .logos {
      @include store-tile;
      display: inline-block;
      padding: 11px 24px;
      margin-top: -29px;
    }
  }
  .desc-blk {
    display: block;
    text-align: center;
    padding-left: 30px;
    padding-right: 30px;
    &:hover { text-decoration: none; }
    .cb {
      display: block;
      margin-top: 10px;
      margin-bottom: 10px;
    }
    .premium-desc{
      display: block;
      color: $prim-text-color;
      font-size: 16px;
      line-height: 20px;
      height: 40px;
      overflow: hidden;
      @include rakutenSansUIsemiBold;
    }
    .premium-expire {
      display: block;
      font-size: 12px;
      line-height: 16px;
      height: 16px;
      margin-top: 15px;
      color: $gray-dark-8-color;
      .fa-clock-o {
        color: $gray-dark-8-color;
        margin-right: 2px;
      }
      .expires-today {
        color: $cb-color;
        .fa-clock-o { color: $cb-color; }
      }
    }
  }
  .tooltip-trigger {
    display: block;
    position: relative;
    font-size: 14px;
    line-height: 18px;
    height: 18px;
    margin-top: 15px;
    .coupon-code {
      display: block;
      font-size: 14px;
      text-align: center;
      padding-left: 30px;
      padding-right: 30px;
      color: $gray-dark-8-color;
      .promo-code {
        @include letter-space( 0.5px );
        cursor: pointer;
        color: $white-color;
        @include background($brand-01);
        @include round(5px);
        @include rakutenSansUIsemiBold;
      }
      .tooltip-container {
        background-color: $brand-01;
        font-size: 14px;
        line-height: 14px;
        padding: 10px;
        position: absolute;
        cursor: pointer;
        color: $white-color;
        border-radius: 8px;
        .copy {
          color: $white-color;
          display: inline-block;
        }
      }
    }
  }
  .shop-blk {
    // display: block;
    &:hover { text-decoration: none; }
    margin-top: 15px;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
    display: none;
  }
  .premium-cash{height:38px;}
  .icon-clock-gry,
  .icon-clock-red{display:inline-block;margin-bottom:-4px;}
}
//* --- End HP Premium Stores --- */

// Used by In Store Cash Back and Targeted Cash Back Modules
.scroller-deals { // formerly iscb-deals
  display: block;
  margin-bottom: 10px;
  .scroll-block {
    margin-bottom: -10px;
    padding-bottom: 20px;
  }
  .scroller-deals-stores { // formerly iscb-items
    .scroller-deals-store { // formerly iscb-item
      @include store-tile;
      position: relative;
      text-align: center;
      .scroller-deals-store-link {
        display: block;
        padding-top: 35px;
        padding-bottom: 35px;
        &:hover { text-decoration: none; }
        .scroller-deals-store-img { display: inline-block; }
        .scroller-deals-store-cb-prev {
          display: block;
          font-size: 12px;
          line-height: 15px;
          height: 15px;
          padding-top: 20px;
          color: $gray-dark-8-color;
        }
        .scroller-deals-store-cb {
          display: block;
          font-size: 16px;
          line-height: 20px;
          padding-top: 5px;
        }
        .scroller-deals-store-text {
          display: block;
          font-size: 14px;
          line-height: 18px;
          height: 36px;
          padding-top: 8px;
          padding-right: 30px;
          padding-left: 30px;
          overflow: hidden;
          color: $prim-text-color;
          &.ellipsis-2l {
            > *:first-child {
              float: right;
              width: 100%;
              margin-left: -5px;
            }
            &:before {
              content: "";
              float: left;
              width: 5px;
              height: 36px;
            }
            &:after {
              content: "\02026";
              box-sizing: content-box;
              -webkit-box-sizing: content-box;
              -moz-box-sizing: content-box;
              float: right;
              position: relative;
              top: -18px;
              left: 99%;
              width: 3em;
              margin-left: -3em;
              padding-right: 5px;
              text-align: right;
              background-size: 100% 100%;
              background-image:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAgAAAAABCAMAAACfZeZEAAAABGdBTUEAALGPC/xhBQAAAwBQTFRF////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////AAAA////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////wDWRdwAAAP90Uk5TgsRjMZXhS30YrvDUP3Emow1YibnM9+ggOZxrBtpRRo94gxItwLOoX/vsHdA2yGgL8+TdKUK8VFufmHSGgAQWJNc9tk+rb5KMCA8aM0iwpWV6dwP9+fXuFerm3yMs0jDOysY8wr5FTldeoWKabgEJ8RATG+IeIdsn2NUqLjQ3OgBDumC3SbRMsVKsValZplydZpZpbJOQco2KdYeEe36BDAL8/vgHBfr2CvTyDu8R7esU6RcZ5ecc4+Af3iLcJSjZ1ivT0S/PMs3LNck4x8U7wz7Bv0G9RLtHuEq1TbJQr1OtVqqnWqRdoqBhnmSbZ5mXapRtcJGOc4t2eYiFfH9AS7qYlgAAARlJREFUKM9jqK9fEGS7VNrDI2+F/nyB1Z4Fa5UKN4TbbeLY7FW0Tatkp3jp7mj7vXzl+4yrDsYoVx+JYz7mXXNSp/a0RN25JMcLPP8umzRcTZW77tNyk63tdprzXdmO+2ZdD9MFe56Y9z3LUG96mcX02n/CW71JH6Qmf8px/cw77ZvVzB+BCj8D5vxhn/vXZh6D4uzf1rN+Cc347j79q/zUL25TPrJMfG/5LvuNZP8rixeZz/mf+vU+Vut+5NL5gPOeb/sd1dZbTs03hBuvmV5JuaRyMfk849nEM7qnEk6IHI8/qn049hB35QGHiv0yZXuMdkXtYC3ebrglcqvYxoj1muvC1nDlrzJYGbpcdHHIMo2FwYv+j3QAAOBSfkZYITwUAAAAAElFTkSuQmCC);
              background: -webkit-gradient(linear,left top,right top,from(rgba(255, 255, 255, 0)),to(white),color-stop(50%,white));
              background:-moz-linear-gradient(to right,rgba(255,255,255,0),white 50%,white);
              background:-ms-linear-gradient(to right,rgba(255,255,255,0),white 50%,white);
              background:linear-gradient(to right,rgba(255,255,255,0),white 50%,white);}
          }
        }
        .scroller-deals-store-details {
          display: block;
          color: $brand-01;
          font-size: 14px;
          padding-top: 25px;
          margin-bottom: 15px;
          &.hide {
            display: none;
          }
        }
        .button { margin-top: 20px; }
        .scroller-gc-store-button {
          margin-top: 10px;
        }
      }
      .scroller-gc-store-link {
        padding-top: 30px;
        padding-bottom: 30px;
      }
    }
    .fa-store {
      background: transparent url(//static.ebates.com/global_files/layout_v1/images/icons/store.png) center center no-repeat;
      width: 17px;
      height: 14px;
    }
    .button {
      top: auto;
      bottom: 25px;
    }
    &.scroll-group {
      .scroller-deals-store {
        display: block;
        @include width( 283.5px );
        @include height( 293px );
        float: left;
        &:not(:last-child) { margin-right: 12px; }
      }
      .scroller-gc-store {
        @include width( 186.5px );
        @include height( 251px );
      }
      &.as-filmstrip {
        .scroller-deals-store {
          &:not(:last-child) { margin-right: 12px; }
        }
        .scroller-gc-store {
          &:not(:last-child) { margin-right: 10px; }
        }
      }
    }
  }
  &.tcb-deals { // Targeted Cash Back
    .scroller-deals-stores {
      &.scroll-group {
        .scroller-deals-store {
          &.tcb-item {
            @include width(185px);
            @include height(154px);
          }
        }
      }
    }
  }
}

.carousel { // Corrections on spelling to be used elsewhere
  .carousel-x { // Horizontal Carousel overflow-0 rel
    overflow: hidden;
    position: relative;
  }
}

.gift-cards-module {
  .scroller-deals {
    .carousel {
      height: 261px;
      .scroll-block {
        height: 271px;
      }
    }
  }
  &.container-fixed {
    @include respond-to-min(1190) {
      // width: 1190px; // May be needed for scroller shadows
      // .scroller-deals-stores {}
    }
  }
}

.targeted-cash-back {
  .tcb-heading-image {
    padding: 30px;
    height: 105px;
    .tcb-heading {
      @include stag(Stag Medium Web, 500);
      font-size: 24px;
      line-height: 24px;
      padding-top: 10px;
      padding-left: 30px;
      padding-bottom: 10px;
      float: left;
    }
  }
}

/* Sticky Right Module */
.promo-scroll {
  .side-scroll-top {
    width: 334px;
    height:0;
  }
  .side-scroll-target {
    width: 334px;
    -webkit-overflow-scrolling: unset;
    .right-dbl-cb {
      @include store-tile;
      .hdr {
        @include border-box;
        width: 99.99%;
        h3 {
          &:hover {
            text-decoration: none;
            a { text-decoration: none; }
          }
          .dbl-cb-title {
            display: inline-block;
            .right-dbl-heading {
              display: block;
              float: left;
              width: 205px;
            }
            .see-all { width: 65px; }
          }
        }
      }
      .m-feature-store-grid:last-of-type { margin-right: 0 }
      .right-dbl-sticky {  }
      .side-dd {
        &.m-double-cb-store-grid-s {
          width: 33.33%;
          @include border-box;
          height: 70px;
          // @include border-right();
          // @include border-bottom();
          float: left;
          cursor: pointer;
          a {
            height: 70px;
            .logo {
              width: 80px;
              image-rendering: auto;
              padding-top: 25px;
            }
          }
        }
        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(4),
        &:nth-child(5),
        &:nth-child(7),
        &:nth-child(8) {
          @include border-right();
          @include border-bottom();
        }
        &:nth-child(10),
        &:nth-child(11) { @include border-right(); }
        &:nth-child(3),
        &:nth-child(6),
        &:nth-child(9) { @include border-bottom(); }
      }
      li {
        width: 110px;
        height: 70px;

        a {
          width: 110px;
          height: 70px;

          img {
            padding-top: 25px;
            image-rendering: auto;
          }
        }
      }
    }
  }
}

.sec-promo {
  .hero {
    &.sweeps {
      @include store-tile;
      img { @include round(8px); }
    }
  }
}
// Primary Promotion
.promo-header {
  @include store-tile;
  &.nostore-header { @include background($brand-01); }
  img {
    display: block;
    overflow: hidden
  }
  p {
    line-height: 18px;
    margin: 0 0 1px;
    &:last-child { margin: 0; }
  }
}
.promo-list {
  .col4:nth-child(4n+4){margin-right:0;}
}
.promo-tile {
  @include store-tile;
  .banner-wrap {
    display: block;
    overflow: hidden;
    height: 46px;
  }
  .banner {
    height: 46px;
    width: 229px;
  }
  img {
    &.default-banner { width: auto; }
  }
  .arrow.arr-right {
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 6px solid $cb-color;
    margin-left: 4px;
  }
}
.divider-inside-top {
  position: relative;
  overflow: hidden;
  border-top: 1px solid $border-color;
  &:before{
    @include rectangle(90%, 8px);
    content: "";
    position: absolute;
    z-index: 1;
    top: -8px;
    left: 4%;
    border-radius: 100px / 5px;
    box-shadow :0 0 20px rgba(0,0,0,0.8);
  }
}
// END Primary Promotion
.promo-block-link {
  .was_rebate,
  .see_store {
    color: $tert-text-color;
  }
}
.hp-promo-row {
  .promo-block {
    .promo-block-link {
      display: block;
      padding-top: 30px;
      padding-bottom: 30px;
      text-decoration: none;
      .img-wrap { // Used in Promo Blocks in HP
        display: block;
        position: relative;
        height: 27px;
        padding: 0 10px;
        margin-bottom: 15px;
      }
      .img-wrap-cyber { // Used in Cyber Promo Blocks in HP
        display: block;
        position: relative;
        height: 27px;
        padding: 5px 10px 0 10px;
        margin-bottom: 15px;
      }
      .was_rebate {
        display: block;
        font-size: 12px;
        line-height: 12px;
        height: 12px;
        color: $tert-text-color;
      }
      .now_rebate {
        display: block;
        position: relative;
        margin-bottom: 5px;
      }
      .see_store {
        display: block;
        font-size: 10px;
        line-height: 14px;
        height: 28px;
        color: $tert-text-color;
        margin-top: 5px;
      }
      .promo-ribbon {
        display: block;
        position: absolute;
        font-size: 12px;
        line-height: 20px;
        text-transform: uppercase;
        color: $white-color;
      }
    }
  }
  .promo-block-img {
    text-align: center;
    margin-bottom: 10px;
    .cb-x-img-blk {
      display: block;
      position: relative;
      overflow: hidden;
      @include store-tile;
    }
  }
}
.primary-promo-module {
  .promo-block {
    // promo-block blk col b-box blk-reg mar-10-b center bg-hover borde
    // leave to cross check against other promo blocks and refactor
    .promo-block-link {
      position: relative;
    }
  }
}

// Essentials Stores (More Stores) at the bottom of Promo //
.more-stores {
  .promo-block {
    .promo-block-link {
      display: block;
      .promo-block-content {
        position: relative;
        padding: 20px 40px;
        .img-wrap {
          margin-top: 3px;
          height: 27px;
          float: left;
          text-align: center;
          padding-right: 40px;
        }
        .promo-block-content-right {
          display: block;
          float: left;
          @include border-left;
          padding-left: 40px;
          .now_rebate {
            display: block;
            font-size: 16px;
            line-height: 16px;
            .was_rebate {
              display: inline-block;
              @include rakutenSansUI(Rakuten Sans UI, 400);
              color: $tert-text-color;
              font-size: 12px;
              line-height: 16px;
              padding-left: 10px;
            }
          }
          .see_store {
            display: block;
            color: $tert-text-color;
            font-size: 10px;
            line-height: 10px;
            margin-top: 5px;
          }
        }
      }
    }
  }
}
// End Essentials Stores (More Stores) at the bottom of Promo //

.promo-hero {
  .hero {
    &.sweeps {
      @include store-tile;
      overflow: hidden;
      margin-bottom: 10px;
      .promo-info {
        &.promo-header {
          padding: 30px;
          h1 {
            &.section-heading {
              padding-top: 0;
              padding-bottom: 10px;
            }
          }
          &.post-promo-seo {
            .expandablediv {
              line-height: 18px;
            }
          }
        }
      }
    }
  }
}
.promo-info-blk { // Promo Page SEO Content (Expandable)
  .hero {
    &.sweeps {
      @include store-tile;
      overflow: hidden;
      margin-bottom: 20px;
      .promo-info {
        &.promo-header {
          padding: 30px;
          h1 {
            &.section-heading {
              padding-top: 0;
              padding-bottom: 10px;
            }
          }
          &.post-promo-seo {
            .expandablediv { line-height: 18px; }
            p {
              font-size: 14px;
              line-height: 18px;
              color: $tert-text-color;
              margin-bottom: 15px;
            }
          }
        }
      }
    }
  }
}

.aside {
  a {
    &.readmore-expander { color: $brand-01; }
  }
}

.promo-ribbon{width:100%;top:0;left:0;margin-top:-1px;margin-left:-1px;}

.cyber.bday.w-filler{
  .cols-6{
    .col:nth-child(9n+0),
    .col:nth-child(13n+0){margin-left:394px;}
    .col:nth-child(10n+0),
    .col:nth-child(14n+0){margin-right:0;}
    .col:nth-child(12n+0){margin-right:12px;}
    .col:nth-child(18n+0){margin-left:0;margin-right:12px;} // hack for 9nth child that repeats at position 18
  }
  .module-title.col{width:382px;left:393px;overflow:hidden;}
  .module-title.col.tall{height:280px;top:145px;} // enable later for bday layout with cb
  .module-title.col.short{height:238px;top:124px;} // bday layout without cb
}
.promo-bday{
  .heading-share{
    .container-share-message,
    .heading-share-module,
    .share-icons-blk{width:330px;}
    .container-share-message {
      margin-top: -50px;
      margin-bottom: -17px;
      // padding-right: 130px;
    }
    .tooltip-load{display:none;}
    .share-icons-blk .fav-message{left:-5px;}
  }
}
.is-tablet .promo-bday .heading-share .container-share-message{
  width: 320px;
  float: left;
  left: 450px;
  position: relative;padding-right:0px;
}
.ios.is-tablet .container-share-message{
  overflow: visible;
}
.ios.is-tablet .container-share-message input.referral-link{
  display: none;
}
.ios.is-tablet .container-share-message a.referral-link{
  position: absolute;
  background-color: $darker-border-color;
  left:-50px;
  top:-35px;
  line-height: 30px;
  height: 30px;
  width:200px;
  font-size: 16px;
  border-radius: 0;
  color:$prim-text-color;
}

.ad-scans { // Home Page AdScans Module
  .adscans-row {
    .adscans-grid {
      @include store-tile;
      padding-top: 15px;
      cursor: pointer;
      .img-container {
        img {
          display: block;
          margin: 0 auto;
          @include round(8px);
        }
      }
    }
  }
}
.new-ui {
  .heading-share {
    .social.int { background-color: $cb-color; }
  }
  .main {
    .border-grn-t-2 { border-top: 1px solid $border-color; }
    &.allstores {
      @include store-tile;
      .all-stores-module-heading {
        padding-right: 30px;
        padding-left: 30px;
        .module-heading { border-bottom: none; }
      }
      .filter-container {
        display: block;
        @include border-box();
        @include border();
        @include round($radius: 3px);
        margin-top: 20px;
        padding-left: 20px;
        height: 48px;
        position: relative;
        input[type="text"] {
          &.store-filter {
            @include round(5px);
            border: none; // overwrite default border for text fields
            width: 700px;
            margin-top: 14px;
            margin-bottom: 14px;
            padding-bottom: 5px;
            font-size: 16px;
            line-height: 16px;
            color: $sec-text-color;
          }
        }
        button {
          &.search-button {
            cursor: pointer;
            display: block;
            background: transparent;
            margin-top: 2px;
            border: none;
            float: right;
            font-size: 16px;
            line-height: 1;
            @include square(40px);
            .fa-search { color: $darker-border-color; }
          }
        }
        .clear-filter {
          position: absolute;
          cursor: pointer;
          @include square(40px);
          font-size: 16px;
          line-height: 1;
          background: $white-color;
          right: 2px;
          top: 2px;
          &:hover { text-decoration: none; }
          .fa-close {
            display: block;
            @include square(16px);
            color: $border-color;
            margin: 12px 0 0 13px;
            &:hover { text-decoration: none; }
          }
        }
      }
      .cat-filter-block {
        height: 54px;
        display: block;
        overflow:hidden;
        margin-top: 20px;
        margin-bottom: 30px;
        position: relative;
        .cat-filters {
          height:75px;
          padding-left: 30px;
          white-space: nowrap;
          overflow-x: scroll;
          position: relative;
          a {
            &:last-child{
              margin-right:30px;
            }
          }
          .button {
            &.scrollerbutton {
              &:hover {
                color: $white-color;
                background-color: $brand-01-hover;
              }
            }
          }
        }
      }
      .ht-semi-circle-left {
        top: -12px;
        .ht-icon-right {
          margin-top: 3px;
          top: 25px;
        }
      }
      .ht-semi-circle-right {
        top: -12px;
        .ht-icon-left {
          margin-top: 3px;
          top: 25px;
        }
      }
      .store-results {
        @include border-right(1px);
        @include border-left(1px);
        background: $white-color;
      }
      .store-sort {
        @include border-top;
        .store {
          height: 70px;
          padding-right: 30px;
          padding-left: 30px;
          @include border-bottom(1px);
          .store-row {
            line-height: 16px;
            .store-name {
              width: 200px;
              margin-right: 30px;
              .name-link {
                font-size: 16px;
                line-height: 20px;
              }
              a { color: $prim-text-color; }
            }
            .coupon-count {
              width: 148px;
              margin-top: 29px;
              margin-right: 30px;
              .coupon-link { color: $gray-dark-8-color; }
              a { color: $gray-dark-8-color; }
            }
            .store-rebate {
              width: 220px;
              margin-top: 27px;
              margin-right: 25px;
              .rebate-link {
                @include rakutenSansUIsemiBold;
                font-size: 16px;
                &:hover { text-decoration: none; }
                .cb-prev {
                  @include rakutenSansUI(Rakuten Sans UI, 400);
                  font-size: 12px;
                  color: $gray-dark-8-color;
                  padding-left: 5px;
                }
              }
            }
            .store-shop {
              width: 109px;
              margin-top: 20px;
              .button { width: 109px; }
            }
          }
        }
        .no_result_store {
          padding-top: 40px;
          padding-bottom: 60px;
          @include border-bottom(1px);
          .no-results {
            margin-left: auto;
            margin-right: auto;
            font-size: 16px;
            line-height: 20px;
            text-align: center;
            padding-top: 20px;
            width: 600px;
            .logo {
              margin-left: auto;
              margin-right: auto;
              margin-bottom: 10px;
              width: 100px;
            }
            .cb,
            .copy { margin-bottom: 15px; }
          }
        }
      }
    }
  }
}
.promo-modules {
  &.cyber { margin-bottom: 20px; }
}

@include respond-to-min(1185) { // override carousels so they're wider than 1170.
  .tcb-module {
    .stores-caurosel-block {
      width: 1185px;
      margin-left: -10px;
    }
    .special-stores {
      &.stores-caurosel-block {
        .bday-special-stores-block {
          padding-left: 10px;
        }
      }
    }
    .special-stores-blk {
      .ht-special-circle-right { left: -10px; }
      .ht-special-circle-left { right: -10px; }
    }
  }
  .gift-cards-module {
    .carousel {
      width: 1185px;
      margin-left: -10px;
      .scroll-block {
        padding-left: 10px;
      }
    }
  }
}
@include respond-to-min(1185) { // override carousels so they're wider than 1170.
  .tcb-module,
  .gift-cards-module,
  .iscb-module {
    .carousel {
      width: 1185px;
      margin-left: -10px;
      .scroll-block {
        padding-left: 10px;
      }
    }
  }
}