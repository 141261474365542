@import "../../partials/functions";
@import "../../partials/variables";
@import "../../partials/mixins";
.store-right {
  .store-coupons-head {
    @include store-tile;
    display: block;
    //@include border;
    background-color: $white-color;
    padding: 20px 30px;
    margin-bottom: 20px;
    &.mar-0-b {
      margin-bottom: 0;
      @include border-bottom( $border-width: 0 );
    }
  }
  .bonus-offers {
    @include store-tile;
    background-color: $brand-01;
    padding: 30px;
    margin-bottom: 20px;
    .fa-bell{
      color: $white-color;
      font-size: 20px;
      line-height: 30px;
      float: left;
    }
    .bonus-link-new {
      width: 826px;
      display: block;
    }
    .welcome-title,
    .return-title {
      padding-left: 40px;
      h3 {
        color: $white-color;
        font-size: 24px;
        line-height: 30px;
      }
      p {
        font-size: 14px;
        line-height: 20px;
        color: $white-color;
        padding-top: 15px;
      }
    }
  }
  .store-cb-terms { // Right Side Cash Back Terms
    @include store-tile;
    border: none;
    background-color: $white-color;
    padding: 0;
    margin-bottom: 20px !important;
    h2 {
      &.left-cb { padding:0 !important; }
    }
    .expander-terms {
      ul { margin-bottom: 15px; }
      p {
        &:not(:last-child) { margin-bottom: 15px; }
      }
    }
    .terms-content {
      padding: 20px 30px;
      &:not(:last-child) { margin-bottom: 15px; }
    }
    .expandable-content {
      &.left-expandable {
        padding:0 !important;
        border-top:none;
      }
      padding: 20px 30px;
      .expandablediv { line-height: 18px; }
    }
    .expander-parent:not(.min){
      .readmore-expander {
        margin-bottom: 5px;
      }
    }
  }
  .cb-facts { // Right Side Cash Back Facts
    @include store-tile;
    .cb-facts-content {
      float: left;
      padding: 30px;
      > div {
        &:not(:last-child) { margin-bottom: 15px; }
      }
    }
  }
  .store-coupons {
    .store-coupons-blk {
      @include store-tile;
      margin-bottom: 20px;
    }
    .cat-header {
      width: 100%;
      display: block;
      float: left;
      clear: both;
    }
    span.cb span.coupon-code { color: $prim-text-color; }
    .gplay-download {
      background-image: url(//static.ebates.com/static/images/mobile/store-badges/google_play-1.png);
      height: 41px;
      width: 114px;
      display: block;
      background-size: contain;
      background-repeat: no-repeat;
    }
    .apple-download {
      background-image: url(//static.ebates.com/static/images/mobile/store-badges/App_Store.png);
      height: 41px;
      width: 114px;
      display: block;
      background-size: contain;
      background-repeat: no-repeat;
    }
    .seeAllCoupons {
      padding: 15px 30px;
      line-height: 30px;
      text-align: center;
    }
  }
  // button banner on store pages
  .stores-button-upsell {
    @include store-tile;
    background: linear-gradient(to right, $brand-01, $brand-01-hover);
    margin-bottom: 20px;
    .button-upsell-content {
      @include round(6px);
      margin: 3px;
      background-color: $white-color;
      padding: 30px;
      display: flex;
      align-items: start;
    }
    .button-upsell-content-left {
      margin-right: 30px;
      a {
        text-decoration: underline;
        color: $prim-text-color;
      }
    }
    .merchantText { @include stag(Stag Medium Web, 400); }
    .browserButtonLink {
      padding: 0 15px;
      width: 200px;
      &:link{ text-decoration: none; }
    }
  }
  .travel-deals { // fix layout in travelocity, hotels.com and expedia
    .deal-blk,
    .travel-blk {
      &.col3{
        &:nth-child(3n+0){margin-right:0;}
      }
    }
  }
  #in-store-offers { margin-bottom: 20px; }

  .green-bar { margin-top: 20px; }

  &.main {
    .store-cb-cats {
      .blk1 {
        width: 100%;
        margin-right: 0;
        .col2 {
          width: 50%;
          margin-right: 0;
        }
      }
    }
    //Travel pages
    .travel-widget {
      @include store-tile;
      margin-bottom: 10px;
      .button.int {
        background-color: $cb-color;
        &:hover {
          background-color: $cb-color-hover;
        }
      }
    }
    .calendar {
      .days.active {
        &:hover {
          background-color: $brand-01;
        }
        &.selected {
          background-color: $brand-01 !important;//Overwrite hoteltravelocity.css
          &:hover {
            background-color: $brand-01 !important;
          }
        }
      }
      .in-selection.first {
        background-color: $brand-01 !important;
      }
    }
    #store-coupons{
      #hotel-form {
        .field {width: 185px;}
      }
    }
    #hotel-form {
      .button {
        &.hotels-btn {
          width: auto;
          margin-top: 10px;
        }
      }
      #destination {
        width: 153px;
      }
    }
    #car-form {
      .car-time{
        width: 185px;
      }
      .car-dropoff-date {
        &+.car-time {
          margin-right: 0;
        }
      }
      .button {
        &.car-btn {
          width: auto;
          margin-top: 10px;
        }
      }
    }
    .deal-range{
      &+li{
        a.four-digits{
          letter-spacing: -1px;
        }
      }
    }
  }
  &.tiered,
  &.tiered-premium {
    .bonus-offers { margin-bottom: 20px; }
    .join-module {
      margin-top: 10px;
      margin-bottom: 20px;
    }
  }
  &.tiered-premium {
    .bonus-offers { margin-top: 10px; }
  }
  .subscribe-rt {
    margin-top: 10px;
    margin-bottom: 20px;
    .subscribe {
      img { @include store-tile; }
    }
    .subscribe-copy {
      display: block;
      width: 250px;
    }
  }
}