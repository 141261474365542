@mixin width($width) { width: $width; }
@mixin height($height) { height: $height; }
@mixin line-height($line-height) { line-height: $line-height; }

@mixin background($background: $default-bg-color) { background-color: $background; }

@mixin color($color: $prim-text-color) { color: $color; }

@mixin round($radius: 3px) { border-radius: $radius; }

@mixin calc($property, $expression) {
  #{$property}: -moz-calc(#{$expression});
  #{$property}: -webkit-calc(#{$expression});
  #{$property}: calc(#{$expression});
}
// Usage Examples:
// @include calc( width, '100%' );
// @include calc( width, '100% - 13rem' );

@mixin border($border-width: 1px, $border-style: solid, $border-color: $border-color) {
  border: $border-width $border-style $border-color;
}
@mixin border-top($border-width: 1px, $border-style: solid, $border-color: $border-color) {
  border-top: $border-width $border-style $border-color;
}
@mixin border-bottom($border-width: 1px, $border-style: solid, $border-color: $border-color) {
  border-bottom: $border-width $border-style $border-color;
}
@mixin border-left($border-width: 1px, $border-style: solid, $border-color: $border-color) {
  border-left: $border-width $border-style $border-color;
}
@mixin border-right($border-width: 1px, $border-style: solid, $border-color: $border-color) {
  border-right: $border-width $border-style $border-color;
}

//.space-1{letter-spacing:1px;}
@mixin letter-space($letter-spacing: 0) {
  letter-spacing: $letter-spacing;
}

@mixin padding-t($padding: 0) {
  padding-top: $padding;
}
@mixin padding-b($padding: 0) {
  padding-bottom: $padding;
}

@mixin padding-lr($padding: 0) {
  padding-right: $padding;
  padding-left: $padding;
}

@mixin padding-tb($padding: 0) {
  padding-top: $padding;
  padding-bottom: $padding;
}

@mixin margin-t($margin: 0) {
  margin-top: $margin;
}

@mixin margin-lr($margin: 0) {
  margin-right: $margin;
  margin-left: $margin;
}

@mixin margin-tb($margin: 0) {
  margin-top: $margin;
  margin-bottom: $margin;
}

@mixin square($square: 10px) {
  width: $square;
  min-width: $square;
  height: $square;
}

@mixin rectangle($width, $height) {
  width: $width;
  min-width: $width;
  height: $height;
}

@mixin box-shadow($shadow) {
  box-shadow: $shadow;
  -webkit-box-shadow: $shadow;
  -moz-box-shadow: $shadow;
}

@mixin box-shadows-3($shadow1, $shadow2, $shadow3) {
  box-shadow: $shadow1, $shadow2, $shadow3;
  -webkit-box-shadow: $shadow1, $shadow2, $shadow3;
  -moz-box-shadow: $shadow1, $shadow2, $shadow3;
}

@mixin border-box($borderbox: border-box) {
  box-sizing: $borderbox;
  -webkit-box-sizing: $borderbox;
  -moz-box-sizing: $borderbox;
}

@mixin user-select($userselect: none) {
  user-select: $userselect;
  -webkit-user-select: $userselect;
  -moz-user-select: $userselect;
  -ms-user-select: $userselect;
}

@mixin transition($transition...) {
  -moz-transition:    $transition;
  -o-transition:      $transition;
  -webkit-transition: $transition;
  transition:         $transition;
}
@mixin transition-property($property...) {
  -moz-transition-property:    $property;
  -o-transition-property:      $property;
  -webkit-transition-property: $property;
  transition-property:         $property;
}
@mixin transition-duration($duration...) {
  -moz-transition-property:    $duration;
  -o-transition-property:      $duration;
  -webkit-transition-property: $duration;
  transition-property:         $duration;
}
@mixin transition-timing-function($timing...) {
  -moz-transition-timing-function:    $timing;
  -o-transition-timing-function:      $timing;
  -webkit-transition-timing-function: $timing;
  transition-timing-function:         $timing;
}
@mixin transition-delay($delay...) {
  -moz-transition-delay:    $delay;
  -o-transition-delay:      $delay;
  -webkit-transition-delay: $delay;
  transition-delay:         $delay;
}

//usage: @include shift([property],[duration],[easing]);
@mixin transitionshift($property: all, $duration: .3s, $ease: linear){
  -webkit-transition: $property $duration $ease;
  -moz-transition: $property $duration $ease;
  -o-transition: $property $duration $ease;
  transition: $property $duration $ease;
}

@mixin bghover {
  @include transition( box-shadow 0.2s ); // used in class bg-hover
  @include transition-timing-function( ease-in-out ); // used in class bg-hover
  &:hover {
    @include box-shadow(0 8px 14px -6px rgba(153,153,153,0.65)); // Used by the Home Page Hovers
  }
}

@mixin button {
  border: none;
  cursor: pointer;
  text-align: center;
  display: inline-block;
  @include rakutenSansUI(Rakuten Sans UI Semibold, normal);
  font-size: 16px;
  color: $white-color;
  min-width: 108px;
  @include round(48px);
  @include height(46px);
  @include line-height(46px);
  outline: none;
  padding: 0 25px;
  text-decoration:none;
  transition: background 0.2s, color 0.2s, border 0.2s;
  transition-timing-function: ease-in-out;
  white-space: nowrap;
  @include border-box;
  &:hover{ text-decoration:none; }
}

@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  // filter: alpha(opacity=$opacity-ie); //IE8
  // usage @include opacity(0.8);
}

@mixin bg-hover() {
  @include transition(box-shadow 0.2s);
  @include transition-timing-function(ease-in-out);
  @include box-shadow(0 8px 14px -6px rgba(153, 153, 153, 0.65)); // Used by the Home Page Hovers
}

@mixin clearfix() {
  *zoom:1;
  &:before,
  &:after {
    content:"";
    display:table;
  }
  &:after {
    clear:both;
  }
  // usage @include clearfix();
}

%visuallyhidden {
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  clip: rect(0, 0, 0, 0);
  position: absolute;
  // @extend %visuallyhidden;
}

@mixin display($display: block) { display: $display; }
@mixin visibility($visibility: visible) { visibility: $visibility; }
@mixin overflow($overflow: scroll) { overflow: $overflow; }
@mixin position($position: relative) { overflow: $position; }

@mixin zindex($zindex: 1) { z-index: $zindex; }

@mixin store-tile {
  @include round(8px);
  @include box-shadow(0 4px 8px 0 rgba(10,22,70,0.15)); // #0a1646
  @include background($white-color);
  @include border-box;
}
@mixin store-tile-no-bg { // Use this for elements that have no white background color
  @include round(8px);
  @include box-shadow(0 4px 8px 0 rgba(10,22,70,0.15)); // #0a1646
  @include border-box;
}
@mixin store-tile-border {
  @include round(8px);
  @include border();
  @include background($white-color);
  @include border-box;
}

// Gradients:
@mixin gradient($direction, $list) {
  background: -webkit-linear-gradient($direction, $list);
  background: -moz-linear-gradient($direction, $list);
  background: -o-linear-gradient($direction, $list);
  background: linear-gradient($direction, $list);
}

@mixin tooltip($size: 5px, $direction: left, $bgcolor: $prim-text-color, $bordercolor: $darker-border-color) {
  background: $bgcolor;
  border:1px solid $bordercolor;
  visibility: hidden;
  @include opacity( 0 );
  min-width:80px;
  @if (($direction == top) or ($direction == bottom)) {
    margin-left: 50%;
    @if ($direction == top) {
      margin-top: ($size);
      top: 100%;
    }
    @if ($direction == bottom) {
      margin-bottom: ($size);
      bottom: 100%;
    }
  }
  @if ($direction == right) or ($direction == left) {
    @if ($direction == right) {
      margin-right: ($size);
      right: 100%;
      top: 50%;
    }
    @if ($direction == left) {
      margin-left: ($size);
      left: 100%;
      top: 50%;
    }
  }
  @include border-box;
  @include round;
  @include rakutenSansUI(Rakuten Sans UI, 400);
  font-size: 14px;
  line-height: 18px;
  position: absolute;
  &:after {
    border-style: solid;
    content: "";
    display: block;
    position: absolute;
    @if (($direction == top) or ($direction == bottom)) {
      border-color: $bgcolor transparent;
      left: 50%;
      margin-left: -$size;
    } @else {
      border-color: transparent $bgcolor;
      margin-top: -$size;
      top: 50%;
    }
    @if ($direction == top) {
      border-width: 0 $size $size $size;
      top: -$size;
    }
    @if ($direction == right) {
      border-width: $size 0 $size $size;
      right: -$size;
    }
    @if ($direction == bottom) {
      border-width: $size $size 0 $size;
      bottom: -$size;
    }
    @if ($direction == left) {
      border-width: $size $size $size 0;
      left: -$size;
    }
  }
  &:before {
    border-style:solid;
    content: "";
    display: block;
    position: absolute;
    @if (($direction == top) or ($direction == bottom)) {
      border-color:rgba(153,153,153,0);
      left: 50%;
      margin-left: -($size +1);
    } @else {
      border-color: transparent $bgcolor;
      margin-top: -$size;
      top: 50%;
    }
    @if ($direction == top) {
      border-width: 0 ($size +1) ($size +1 ) ($size +1 );
      top: -($size +1);
      border-bottom-color: $bordercolor;
    }
    @if ($direction == bottom) {
      border-width: ($size +1) ($size +1 ) 0 ($size +1 );
      bottom: -($size +1);
      border-top-color: $bordercolor;
    }
    @if ($direction == right) {
      border-width: ($size +1) 0 ($size +1 ) ($size +1 );
      right: -($size +1);
      border-left-color: $bordercolor;
    }
    @if ($direction == left) {
      border-width: ($size +1) ($size +1 ) ($size +1 ) 0;
      left: -($size +1);
      border-right-color: $bordercolor;
    }
  }
}

@mixin tooltip-load($tooltip-transition-in-duration: .3s) {
  position: relative;
  overflow: visible;
  &:hover, &:focus {
    .tooltip {
      visibility: visible;
      @include opacity( 1 );
      transform: translate(-50%, 0); // horizontally centered
      transition:
              visibility 0s linear 0s,
              opacity $tooltip-transition-in-duration ease-out 0.2s
    }
    .tooltip-left,
    .tooltip-right{
      transform: translate(0, -50%); // vertically centered
    }
  }
}
@mixin tooltip-click-load($tooltip-transition-in-duration: .3s) {
  position: relative;
  overflow: visible;
  &:focus {
    .tooltip {
      visibility: hidden;
      @include opacity( 0 );
      transform: translate(-50%, 0); // horizontally centered
      transition:
              visibility 0s linear 0s,
              opacity $tooltip-transition-in-duration ease-out 0.2s
    }
    .tooltip-left,
    .tooltip-right{
      transform: translate(0, -50%); // vertically centered
    }
  }
}

@mixin rakutenSansUI( $font-family: Rakuten Sans UI, $font-weight: 400) {
  font-family: $font-family;
  font-weight: $font-weight;
  font-style: normal;
}
/*
  Usage example:
  @include rakutenSansUI(Rakuten Sans UI, 300); // Light
  @include rakutenSansUI(Rakuten Sans UI, 400); // Regular
  @include rakutenSansUI(Rakuten Sans UI Semibold, 700); // Semi Bold
*/
@mixin rakutenSansUIsemiBold ( $font-family: Rakuten Sans UI Semibold) {
  font-family: $font-family;
  $font-weight: 700;
  font-style: normal;
} // @include rakutenSansUIsemiBold;


@mixin stag( $font-family: Stag Web, $font-weight: 500) {
  font-family: $font-family; font-weight: $font-weight;
  font-style: normal;
}

@mixin stagSemibold($font-family: Stag Web) {
  font-family: $font-family;
  font-weight: 500;
  font-style: normal;
}
/*
  Usage example:
  @include stag(Stag Light Web, 300); // stag-light // Light
  @include stag(Stag Web, 400); // stag-book // Regular
  @include stag(Stag Medium Web, 500); // for stag-med  // Semi Bold
*/

// Use these for Media Queries! Simple. Add
@mixin respond-to-max($breakpoint) {
  @media only screen and (max-width: $breakpoint + px) { @content; }
  // Usage @include respond-to-max(320) { styles here }
}

@mixin respond-to-min($breakpoint) {
  @media only screen and (min-width: $breakpoint + px) { @content; }
  // Usage @include respond-to-min(1170) { styles here }
}

@mixin respond-to-min-max($breakpoint-min, $breakpoint-max) {
  @media only screen and (min-width: $breakpoint-min + px) and (max-width: $breakpoint-max + px) { @content; }
  // Usage @include respond-to-min-max(320, 1180) { styles here }
}

@mixin webkit-hack($pixelratio) {
  @media only screen and (-webkit-min-device-pixel-ratio: $pixelratio) { @content; } // Safari Hack
  // Usage @include webkit-hack(1) { styles here }
}

// DO NOT USE >>>>>>>
@mixin phone-xs() { // usage @include phone-xs { classes and properties inside }
  @media only screen and (max-width: 320px) {
    @content;
  }
}
// DO NOT USE phone-xs-pano!! Being Deprecated.
@mixin phone-xs-pano() { // usage @include phone-xs-pano { classes and properties inside }
  @media only screen and (max-width: 568px) {
    @content;
  }
}
// DO NOT USE phone-sm!! Being Deprecated.
@mixin phone-sm() { // usage @include phone-sm { classes and properties inside }
  @media only screen and (max-width: 375px) {
    @content;
  }
}
// DO NOT USE phone-sm-pano!! Being Deprecated.
@mixin phone-sm-pano() { // usage @include phone-sm-pano { classes and properties inside }
  @media only screen and (max-width: 667px) {
    @content;
  }
}
// DO NOT USE phone-lg!! Being Deprecated.
@mixin phone-lg() { // usage @include phone-lg { classes and properties inside }
  @media only screen and (max-width: 414px) {
    @content;
  }
}
// DO NOT USE phone-lg-pano!! Being Deprecated.
@mixin phone-lg-pano() { // usage @include phone-lg-pano { classes and properties inside }
  @media only screen and (max-width: 812px) { // updated to 812px for iPhone X
    @content;
  }
}
// DO NOT USE tablet-screens-full!! Being Deprecated.
@mixin tablet-screens-full() { // usage @include tablet-screens-full { classes and properties inside }
  // Used for smaller Desktops where the max-screen is 1180px
  @media only screen and (max-width:1180px) {
    @content;
  }
}

// DO not use unless absolutely needed
$break-small: 480px;
$break-medium: 768px;
$break-large: 980px;
$break-x-large: 1200px;
@mixin screen($size) {
  @if $size == big-phone-up {
    @media only screen and (min-width: $break-small) {
      @content;
    }
  }
  @if $size == tablet-up {
    @media only screen and (min-width: $break-medium) {
      @content;
    }
  } @else if $size == desktop-up {
    @media only screen and (min-width: $break-large) {
      @content;
    }
  } @else if $size == big-desktop {
    @media only screen and (min-width: $break-x-large) {
      @content;
    }
  } @else if $size == phone-only {
    @media only screen and (max-width: $break-medium - 1) {
      @content;
      // Sample usage: @include screen(phone-only) { min-width: 320px; }
    }
  } @else if $size == small-phone-only {
    @media only screen and (max-width: $break-small - 1) {
      @content;
    }
  } @else if $size == big-phone-only {
    @media only screen and (min-width: $break-small) and (max-width: $break-medium - 1) {
      @content;
    }
  } @else if $size == tablet-only {
    @media only screen and (min-width: $break-medium) and (max-width: $break-large - 1) {
      @content;
    }
  } @else if $size == desktop-only {
    @media only screen and (min-width: $break-large) and (max-width: $break-x-large - 1) {
      @content;
    }
  } @else if $size == print {
    @media print {
      @content;
    }
  }
}
// DO NOT USE <<<<<<<

// from https://gist.github.com/richardtorres314/26b18e12958ba418bb37993fdcbfc1bd
// --------------------------------------------------
// Flexbox SASS mixins
// The spec: http://www.w3.org/TR/css3-flexbox
// --------------------------------------------------

// Flexbox display
@mixin flexbox() {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox; // ie 10
  display: -webkit-flex;
  display: flex;
}

// The 'flex' shorthand
// - applies to: flex items
// <positive-number>, initial, auto, or none
@mixin flex($values) {
  -webkit-box-flex: $values;
  -moz-box-flex: $values;
  -webkit-flex: $values;
  -ms-flex: $values;
  flex: $values;
}

// Flex Flow Direction
// - applies to: flex containers
// row | row-reverse | column | column-reverse
@mixin flex-direction($direction) {
  -webkit-flex-direction: $direction;
  -moz-flex-direction: $direction;
  -ms-flex-direction: $direction;
  flex-direction: $direction;
}

// Flex Line Wrapping
// - applies to: flex containers
// nowrap | wrap | wrap-reverse
@mixin flex-wrap($wrap) {
  -webkit-flex-wrap: $wrap;
  -moz-flex-wrap: $wrap;
  -ms-flex-wrap: $wrap;
  flex-wrap: $wrap;
}

// Flex Direction and Wrap
// - applies to: flex containers
// <flex-direction> || <flex-wrap>
@mixin flex-flow($flow) {
  -webkit-flex-flow: $flow;
  -moz-flex-flow: $flow;
  -ms-flex-flow: $flow;
  flex-flow: $flow;
}

// Display Order
// - applies to: flex items
// <integer>
@mixin order($val) {
  -webkit-box-ordinal-group: $val;
  -moz-box-ordinal-group: $val;
  -ms-flex-order: $val;
  -webkit-order: $val;
  order: $val;
}

// Flex grow factor
// - applies to: flex items
// <number>
@mixin flex-grow($grow) {
  -webkit-flex-grow: $grow;
  -moz-flex-grow: $grow;
  -ms-flex-grow: $grow;
  flex-grow: $grow;
}

// Flex shrink
// - applies to: flex item shrink factor
// <number>
@mixin flex-shrink($shrink) {
  -webkit-flex-shrink: $shrink;
  -moz-flex-shrink: $shrink;
  -ms-flex-shrink: $shrink;
  flex-shrink: $shrink;
}

// Flex basis
// - the initial main size of the flex item
// - applies to: flex itemsnitial main size of the flex item
// <width>
@mixin flex-basis($width) {
  -webkit-flex-basis: $width;
  -moz-flex-basis: $width;
  -ms-flex-basis: $width;
  flex-basis: $width;
}

// Axis Alignment
// - applies to: flex containers
// flex-start | flex-end | center | space-between | space-around
@mixin justify-content($justify) {
  -webkit-justify-content: $justify;
  -moz-justify-content: $justify;
  -ms-justify-content: $justify;
  justify-content: $justify;
  -ms-flex-pack: $justify;
}

// Packing Flex Lines
// - applies to: multi-line flex containers
// flex-start | flex-end | center | space-between | space-around | stretch
@mixin align-content($align) {
  -webkit-align-content: $align;
  -moz-align-content: $align;
  -ms-align-content: $align;
  align-content: $align;
}

// Cross-axis Alignment
// - applies to: flex containers
// flex-start | flex-end | center | baseline | stretch
@mixin align-items($align) {
  -webkit-align-items: $align;
  -moz-align-items: $align;
  -ms-align-items: $align;
  align-items: $align;
}

// Cross-axis Alignment
// - applies to: flex items
// auto | flex-start | flex-end | center | baseline | stretch
@mixin align-self($align) {
  -webkit-align-self: $align;
  -moz-align-self: $align;
  -ms-align-self: $align;
  align-self: $align;
}

// Retina Images
@mixin image-2x($image, $width, $height) {
  @media (min--moz-device-pixel-ratio: 1.3),
  (-o-min-device-pixel-ratio: 2.6/2),
  (-webkit-min-device-pixel-ratio: 1.3),
  (min-device-pixel-ratio: 1.3),
  (min-resolution: 1.3dppx) {
    background-image: url($image);
    background-size: $width + px $height + px;
    background-size: $width / 10 + rem $height / 10 + rem;
  }
}

// Usage Sample -  Retina Images
// .logo{
//   background:url(logo.png) no-repeat;
//   @include image-2x(logo-hd.png", 120, 30);
// }

// Output Sample -  Retina Images
// .logo {
//   background: url(../img/logo.png) no-repeat;
// }
// @media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6 / 2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
//   .logo {
//     background-image: url("../img/logo_hd.png");
//     background-size: 120px 30px;
//   }
// }





@mixin _select-form-reset() {
  // Reset from https://codepen.io/jnowland/pen/KzYQxp
  // Used in example https://codepen.io/jnowland/pen/GZLQBw
  display: block;
  padding: 0;
  position: relative;
  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    pointer-events: none; // Arrow clickable in some browsers
    z-index: 2;
  }
  // This is the native select, we're making everything but the text invisible so we can see the button styles in the wrapper
  select {
    -webkit-appearance: none;
    appearance: none;
    background: none;
    box-sizing: border-box;
    width: 100%;
    margin: 0;
    border: 1px solid transparent;
    font-size: 16px; // Font size must the 16px or larger to prevent iOS page zoom on focus
    outline: none;
    /* Focus style */
    &:focus {
      background-color: transparent;
      outline: none;
    }
  }
  /* Set options to normal weight */
  option { font-weight: normal; }
  /* ------------------------------------  */
  /* START OF UGLY BROWSER-SPECIFIC HACKS */
  /* ----------------------------------  */
  // Targeted via this hack http://browserhacks.com/#hack-a3f166304aafed524566bc6814e1d5c7
  x:-o-prefocus,
  &::after { display: none; }
  // IE 10/11+ - This hides native dropdown button arrow so it will have the custom appearance,
  // IE 9 and earlier get a native select - targeting media query hack via http://browserhacks.com/#hack-28f493d247a12ab654f6c3637f6978d5 - looking for better ways to achieve this targeting
  // The second rule removes the odd blue bg color behind the text in the select button in IE 10/11 and sets the text color to match the focus style's - fix via http://stackoverflow.com/questions/17553300/change-ie-background-color-on-unopened-focused-select-box */
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    & {
      select {
        &::-ms-expand { display: none; }
        &:focus::-ms-value {
          background: transparent;
          color: $prim-text-color;
        }
      }
    }
  }
  /* FIREFOX won't let us hide the native select arrow, so we have to make it wider than needed and clip it via overflow on the parent container.
     The percentage width is a fallback since FF 4+ supports calc() so we can just add a fixed amount of extra width to push the native arrow out of view. We're applying this hack across all FF versions because all the previous hacks were too fragile and complex.
     You might want to consider not using this hack and using the native select arrow in FF. Note this makes the menus wider than the select button because they display at the specified width and aren't clipped. Targeting hack via http://browserhacks.com/#hack-758bff81c5c32351b02e10480b5ed48e */
  /* Show only the native arrow */
  @-moz-document url-prefix() {
    & {
      overflow: hidden;
      select {
        width: 120%;
        width: -moz-calc(100% + 3em);
        width: calc(100% + 3em);
        @supports(-moz-appearance: none) {
          width: 100%;
        }
        /* Firefox focus has odd artifacts around the text, this kills that. See https://developer.mozilla.org/en-US/docs/Web/CSS/:-moz-focusring */
        &:-moz-focusring {
          color: transparent;
          text-shadow: 0 0 0 $prim-text-color;
        }
      }
    }
  }
  @supports( -moz-appearance: none ) {
    & { width: 100%; }
  }
}
