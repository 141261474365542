.cb.normal,
.prox-b .prox-r,
.prox-r a,
a.prox-r { @include rakutenSansUI(Rakuten Sans UI, 400); }

.cb, b, strong,
.prox-b a,
a.prox-b,
.prox-r .prox-b,
.cb.prox-b { @include rakutenSansUIsemiBold; }

.none { text-decoration: none; }

a {
  color: $brand-01;
  &:hover {
    text-decoration: underline;
    color: $brand-01-hover;
  }
  &.f-sec{ color: $sec-text-color; }
  &.f-norm { color:$prim-text-color; }
}

a, a.nohover:hover, .nohover a:hover, .nohover:hover{ text-decoration:none; }


h1{
  @include rakutenSansUIsemiBold;
  font-size: 24px;
  line-height: 28px;
  &.alt{
    @include rakutenSansUI(Rakuten Sans UI, 400);
    font-size: 24px;
    line-height: 1;
  }
  &.cn { font-size: 2em; }
  &.stag-med { @include stag(Stag Medium Web, 500); }
}

h2{
  @include stag(Stag Medium Web, 500);
  font-size: 28px;
  line-height: 36px;
  &.alt{
    @include rakutenSansUIsemiBold;
    font-size: 18px;
    line-height: 1;
    text-transform: uppercase;
    @include letter-space(2px);
  }
  &.scn { font-size: 20px; }
  &.f-b { @include rakutenSansUIsemiBold; }
}

h3{
  @include rakutenSansUIsemiBold;
  font-size: 20px;
  line-height: 24px;
  &.prox-r{ @include rakutenSansUI(Rakuten Sans UI, 400); }
  &.lh-28 { line-height: 28px; }
  &.alt {
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    @include letter-space(1px);
  }
  &.tabs{
    @include rakutenSansUI(Rakuten Sans UI, 400);
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    @include letter-space(1px);
  }
  &.lh-24{
    font-size: 24px;
    line-height: 24px;
  }
}

h4{
  @include rakutenSansUIsemiBold;
  font-size: 16px;
  line-height: 20px;
}

.cb{ @include rakutenSansUIsemiBold; }
.dwview {
  .cb {
    font-size: 16px;
    line-height: 20px;
    &.f-18 {
      font-size: 18px;
      line-height: 18px;
    }
  }
}

.mwview {
  h1 {
    @include rakutenSansUIsemiBold;
    font-size: 16px;
    line-height: 20px;
  }
  h2 {
    @include rakutenSansUI(Rakuten Sans UI, 400);
    font-size: 14px;
    line-height: 18px;
    &.alt {
      @include rakutenSansUIsemiBold;
      font-size: 12px;
      line-height: 1;
      text-transform: uppercase;
      @include letter-space(2px);
    }
  }
}

p,
.f-default {
  font-size: 14px;
  line-height: 18px;
}
p.lg, li.lg, .f-lg {
  font-size: 16px;
  line-height: 20px;
}
p.sm, li.sm, .f-sm {
  font-size: 12px;
  line-height: 16px;
}

.account-menu .dropdown .f-norm{color:$prim-text-color;}
.account-menu .dropdown a:hover{color:$brand-01;}

.new-ui{
  a, .f-brand-01, .f-int, .f-grn { color:$brand-01; }
  a {
    &.f-sec { color: $sec-text-color; }
    &.f-norm { color: $brand-01; }
    &.f-gry-dk-8 { color: $gray-dark-8-color; }
    &.f-white { color: $white-color; }
  }
  .f-ext, .cb, span.cb, .f-orange, .f-ext { color: $brand-01; }
  .score-3,.score-4 { color:$prim-text-color; }

  // hacks
  .fa-star.f-grn,
  .fa-star-o.f-grn{ color:$prim-text-color; }
}

.iblk {
  &.reset-f { font-size: 0; }
}

[class^="fa-"], [class*=" fa-"] {
  &.lh-46{ line-height: 46px; }
}

ul{list-style-type:none;list-style-position:inside;}
ul.bull{list-style-type:circle;list-style-position:inside;}
ul.default{list-style-type:disc;list-style-position:inside;}

sup, sub{
  line-height: 0;
  position: relative;
  vertical-align: baseline;
  top: -0.5em;
}
sup { top: -0.5em; }
sub { bottom: -0.25em; }
